<div
    #specificContainer
    class="iu-specific-window iu-specific-window-container"
    [attr.data-cy-specific-window-name]="name">
    @if (!isModal && isLoading) {
        <p-progressBar [mode]="'indeterminate'" [value]="0" [showValue]="false" [styleClass]="'loading-progressbar'" />
    }

    @if (vcrOptional.length > 0) {
        <a class="settings" (click)="isSidebarOpen = !isSidebarOpen"><i class="fas fa-cog"></i></a>
    }

    <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
        <div class="p-col-12">
            <label [hidden]="title && title.length === 0" class="specific-title"
            ><b>{{ title }}</b></label
            >
            <div class="fields">
                <ng-template iuDynamicContainer></ng-template>
            </div>
        </div>
        <div class="iu-specific-btn-container" [ngClass]="[shouldAddPadding ? 'shouldAddPadding' : '']">
            @if (gridViews && gridViews.length === 1 && hasInfoWindowGrid) {
                <iu-button-ui
                        class="iu-btn p-col-12 p-md-3 p-lg-2"
                        (click)="$event.preventDefault(); $event.stopPropagation(); zoomOnLineSelected()"
                        label="{{ 'contextmenu.zoom' | translate }}"
                        data-cy="form-button-zoom"
                        icon="fa fa-search-plus" />
            }
            <iu-button-ui
                    label="{{ 'productPrice.priceHistory' | translate }}"
                    class="iu-btn p-col-12 p-md-3 p-lg-2 primary historyPriceButton"
                    (click)="$event.preventDefault(); $event.stopPropagation(); showPriceHistoryForm()" />
            <ng-template #vcrButtons />
            @if (isModal && gridViews && gridViews.length === 1 && hasInfoWindowGrid) {
                <iu-button-ui
                        class="p-col-12 p-md-3 p-lg-2"
                        (click)="
            $event.preventDefault(); $event.stopPropagation(); setSelectedRowOnAutocomplete(); closeModalEmitter.emit()
          "
                        label="{{ 'infodialog.ok' | translate }}"
                        data-cy="form-button-validate" />
            }
            @if (isModal) {
                <iu-button-ui
                        class="iu-btn btn-cancel p-col-12 p-md-3 p-lg-1"
                        (click)="$event.preventDefault(); $event.stopPropagation(); closeModalEmitter.emit()"
                        label="{{ 'process.cancel' | translate }}"
                        icon="fa fa-ban" />
            }
        </div>
    </p-scrollPanel>
    <p-sidebar
            [(visible)]="isSidebarOpen"
            [baseZIndex]="6000"
            position="right"
            styleClass="p-sidebar-md optionalFilterPanel">
        <h1 class="iu-notification-center-title">
            {{ 'specificWindow.optionalFilters' | translate }}
        </h1>
        <div class="optionalFields">
            <ng-template #optional />
        </div>
    </p-sidebar>
</div>
<!-- @if (displaySearch) {
  <ng-container>
    <iu-modal-ui
      data-cy="search-panel"
      [attr.data-cy-columnName]="this.searchLinkedComponent.autoComplete.data.columnName"
      [title]="this.searchLinkedComponent.autoComplete.label"
      [hasCloseBtn]="true"
      (closeModalEmitter)="closeModalSearch()"
    >
      <div class="autocomplete-universal-filter">
        <iu-universal-filter-ui
          [tabId]="-1"
          [columns]="searchLinkedComponent.autoComplete.data.searchColumns"
          [gridTab]="gridTab"
        />
      </div>
      <iu-grid-tab-infinity-scroll-ui
        #gridTab
        (searchEmitter)="
          this.searchLinkedComponent.setSearchSelectItem($event); displaySearch = false; searchPanelValidation = undefined
        "
        [data]="this.searchLinkedComponent.autoComplete.data"
        [container]="this"
        [isSearch]="true"
        [dataStored]="this.searchLinkedComponent.autoComplete.dataStored"
        [validation]="searchPanelValidation"
      />
    </iu-modal-ui>
  </ng-container>
} -->
<div class="specificModal" [ngClass]="{ hidden: !displayFormUI }">
    <iu-modal-ui
            [title]="specificWindowTitle"
            [isModalDisplay]="displayFormUI"
            (closeModalEmitter)="updateModalDisplay({ key: 'displayFormUI', value: false, sourceComponent: this })"
            [angularStyle]="customFormModalBodyCss">
        <ng-template #vcrSpecific />
    </iu-modal-ui>
</div>
<div hidden="true">
    <ng-template #vcrHidden />
</div>
