<iu-input-file-ui
  customUpload="true"
  (nbUploadedFilesChangeEmitter)="updateNbUploadedFiles($event)"
  (openFileOnSideEmitter)="openFileOnSide($event)"
  (uploadEnd)="uploadEnd.emit($event)"
  [additionalUploadInformations]="tags"
  [adTab_ID]="adTab_ID"
  [adTable_ID]="adTable_ID"
  [auto]="false"
  [data]="{ isParam: false, columnName: 'join-file' }"
  [fromNotes]="fromNotes"
  [isAttachment]="true"
  [multiple]="true"
  [record_id]="record_id"
  [searchInformations]="baseSearch"
  [supportLinks]="true"
  [type]="fromNotes ? 'NOTES' : 'ATTACHEMENT'">
</iu-input-file-ui>
