import {NgClass, NgStyle} from '@angular/common';
import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    inject,
    Input,
    Output,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation,
} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CompiereDataGridRequestJSON, CompiereDataGridType, DataStore} from '@compiere-ws/models/compiere-data-json';
import {CountListViewType, WidgetViewType} from '@compiere-ws/models/widget-center-json';
import {CompiereDataField} from '@compiere-ws/models/window-json';
import {GridPreference, GridPreferencesService,} from '@compiere-ws/services/grid-preferences/grid-preferences.service';
import {WidgetCenterService} from '@compiere-ws/services/widget-center/widget-center.service';
import {CustomDesignItemType} from '@iupics-components/models/custom-design';
import PrimeFieldsetComponent from '@iupics-components/overrided/prime-fieldset/prime-fieldset.component';
import {InfoDialogType} from '@iupics-components/specific/window/info-dialog/info-dialog.component';
import SpecificWindowUiComponent
    from '@iupics-components/specific/window/specific-window-ui/specific-window-ui.component';
import AutocompleteUiComponent from '@iupics-components/standard/fields/autocomplete-ui/autocomplete-ui.component';
import InputNumberUiComponent from '@iupics-components/standard/fields/input-number-ui/input-number-ui.component';
import InputSwitchUiComponent from '@iupics-components/standard/fields/input-switch-ui/input-switch-ui.component';
import UniversalFilterStandaloneUiComponent
    from '@iupics-components/standard/menu/universal-filter-standalone-ui/universal-filter-standalone-ui.component';
import {CacheManagerService} from '@iupics-manager/managers/cache-manager/cache-manager.service';
import {DynamicComponent} from '@iupics-manager/models/dynamic-component';
import {Global} from '@iupics-manager/models/global-var';
import {IupicsTypeEvent} from '@iupics-manager/models/iupics-event';
import {IupicsMessage} from '@iupics-manager/models/iupics-message';
import {IupicsWidget} from '@iupics-manager/models/iupics-widget';
import {DynamicContainerDirective} from '@iupics-util/directives/dynamic-container.directive';
import {AbstractWidgetComponent} from '@iupics/modules/iupics-widgets/abstract-widget.component';
import CountListWidgetUiComponent
    from '@iupics/modules/iupics-widgets/components/count-list-widget/count-list-widget-ui.component';
import {TranslateModule} from '@ngx-translate/core';
import {ThemeService} from '@web-desktop/controllers/theme.service';
import {IupicsMenuType} from '@web-desktop/models/menu-item-ui';
import {cloneDeep} from 'lodash';
import {ButtonModule} from 'primeng/button';
import {ColorPickerModule} from 'primeng/colorpicker';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {TabViewModule} from 'primeng/tabview';
import {catchError, map, of, Subscription} from 'rxjs';

@Component({
    selector: 'iu-widget-editor-ui',
    templateUrl: './widget-editor-ui.component.html',
    styleUrls: ['./widget-editor-ui.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [
        DynamicContainerDirective,
        CountListWidgetUiComponent,
        PrimeFieldsetComponent,
        AutocompleteUiComponent,
        InputSwitchUiComponent,
        ButtonModule,
        TranslateModule,
        ColorPickerModule,
        FormsModule,
        NgStyle,
        InputNumberUiComponent,
        TabViewModule,
        NgClass,
        AutocompleteUiComponent,
        UniversalFilterStandaloneUiComponent,
        ScrollPanelModule,
    ],
})
export default class WidgetEditorUiComponent extends SpecificWindowUiComponent {
    @ViewChild('previewWidget', {read: ViewContainerRef, static: true}) previewWidgetVcr: ViewContainerRef;
    @ViewChild('vcrWidgetChoice', {read: ViewContainerRef}) vcrWidgetChoice: ViewContainerRef;
    @ViewChild('vcrDataSource', {read: ViewContainerRef, static: true}) vcrDataSource: ViewContainerRef;
    @ViewChild('vcrCols', {read: ViewContainerRef, static: true}) vcrCols: ViewContainerRef;
    @ViewChild('vcrParams', {read: ViewContainerRef, static: true}) vcrParams: ViewContainerRef;
    @ViewChild('vcrAccess', {read: ViewContainerRef, static: true}) vcrAccess: ViewContainerRef;

    @Input() widget: IupicsWidget;

    defaultView: { id: CountListViewType; displayValue: string } = {
        id: CountListViewType.COUNT_VIEW,
        displayValue: this.translateService.instant('widgetEditor.countView'),
    };

    @Input() gridPref: GridPreference;

    @Output() closeEmitter: EventEmitter<Event> = new EventEmitter();
    @Output() updateInitialUFEmitter: EventEmitter<number> = new EventEmitter();

    updateFieldUFEmitter: EventEmitter<CompiereDataGridRequestJSON> = new EventEmitter();
    IupicsMenuType = IupicsMenuType;
    activeWidgetType: string;
    activeWidget: AbstractWidgetComponent = null;
    subscriptions: Subscription[] = [];
    currentRoleId: number;
    isAdmin = false;
    views: { items: { id: CountListViewType; displayValue: string }[] } = {
        items: [
            {
                id: CountListViewType.COUNT_VIEW,
                displayValue: this.translateService.instant('widgetEditor.countView'),
            },
            {
                id: CountListViewType.LIST_VIEW,
                displayValue: this.translateService.instant('widgetEditor.listView'),
            },
        ],
    };
    roleNames = {items: []};
    widgetRoles: CompiereDataField[] = [];
    activeTabIndex = 0;
    searchColumns: any[];
    showUF = false;

    private widgetService = inject(WidgetCenterService);
    private themeService = inject(ThemeService);
    private detectorRef = inject(ChangeDetectorRef);
    private gridPreferenceService = inject(GridPreferencesService);

    ngOnInit(): void {
        this.currentRoleId = this.connectorService.getIupicsUserAccount().current_role.role_id;
        this.isAdmin = this.connectorService.getIupicsUserAccount().current_role.isAdministrator;
        this.detectorRef.detectChanges();
        this.customDesignArray.push(
            {
                vcr: 'vcrWidgetChoice',
                type: CustomDesignItemType.FIELD,
                columnName: 'AD_Widget_ID',
                cssClass: 'p-col-6 p-md-6 p-lg-6',
            },
            {
                vcr: 'vcr',
                type: CustomDesignItemType.FIELD,
                columnName: 'Record_ID',
                cssClass: 'p-col-6 p-md-6 p-lg-6',
            },
            {
                vcr: 'vcr',
                type: CustomDesignItemType.FIELD,
                columnName: 'Name',
                cssClass: 'p-col-6 p-md-6 p-lg-4',
            },
            {
                vcr: 'vcr',
                type: CustomDesignItemType.FIELD,
                columnName: 'WidgetType',
                cssClass: 'p-col-6 p-md-4 p-lg-4',
            },
            {
                vcr: 'vcr',
                type: CustomDesignItemType.FIELD,
                columnName: 'DefaultView',
                cssClass: 'p-col-6 p-md-4 p-lg-4',
            },
            {
                vcr: 'vcr',
                type: CustomDesignItemType.FIELD,
                columnName: 'AngularClass',
                cssClass: 'p-col-6 p-md-4 p-lg-4',
            },
            {
                vcr: 'vcr',
                type: CustomDesignItemType.FIELD,
                columnName: 'Description',
                cssClass: 'p-col-12 p-md-12 p-lg-12',
            },

            {
                vcr: 'vcrDataSource',
                type: CustomDesignItemType.FIELD,
                columnName: 'AD_Window_ID',
                cssClass: 'p-col-6 p-md-6 p-lg-6',
            },
            {
                vcr: 'vcrDataSource',
                type: CustomDesignItemType.FIELD,
                columnName: 'AD_Tab_ID',
                cssClass: 'p-col-6 p-md-6 p-lg-6',
            },
            {
                vcr: 'vcrDataSource',
                type: CustomDesignItemType.FIELD,
                columnName: 'AD_Table_ID',
                cssClass: 'p-col-6 p-md-6 p-lg-6',
            },
            {
                vcr: 'vcrDataSource',
                type: CustomDesignItemType.FIELD,
                columnName: 'Iframe',
                cssClass: 'p-col-12 p-md-12 p-lg-12',
            },
            {
                vcr: 'vcrCols',
                type: CustomDesignItemType.FIELD,
                columnName: 'CategoryColumn_ID',
                cssClass: 'p-col-6 p-md-6 p-lg-3',
            },
            {
                vcr: 'vcrCols',
                type: CustomDesignItemType.FIELD,
                columnName: 'CategorySort',
                cssClass: 'p-col-6 p-md-6 p-lg-3',
            },
            {
                vcr: 'vcrCols',
                type: CustomDesignItemType.FIELD,
                columnName: 'SerieColumn_ID',
                cssClass: 'p-col-6 p-md-6 p-lg-3',
            },
            {
                vcr: 'vcrCols',
                type: CustomDesignItemType.FIELD,
                columnName: 'SerieSort',
                cssClass: 'p-col-6 p-md-6 p-lg-3',
            },
            {
                vcr: 'vcrCols',
                type: CustomDesignItemType.FIELD,
                columnName: 'AggregateColumn_ID',
                cssClass: 'p-col-6 p-md-6 p-lg-3',
            },
            {
                vcr: 'vcrCols',
                type: CustomDesignItemType.FIELD,
                columnName: 'AggregateOperator',
                cssClass: 'p-col-6 p-md-6 p-lg-3',
            },
            {
                vcr: 'vcrCols',
                type: CustomDesignItemType.FIELD,
                columnName: 'AggregateSort',
                cssClass: 'p-col-6 p-md-6 p-lg-3',
            },
            {
                vcr: 'vcrCols',
                type: CustomDesignItemType.FIELD,
                columnName: 'RecordLimit',
                cssClass: 'p-col-6 p-md-6 p-lg-3',
            },
            {
                vcr: 'vcrParams',
                type: CustomDesignItemType.FIELD,
                columnName: 'MeasureUnit',
                cssClass: 'p-col-6 p-md-6 p-lg-6',
            },
            {
                vcr: 'vcrParams',
                type: CustomDesignItemType.FIELD,
                columnName: 'Precision',
                cssClass: 'p-col-6 p-md-6 p-lg-6',
            },
            {
                vcr: 'vcrParams',
                type: CustomDesignItemType.FIELD,
                columnName: 'IsRounded',
                cssClass: 'p-col-6 p-md-6 p-lg-6',
            },
            {
                vcr: 'vcrParams',
                type: CustomDesignItemType.FIELD,
                columnName: 'Multiplier',
                cssClass: 'p-col-6 p-md-6 p-lg-6',
            },
            {
                vcr: 'vcrParams',
                type: CustomDesignItemType.FIELD,
                columnName: 'NormalLevel',
                cssClass: 'p-col-6 p-md-6 p-lg-6',
            },
            {
                vcr: 'vcrParams',
                type: CustomDesignItemType.FIELD,
                columnName: 'AlertLevel',
                cssClass: 'p-col-6 p-md-6 p-lg-6',
            },
            {
                vcr: 'vcrParams',
                type: CustomDesignItemType.FIELD,
                columnName: 'NormalColor',
                cssClass: 'p-col-12 p-md-6 p-lg-4',
            },
            {
                vcr: 'vcrParams',
                type: CustomDesignItemType.FIELD,
                columnName: 'WarningColor',
                cssClass: 'p-col-12 p-md-6 p-lg-4',
            },
            {
                vcr: 'vcrParams',
                type: CustomDesignItemType.FIELD,
                columnName: 'AlertColor',
                cssClass: 'p-col-12 p-md-6 p-lg-4',
            }
        );
        super.ngOnInit();
        this.initData(this.widget?.id);
        this.activeGrids = [];
        const item: DynamicComponent = {
            container: this,
            DOMParentComponent: this,
            component: 'SpecificWindowUiComponent',
            cssClass: 'p-col-6',
            isCssOnComponent: false,
            tabId: this.formId,
            gridPaginator: false,
        };
        this.windowFactory.newEventHandler({
            type: IupicsTypeEvent.showSpecificWindow,
            item: item,
        });
    }

    initData(widgetId?: number, updateWidgetType = true) {
        const activeTheme = this.themeService.active();
        const widgetIdToUse = widgetId ? widgetId : this.gridPref?.widgetID;
        if (widgetIdToUse) {
            this.activeWidgetType = null;
            this.widget = cloneDeep(this.widgetService.getIupicsWidget(widgetIdToUse));
            if (this.widget == null) {
                this.messageManager.newMessage(
                    new IupicsMessage(
                        this.translateService.instant('generic.error'),
                        this.translateService.instant('widgetEditor.widgetNotFound'),
                        'error'
                    )
                );
            }
        }
        if (!this.widget) {
            if (this.gridPref) {
                this.widget = {
                    id: 0,
                    name: this.gridPref.name,
                    description: null,
                    angularClass: 'CountListWidgetUiComponent',
                    widgetType: WidgetViewType.COUNT_LIST,
                    defaultView: CountListViewType.COUNT_VIEW,
                    gridRequest: this.gridPref.gridRequest,
                    gridState: this.gridPref.gridState,
                    userGridPreferenceId: this.gridPref.userGridPreferenceID,
                    tabId: this.gridPref.tabID,
                    roleId: this.currentRoleId,
                    tableId: this.gridPref.tableID,
                    windowId: this.gridPref.windowID,
                    recordLimit: 0,
                    normalLevel: 0,
                    normalColor: activeTheme?.data['--success-bg-color-active']
                        ? activeTheme?.data['--success-bg-color-active']
                        : '#63a856',
                    alertLevel: 0,
                    alertColor: activeTheme?.data['--alert-bg-color-active']
                        ? activeTheme?.data['--alert-bg-color-active']
                        : '#9b0015',
                    warningColor: activeTheme?.data['--warning-bg-color-active']
                        ? activeTheme?.data['--warning-bg-color-active']
                        : '#b4892d',
                    userId: this.connectorService.getIupicsUserAccount().id,
                    multiplier: null,
                    categoryColumnId: null,
                    categorySort: null,
                    serieColumnId: null,
                    serieSort: null,
                    aggregateColumnId: null,
                    aggregateSort: null,
                    aggregateOperator: null,
                    isRounded: true,
                    measureUnit: '',
                    precision: 2,
                    widgetRoles: [],
                    iframe: null,
                };
            } else {
                this.widget = {
                    id: 0,
                    name: null,
                    description: null,
                    angularClass: 'CountListWidgetUiComponent',
                    widgetType: WidgetViewType.COUNT_LIST,
                    defaultView: CountListViewType.COUNT_VIEW,
                    gridRequest: null,
                    gridState: null,
                    userGridPreferenceId: null,
                    tabId: null,
                    roleId: this.currentRoleId,
                    tableId: null,
                    windowId: null,
                    recordLimit: 0,
                    normalLevel: 0,
                    normalColor: activeTheme?.data['--success-bg-color-active']
                        ? activeTheme?.data['--success-bg-color-active']
                        : '#63a856',
                    alertLevel: 0,
                    alertColor: activeTheme?.data['--alert-bg-color-active']
                        ? activeTheme?.data['--alert-bg-color-active']
                        : '#9b0015',
                    warningColor: activeTheme?.data['--warning-bg-color-active']
                        ? activeTheme?.data['--warning-bg-color-active']
                        : '#b4892d',
                    userId: this.connectorService.getIupicsUserAccount().id,
                    multiplier: null,
                    categoryColumnId: null,
                    categorySort: null,
                    serieColumnId: null,
                    serieSort: null,
                    aggregateColumnId: null,
                    aggregateSort: null,
                    aggregateOperator: null,
                    isRounded: true,
                    measureUnit: '',
                    precision: 2,
                    widgetRoles: [],
                    iframe: null,
                };
            }
        }
        this.widgetService.getWidgetRoles(this.isAdmin, this.widget?.id).subscribe((widgetRoles) => {
            this.widget.widgetRoles = widgetRoles;
            this.initWidgetRoles();
            if (updateWidgetType) {
                this.updateWidgetType();
            } else {
                this.initStoreWithWidget();
            }
        });
    }

    checkDataDiff(value1: any, value2: any): boolean {
        let isEquals = true;
        // simple case
        if (value1 != value2 && !(value1 instanceof Object) && !(value2 instanceof Object)) {
            if (
                !(value1 === null && (value2 === '' || value2 === 0)) &&
                !(value2 === null && (value1 === '' || value1 === 0))
            ) {
                // input text & auto & number)
                isEquals = false;
            }
        }
        // complex case
        else {
            // id or value
            const val1 = value1 instanceof Object ? value1.id : value1;
            const val2 = value2 instanceof Object ? value2.id : value2;
            if (val1 != val2) {
                isEquals = false;
            }
        }
        // prise en compte du cas des null étant un espace dans le oldstore ou remote (oldValue:' '|currentValue:null)
        if (value1 && value1.trim && value1.trim() === '' && value2 === null) {
            isEquals = true;
        }
        // prise en compte du cas des yes-no null dans le oldstore ou remote
        if ((value1 === null && value2 === 'N') || (value2 === null && value1 === 'N')) {
            isEquals = true;
        }
        return isEquals;
    }

    initStoreWithWidget() {
        if (this.widget) {
            let changedColumns = [];
            this.initStep0(changedColumns);
            this.initStep1(changedColumns);
            this.initStep2(changedColumns);
            this.initStep3(changedColumns);
            let shouldUpdateUF = false;
            const updateColUF = new Set(['AD_Tab_ID', 'AD_Table_ID']);
            for (const columnName of changedColumns) {
                if (updateColUF.has(columnName)) {
                    shouldUpdateUF = true;
                }
                this.setDatacontainerValue(columnName, this.getWidgetValue(columnName));
            }
            if (shouldUpdateUF) this.initUniversalFilter();
        }
    }

    initStep0(changedColumns: string[]) {
        this.updateStoreWithWidget('GridRequest', changedColumns);

        this.updateStoreWithWidget('AD_UserGridPreference_ID', changedColumns);

        this.updateStoreWithWidget('Record_ID', changedColumns);

        this.updateStoreWithWidget('AD_Role_ID', changedColumns);

        this.updateStoreWithWidget('AD_User_ID', changedColumns);
    }

    initStep1(changedColumns: string[]) {
        this.updateStoreWithWidget('AD_Widget_ID', changedColumns);

        this.updateStoreWithWidget('Name', changedColumns);

        this.updateStoreWithWidget('Description', changedColumns);

        this.updateStoreWithWidget('WidgetType', changedColumns);

        this.updateStoreWithWidget('DefaultView', changedColumns);

        this.updateStoreWithWidget('AngularClass', changedColumns);
    }

    initStep2(changedColumns: string[]) {
        this.updateStoreWithWidget('AD_Window_ID', changedColumns);

        this.updateStoreWithWidget('AD_Tab_ID', changedColumns);

        this.updateStoreWithWidget('AD_Table_ID', changedColumns);

        this.updateStoreWithWidget('CategoryColumn_ID', changedColumns);

        this.updateStoreWithWidget('CategorySort', changedColumns);

        this.updateStoreWithWidget('SerieColumn_ID', changedColumns);

        this.updateStoreWithWidget('SerieSort', changedColumns);

        this.updateStoreWithWidget('AggregateColumn_ID', changedColumns);

        this.updateStoreWithWidget('AggregateSort', changedColumns);

        this.updateStoreWithWidget('AggregateOperator', changedColumns);

        this.updateStoreWithWidget('RecordLimit', changedColumns);

        this.updateStoreWithWidget('Iframe', changedColumns);
    }

    initStep3(changedColumns: string[]) {
        this.updateStoreWithWidget('Multiplier', changedColumns);

        this.updateStoreWithWidget('IsRounded', changedColumns);

        this.updateStoreWithWidget('MeasureUnit', changedColumns);

        this.updateStoreWithWidget('Precision', changedColumns);

        this.updateStoreWithWidget('AlertLevel', changedColumns);

        this.updateStoreWithWidget('AlertColor', changedColumns);

        this.updateStoreWithWidget('NormalLevel', changedColumns);

        this.updateStoreWithWidget('NormalColor', changedColumns);

        this.updateStoreWithWidget('WarningColor', changedColumns);
    }

    updateStoreWithWidget(columnName: string, changedColumns: string[]) {
        let value = this.getWidgetValue(columnName);
        if (!this.checkDataDiff(this.dataStore.data[columnName], value)) {
            changedColumns.push(columnName);
        }
        this.dataStore.data[columnName] = value;
        if (this.dataStore.currentContext) this.dataStore.currentContext[columnName] = value;
    }

    getWidgetValue(columnName: string): any {
        let value = null;
        switch (columnName) {
            case 'AD_Widget_ID':
                value = this.widget.id;
                break;
            case 'AlertColor':
                value = this.widget.alertColor;
                break;
            case 'WarningColor':
                value = this.widget.warningColor;
                break;
            case 'NormalColor':
                value = this.widget.normalColor;
                break;
            case 'AlertLevel':
                value = this.widget.alertLevel;
                break;
            case 'NormalLevel':
                value = this.widget.normalLevel;
                break;
            case 'DefaultView':
                value = this.widget.defaultView;
                break;
            case 'AngularClass':
                value = this.widget.angularClass;
                break;
            case 'Description':
                value = this.widget.description;
                break;
            case 'Name':
                value = this.widget.name;
                break;
            case 'Record_ID':
                value = this.widget.recordId;
                break;
            case 'RecordLimit':
                value = this.widget.recordLimit;
                break;
            case 'AD_Tab_ID':
                value = this.widget.tabId;
                break;
            case 'AD_Table_ID':
                value = this.widget.tableId;
                break;
            case 'AD_Window_ID':
                value = this.widget.windowId;
                break;
            case 'WidgetType':
                value = this.widget.widgetType;
                break;
            case 'Multiplier':
                value = this.widget.multiplier;
                break;
            case 'CategoryColumn_ID':
                value = this.widget.categoryColumnId;
                break;
            case 'CategorySort':
                value = this.widget.categorySort;
                break;
            case 'SerieColumn_ID':
                value = this.widget.serieColumnId;
                break;
            case 'SerieSort':
                value = this.widget.serieSort;
                break;
            case 'AggregateColumn_ID':
                value = this.widget.aggregateColumnId;
                break;
            case 'AggregateSort':
                value = this.widget.aggregateSort;
                break;
            case 'AggregateOperator':
                value = this.widget.aggregateOperator;
                break;
            case 'IsRounded':
                value = this.widget.isRounded ? 'Y' : 'N';
                break;
            case 'MeasureUnit':
                value = this.widget.measureUnit;
                break;
            case 'Precision':
                value = this.widget.precision;
                break;
            case 'GridRequest':
                value = this.widget.precision;
                break;
            case 'AD_UserGridPreference_ID':
                value = this.widget.userGridPreferenceId;
                if (value == null || value == undefined) {
                    value = this?.gridPref?.userGridPreferenceID;
                }
                break;
            case 'AD_Role_ID':
                value = this.widget.roleId;
                break;
            case 'AD_User_ID':
                value = this.widget.userId;
                break;
            case 'Iframe':
                value = this.widget.iframe;
                break;
            default:
                break;
        }
        return value;
    }

    noticeWidgetChange() {
        this.widget = {...this.widget};
        if (this.activeWidget) {
            this.activeWidget.widget = this.widget;
        }
    }

    getMissingMantoryField(): string[] {
        const mandatoryFields = [];
        for (const dataContainer of this.dataContainers) {
            if (dataContainer && dataContainer.data && dataContainer.data.isMandatory) {
                if (
                    this.dataStore &&
                    (this.dataStore.data[dataContainer.data?.columnName] === null ||
                        this.dataStore.data[dataContainer.data?.columnName] === undefined) &&
                    (dataContainer.fieldValue === null || dataContainer.fieldValue === undefined)
                ) {
                    mandatoryFields.push(dataContainer.label);
                }
            }
        }
        return mandatoryFields;
    }

    saveWidget(event: Event) {
        if (event) {
            event.stopPropagation();
        }
        if (!this.checkAndShowMandatory()) {
            return;
        }

        if (
            (this.widget.name && this.widget.widgetType == 'GEN') ||
            this.widget.tableId ||
            (this.widget.windowId && this.widget.tabId) ||
            this.widget.iframe
        ) {
            this.subscriptions.push(
                this.widgetService
                    .saveWidget(this.widget)
                    .pipe(
                        map((widget) => {
                            if (widget && widget.id > 0) {
                                this.widget = widget;
                                this.initData(widget.id, false);
                                this.messageManager.newMessage(
                                    new IupicsMessage(
                                        this.translateService.instant('generic.success'),
                                        this.translateService.instant('widgetEditor.saveSuccess'),
                                        'success'
                                    )
                                );
                                this.updateInitialUFEmitter.emit(widget.id);
                            } else {
                                this.messageManager.newMessage(
                                    new IupicsMessage(
                                        this.translateService.instant('generic.error'),
                                        this.translateService.instant('widgetEditor.saveFailed'),
                                        'error'
                                    )
                                );
                            }
                            return widget;
                        }),
                        catchError((error) => {
                            this.messageManager.newMessage(
                                new IupicsMessage(
                                    this.translateService.instant('generic.error'),
                                    this.translateService.instant('widgetEditor.saveFailed'),
                                    'error'
                                )
                            );
                            return of(this.widget);
                        })
                    )
                    .subscribe()
            );
        } else {
            this.messageManager.newMessage(
                new IupicsMessage(
                    this.translateService.instant('generic.warning'),
                    this.translateService.instant('widgetEditor.missingParams'),
                    'warning'
                )
            );
        }
    }

    deleteWidget(event: Event) {
        if (event) {
            event.stopPropagation();
        }
        if (this.widget?.id) {
            Global.infoDialog.message = {
                summary: this.translateService.instant('generic.warning'),
                detail: this.translateService.instant('widgetEditor.deleteWidget'),
            };

            Global.infoDialog.dialogType = InfoDialogType.CONFIRM_YESNO;
            Global.infoDialog.showInfoDialog();
            const confirm = Global.infoDialog.confirm.subscribe((e: any) => {
                this.subscriptions.push(
                    this.widgetService.deleteWidget(this.widget.id).subscribe((success) => {
                        if (success) {
                            this.updateGridPreference();
                            this.widget = null;
                            if (this.gridPref) this.gridPref.widgetID = null;
                            this.initData();
                            this.updateInitialUFEmitter.emit(0);
                        } else {
                            this.messageManager.newMessage(
                                new IupicsMessage(
                                    this.translateService.instant('generic.error'),
                                    this.translateService.instant('widgetEditor.deleteFailed'),
                                    'error'
                                )
                            );
                        }
                        if (confirm !== undefined) {
                            confirm.unsubscribe();
                        }
                        if (cancel !== undefined) {
                            cancel.unsubscribe();
                        }
                    })
                );
            });
            const cancel = Global.infoDialog.cancel.subscribe((e) => {
                this.updateInitialUFEmitter.emit(-1);
                if (confirm !== undefined) {
                    confirm.unsubscribe();
                }
                if (cancel !== undefined) {
                    cancel.unsubscribe();
                }
            });
        }
    }

    updateGridPreference() {
        if (this.widget?.userGridPreferenceId > 0) {
            let gridPreference = this.gridPreferenceService.findGridPreferenceById(this.widget.userGridPreferenceId);
            if (gridPreference) {
                gridPreference.widgetID = null;
                this.gridPreferenceService.setGridPreferenceFromMap(gridPreference);
            }
        }
    }

    updateWidgetType() {
        switch (this.widget.widgetType) {
            case WidgetViewType.COUNT_LIST:
                this.widget.angularClass = 'CountListWidgetUiComponent';
                this.widget.categoryColumnId = null;
                this.widget.categorySort = null;
                this.widget.serieColumnId = null;
                this.widget.serieSort = null;
                break;
            case WidgetViewType.GRID_VIEW_WIDGET:
                this.widget.angularClass = 'GridWidgetComponent';
                this.widget.tableId = null;
                this.widget.categoryColumnId = null;
                this.widget.categorySort = null;
                this.widget.serieColumnId = null;
                this.widget.serieSort = null;
                this.widget.aggregateColumnId = null;
                this.widget.aggregateOperator = null;
                this.widget.aggregateSort = null;
                break;
            case WidgetViewType.GEN_WIDGET:
                break;
            case WidgetViewType.METABASE_CHART:
                this.widget.angularClass = 'MetabaseWidgetComponent';
                this.widget.windowId = null;
                this.widget.tabId = null;
                this.widget.tableId = null;
                this.widget.categoryColumnId = null;
                this.widget.categorySort = null;
                this.widget.serieColumnId = null;
                this.widget.serieSort = null;
                this.widget.aggregateColumnId = null;
                this.widget.aggregateOperator = null;
                this.widget.aggregateSort = null;
                break;
            default:
                this.widget.angularClass = 'ChartWidgetComponent';
                break;
        }
        this.switchWidgetType();
    }

    switchWidgetType() {
        const component = CacheManagerService.iupics_widgets.get(this.widget.angularClass);
        if (component && (this.activeWidgetType !== this.widget.widgetType || this.widget.widgetType === 'GEN')) {
            this.initStoreWithWidget();
            this.previewWidgetVcr.clear();
            const componentRef = this.previewWidgetVcr.createComponent(component);
            this.activeWidget = componentRef.instance;
            this.activeWidgetType = this.widget.widgetType;
            componentRef.instance.widget = this.widget;
            componentRef.instance.isPreview = true;
            componentRef.instance.activeView = this.widget.defaultView;
            componentRef.instance.widgetChanged.subscribe((widget) => {
                this.widget = {...widget};
            });
        } else {
            this.initStoreWithWidget();
            this.activeWidgetType = null;
            this.activeWidget = null;
            this.previewWidgetVcr.clear();
        }
    }

    showCMChecker(item: WidgetCreatorStep): boolean {
        if (item.id === 1) {
            return true;
        }
        return false;
    }

    initWidgetRoles() {
        this.widgetRoles = [];
        const roles = this.connectorService.getIupicsUserAccount().roles;
        for (const role of roles) {
            this.roleNames.items.push({id: role.role_id, displayValue: role.name});
            if (this.widget?.widgetRoles && this.widget.widgetRoles.includes(role.role_id))
                this.widgetRoles.push({id: role.role_id, displayValue: role.name});
        }
    }

    widgetRolesChange(widgetRoles) {
        this.widget.newWidgetRoles = widgetRoles.map((obj) => obj.id);
    }

    initUniversalFilter() {
        this.searchColumns = null;
        this.showUF = false;
        this.detectorRef.detectChanges();
        if (this.widget?.userGridPreferenceId <= 0) {
            let obs = null;
            if (this.widget.tableId > 0) {
                obs = this.widgetService.getSearchColumns(this.widget).pipe(
                    map((searchColumns) => {
                        if (searchColumns?.length > 0) {
                            this.searchColumns = searchColumns;
                        }
                        return this.searchColumns != null;
                    })
                );
            } else if (this.widget.windowId > 0 && this.widget.tabId > 0) {
                obs = this.uiCreator.getWindow(this.widget.windowId).pipe(
                    map((windowDef) => {
                        return true;
                    }),
                    catchError(() => {
                        return of(false);
                    })
                );
            }
            if (obs !== null) {
                this.subscriptions.push(
                    obs.subscribe((success) => {
                        if (success) {
                            this.showUF = true;
                            this.detectorRef.detectChanges();
                            this.setUfFilters();
                        }
                    })
                );
            }
        }
    }

    onFilterChange(result: { filterToApply: CompiereDataGridRequestJSON; isNotFromUF: boolean }) {
        if (!this.widget.userGridPreferenceId && !result.isNotFromUF) {
            let req = result.filterToApply;
            if (this.widget.tabId > 0) {
                req.entityId = this.widget.tabId;
                req.windowType = CompiereDataGridType.WINDOW;
            } else if (this.widget.tableId > 0 && this.searchColumns) {
                req.tableName = this.searchColumns[0]?.field?.tableName;
                req.windowType = CompiereDataGridType.TABLE;
            }
            this.widget.gridRequest = JSON.stringify(result.filterToApply);
            this.dataStore.data['GridRequest'] = this.widget.gridRequest;
            this.noticeWidgetChange();
        }
    }

    setUfFilters() {
        if (!this.widget.userGridPreferenceId && this.widget?.gridRequest?.length > 0) {
            const request = JSON.parse(this.widget.gridRequest);
            this.updateFieldUFEmitter.emit(request);
        }
    }

    //override
    notifyFromDataChange(item?: any) {
        if (item.data) {
            const value = this.dataStore.data[item.data.columnName]?.id ?? this.dataStore.data[item.data.columnName];

            switch (item.data.columnName) {
                case 'AD_Widget_ID':
                    if (value > 0) {
                        this.widget = cloneDeep(this.widgetService.getIupicsWidget(value));
                    } else {
                        this.widget = null;
                    }
                    this.initData(value);
                    return;
                case 'AlertColor':
                    this.widget.alertColor = value;
                    break;
                case 'WarningColor':
                    this.widget.warningColor = value;
                    break;
                case 'NormalColor':
                    this.widget.normalColor = value;
                    break;
                case 'AlertLevel':
                    this.widget.alertLevel = value;
                    break;
                case 'NormalLevel':
                    this.widget.normalLevel = value;
                    break;
                case 'DefaultView':
                    this.widget.defaultView = value;
                    break;
                case 'AngularClass':
                    this.widget.angularClass = value;
                    this.switchWidgetType();
                    break;
                case 'Description':
                    this.widget.description = value;
                    break;
                case 'Name':
                    this.widget.name = value;
                    break;
                case 'RecordId':
                    this.widget.recordId = value;
                    break;
                case 'RecordLimit':
                    this.widget.recordLimit = value;
                    break;
                case 'AD_Tab_ID':
                    this.widget.tabId = value;
                    this.widget.categoryColumnId = null;
                    this.widget.categorySort = null;
                    this.widget.serieColumnId = null;
                    this.widget.serieSort = null;
                    this.widget.aggregateColumnId = null;
                    this.widget.aggregateOperator = null;
                    this.widget.aggregateSort = null;
                    this.searchColumns = null;
                    this.widget.gridRequest = null;
                    this.initUniversalFilter();
                    break;
                case 'AD_Table_ID':
                    this.widget.tableId = value;
                    this.widget.windowId = null;
                    this.widget.tabId = null;
                    this.widget.categoryColumnId = null;
                    this.widget.categorySort = null;
                    this.widget.serieColumnId = null;
                    this.widget.serieSort = null;
                    this.widget.aggregateColumnId = null;
                    this.widget.aggregateOperator = null;
                    this.widget.aggregateSort = null;
                    this.searchColumns = null;
                    this.widget.gridRequest = null;
                    this.initUniversalFilter();
                    break;
                case 'AD_Window_ID':
                    this.widget.windowId = value;
                    this.widget.tableId = null;
                    this.widget.tabId = null;
                    this.widget.categoryColumnId = null;
                    this.widget.categorySort = null;
                    this.widget.serieColumnId = null;
                    this.widget.serieSort = null;
                    this.widget.aggregateColumnId = null;
                    this.widget.aggregateOperator = null;
                    this.widget.aggregateSort = null;
                    this.searchColumns = null;
                    this.widget.gridRequest = null;
                    break;
                case 'Iframe':
                    this.widget.iframe = value;
                    this.widget.windowId = null;
                    this.widget.tableId = null;
                    this.widget.tabId = null;
                    this.widget.categoryColumnId = null;
                    this.widget.categorySort = null;
                    this.widget.serieColumnId = null;
                    this.widget.serieSort = null;
                    this.widget.aggregateColumnId = null;
                    this.widget.aggregateOperator = null;
                    this.widget.aggregateSort = null;
                    this.searchColumns = null;
                    this.widget.gridRequest = null;
                    break;
                case 'WidgetType':
                    if (!this.checkDataDiff(value, this.widget.widgetType)) {
                        this.widget.widgetType = value;
                        if (this.widget.widgetType === WidgetViewType.COUNT_LIST) {
                            this.widget.defaultView = CountListViewType.COUNT_VIEW;
                        } else {
                            this.widget.defaultView = null;
                        }
                        this.updateWidgetType();
                        return;
                    }
                    break;
                case 'Multiplier':
                    this.widget.multiplier = value;
                    break;
                case 'CategoryColumn_ID':
                    this.widget.categoryColumnId = value;
                    if (!value) {
                        this.widget.categorySort = null;
                    }
                    break;
                case 'CategorySort':
                    this.widget.categorySort = value;
                    break;
                case 'SerieColumn_ID':
                    this.widget.serieColumnId = value;
                    if (!value) {
                        this.widget.serieSort = null;
                    }
                    break;
                case 'SerieSort':
                    this.widget.serieSort = value;
                    break;
                case 'AggregateColumn_ID':
                    this.widget.aggregateColumnId = value;
                    if (!value) {
                        this.widget.aggregateSort = null;
                    }
                    break;
                case 'AggregateSort':
                    this.widget.aggregateSort = value;
                    break;
                case 'AggregateOperator':
                    this.widget.aggregateOperator = value;
                    break;
                case 'IsRounded':
                    this.widget.isRounded = value == 'Y';
                    break;
                case 'MeasureUnit':
                    this.widget.measureUnit = value;
                    break;
                case 'Precision':
                    this.widget.precision = value;
                    break;
                default:
                    break;
            }
            this.initStoreWithWidget();
            this.noticeWidgetChange();
        }
    }

    setDataContainersValueWithChangedStore(dataStore?: DataStore) {
    }
}

export interface WidgetCreatorStep {
    label: string;
    id: number;
}
