import { NgClass } from '@angular/common';
import { Component, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { CompiereDataGridType, DataStoreRequest } from '@compiere-ws/models/compiere-data-json';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import SpecificWindowUiComponent from '@iupics-components/specific/window/specific-window-ui/specific-window-ui.component';
import AutocompleteUiComponent from '@iupics-components/standard/fields/autocomplete-ui/autocomplete-ui.component';
import ButtonUiComponent from '@iupics-components/standard/fields/button-ui/button-ui.component';
import EditViewUiComponent from '@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component';
import ModalUiComponent from '@iupics-components/standard/layouts/modal-ui/modal-ui.component';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { DynamicContainerDirective } from '@iupics-util/directives/dynamic-container.directive';
import { TranslateModule } from '@ngx-translate/core';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanelModule } from 'primeng/scrollpanel';

@Component({
  selector: 'iu-account-editor-window-ui',
  templateUrl: './account-editor-window-ui.component.html',
  styleUrls: ['./account-editor-window-ui.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    ProgressBarModule,
    ScrollPanelModule,
    DynamicContainerDirective,
    ButtonUiComponent,
    ModalUiComponent,
    // UniversalFilterUiComponent,
    // GridTabInfinityScrollUiComponent,
    TranslateModule,
  ],
})
export default class AccountEditorWindowUiComponent extends SpecificWindowUiComponent implements OnInit {
  @ViewChild('left', { read: ViewContainerRef, static: true })
  vcrLeft: ViewContainerRef;
  @ViewChild('top', { read: ViewContainerRef, static: true })
  vcrTop: ViewContainerRef;
  @ViewChild('bot', { read: ViewContainerRef, static: true })
  @Output()
  vcrBot: ViewContainerRef;
  @ViewChild('hidden', { read: ViewContainerRef, static: true })
  vcrHidden: ViewContainerRef;
  verifAlias = false;
  verifAcc = false;
  checked = false;
  checkedNode = false;
  combination: string;
  offset: number;
  limit: number;
  hideColumns: string[] = [];
  displayColumns: string[] = [];
  firstRowSelected: number;
  accountIndex: number;
  orgIndex: number;
  lastRowSelected: any;
  hasAlias: string;
  processId = 0;

  isModal: boolean = false;

  ngOnInit() {
    if (this.parentComponent instanceof EditViewUiComponent) {
      if (!this.dataStore) {
        this.dataStore = this.store.newSpecificWindowData(
            this.formId,
            (<EditViewUiComponent>this.parentComponent).editTabs[0].dataStored
        );
      }
    }

    let acctSchemaId =
        this.dataStore.data.C_AcctSchema_ID !== undefined && this.dataStore.data.C_AcctSchema_ID !== null
            ? this.dataStore.data.C_AcctSchema_ID.id
            : null;
    if (acctSchemaId === undefined || acctSchemaId === null) {
      const currentContext = this.getCurrentContext();
      if (currentContext.C_AcctSchema_ID !== undefined && currentContext.C_AcctSchema_ID !== null) {
        acctSchemaId = currentContext.C_AcctSchema_ID.id
            ? currentContext.C_AcctSchema_ID.id
            : currentContext.C_AcctSchema_ID;
      } else if (currentContext.$C_AcctSchema_ID !== undefined && currentContext.$C_AcctSchema_ID !== null) {
        acctSchemaId = currentContext.$C_AcctSchema_ID.id
            ? currentContext.$C_AcctSchema_ID.id
            : currentContext.$C_AcctSchema_ID;
      }
    }
    if (acctSchemaId !== undefined && acctSchemaId !== null) {
      this.subscriptions.push(
          this.uiCreator
              .getDBSelect(
                  "SELECT ELEMENTTYPE,SEQNO FROM C_AcctSchema_Element WHERE C_AcctSchema_ID=? AND IsActive= 'Y' ORDER BY SeqNo ",
                  [acctSchemaId],
                  []
              )
              .subscribe((response) => {
                if (response !== undefined && !this.verifAcc) {
                  this.subscriptions.push(
                      this.uiCreator
                          .getDBSelect('SELECT HasAlias FROM  C_AcctSchema WHERE C_AcctSchema_ID=?', [acctSchemaId], [])
                          .subscribe((data) => {
                            let i = 0;
                            let j = 0;
                            const vcr = 'vcrTop';
                            if (data !== undefined && !this.verifAlias) {
                              this.hasAlias = data[0].HASALIAS;
                              if (data[0].HASALIAS === 'Y') {
                                this.customDesignArray.push({
                                  vcr: vcr,
                                  type: CustomDesignItemType.FIELD,
                                  columnName: 'Alias',
                                  cssClass: 'p-col-12 p-md-4',
                                });
                                this.displayColumns.push('Alias');
                                j++;
                              } else {
                                this.customDesignArray.push({
                                  vcr: 'vcrHidden',
                                  type: CustomDesignItemType.FIELD,
                                  columnName: 'Alias',
                                });
                                this.hideColumns.push('Alias');
                              }
                              this.verifAlias = true;
                            }
                            this.customDesignArray.push({
                              vcr: vcr,
                              type: CustomDesignItemType.FIELD,
                              columnName: 'Combination',
                              cssClass: 'p-col-12 p-md-4',
                            });
                            this.displayColumns.push('Combination');
                            j++;

                            if (response[i] !== undefined) {
                              if (response[i].ELEMENTTYPE === 'OO') {
                                // vcr = this.addVcr(i + j + 1);
                                this.customDesignArray.push({
                                  vcr: vcr,
                                  type: CustomDesignItemType.FIELD,
                                  columnName: 'AD_Org_ID',
                                  cssClass: 'p-col-12 p-md-4',
                                });
                                this.displayColumns.push('AD_Org_ID');
                                this.orgIndex = i + j;
                                i++;
                              } else {
                                this.customDesignArray.push({
                                  vcr: 'vcrHidden',
                                  type: CustomDesignItemType.FIELD,
                                  columnName: 'AD_Org_ID',
                                });
                                this.hideColumns.push('AD_Org_ID');
                              }
                            } else {
                              this.customDesignArray.push({
                                vcr: 'vcrHidden',
                                type: CustomDesignItemType.FIELD,
                                columnName: 'AD_Org_ID',
                              });
                              this.hideColumns.push('AD_Org_ID');
                            }
                            if (response[i] !== undefined) {
                              if (response[i].ELEMENTTYPE === 'AC') {
                                // vcr = this.addVcr(i + j + 1);
                                this.customDesignArray.push({
                                  vcr: vcr,
                                  type: CustomDesignItemType.FIELD,
                                  columnName: 'Account_ID',
                                  cssClass: 'p-col-12 p-md-4',
                                });
                                this.displayColumns.push('Account_ID');
                                this.accountIndex = i + j;
                                i++;
                              } else {
                                this.customDesignArray.push({
                                  vcr: 'vcrHidden',
                                  type: CustomDesignItemType.FIELD,
                                  columnName: 'Account_ID',
                                });
                                this.hideColumns.push('Account_ID');
                              }
                            } else {
                              this.customDesignArray.push({
                                vcr: 'vcrHidden',
                                type: CustomDesignItemType.FIELD,
                                columnName: 'Account_ID',
                              });
                              this.hideColumns.push('Account_ID');
                            }
                            if (response[i] !== undefined) {
                              if (response[i].ELEMENTTYPE === 'U1') {
                                // vcr = this.addVcr(i + j + 1);
                                this.customDesignArray.push({
                                  vcr: vcr,
                                  type: CustomDesignItemType.FIELD,
                                  columnName: 'User1_ID',
                                  cssClass: 'p-col-12 p-md-4',
                                });
                                this.displayColumns.push('User1_ID');
                                i++;
                              } else {
                                this.customDesignArray.push({
                                  vcr: 'vcrHidden',
                                  type: CustomDesignItemType.FIELD,
                                  columnName: 'User1_ID',
                                });
                                this.hideColumns.push('User1_ID');
                              }
                            } else {
                              this.customDesignArray.push({
                                vcr: 'vcrHidden',
                                type: CustomDesignItemType.FIELD,
                                columnName: 'User1_ID',
                              });
                              this.hideColumns.push('User1_ID');
                            }
                            if (response[i] !== undefined) {
                              if (response[i].ELEMENTTYPE === 'U2') {
                                // vcr = this.addVcr(i + j + 1);
                                this.customDesignArray.push({
                                  vcr: vcr,
                                  type: CustomDesignItemType.FIELD,
                                  columnName: 'User2_ID',
                                  cssClass: 'p-col-12 p-md-4',
                                });
                                this.displayColumns.push('User2_ID');
                                i++;
                              } else {
                                this.customDesignArray.push({
                                  vcr: 'vcrHidden',
                                  type: CustomDesignItemType.FIELD,
                                  columnName: 'User2_ID',
                                });
                                this.hideColumns.push('User2_ID');
                              }
                            } else {
                              this.customDesignArray.push({
                                vcr: 'vcrHidden',
                                type: CustomDesignItemType.FIELD,
                                columnName: 'User2_ID',
                              });
                              this.hideColumns.push('User2_ID');
                            }
                            if (response[i] !== undefined) {
                              if (response[i].ELEMENTTYPE === 'PJ') {
                                // vcr = this.addVcr(i + j + 1);
                                this.customDesignArray.push({
                                  vcr: vcr,
                                  type: CustomDesignItemType.FIELD,
                                  columnName: 'C_Project_ID',
                                  cssClass: 'p-col-12 p-md-4',
                                });
                                this.displayColumns.push('C_Project_ID');
                                i++;
                              } else {
                                this.customDesignArray.push({
                                  vcr: 'vcrHidden',
                                  type: CustomDesignItemType.FIELD,
                                  columnName: 'C_Project_ID',
                                });
                                this.hideColumns.push('C_Project_ID');
                              }
                            } else {
                              this.customDesignArray.push({
                                vcr: 'vcrHidden',
                                type: CustomDesignItemType.FIELD,
                                columnName: 'C_Project_ID',
                              });
                              this.hideColumns.push('C_Project_ID');
                            }
                            if (response[i] !== undefined) {
                              if (response[i].ELEMENTTYPE === 'OT') {
                                // vcr = this.addVcr(i + j + 1);
                                this.customDesignArray.push({
                                  vcr: vcr,
                                  type: CustomDesignItemType.FIELD,
                                  columnName: 'AD_OrgTrx_ID',
                                  cssClass: 'p-col-12 p-md-4',
                                });
                                this.displayColumns.push('AD_OrgTrx_ID');
                                i++;
                              } else {
                                this.customDesignArray.push({
                                  vcr: 'vcrHidden',
                                  type: CustomDesignItemType.FIELD,
                                  columnName: 'AD_OrgTrx_ID',
                                });
                                this.hideColumns.push('AD_OrgTrx_ID');
                              }
                            } else {
                              this.customDesignArray.push({
                                vcr: 'vcrHidden',
                                type: CustomDesignItemType.FIELD,
                                columnName: 'AD_OrgTrx_ID',
                              });
                              this.hideColumns.push('AD_OrgTrx_ID');
                            }
                            if (response[i] !== undefined) {
                              if (response[i].ELEMENTTYPE === 'BP') {
                                // vcr = this.addVcr(i + j + 1);
                                this.customDesignArray.push({
                                  vcr: vcr,
                                  type: CustomDesignItemType.FIELD,
                                  columnName: 'C_BPartner_ID',
                                  cssClass: 'p-col-12 p-md-4',
                                });
                                this.displayColumns.push('C_BPartner_ID');
                                i++;
                              } else {
                                this.customDesignArray.push({
                                  vcr: 'vcrHidden',
                                  type: CustomDesignItemType.FIELD,
                                  columnName: 'C_BPartner_ID',
                                });
                                this.hideColumns.push('C_BPartner_ID');
                              }
                            } else {
                              this.customDesignArray.push({
                                vcr: 'vcrHidden',
                                type: CustomDesignItemType.FIELD,
                                columnName: 'C_BPartner_ID',
                              });
                              this.hideColumns.push('C_BPartner_ID');
                            }
                            if (response[i] !== undefined) {
                              if (response[i].ELEMENTTYPE === 'PR') {
                                // vcr = this.addVcr(i + j + 1);
                                this.customDesignArray.push({
                                  vcr: vcr,
                                  type: CustomDesignItemType.FIELD,
                                  columnName: 'M_Product_ID',
                                  cssClass: 'p-col-12 p-md-4',
                                });
                                this.displayColumns.push('M_Product_ID');
                                i++;
                              } else {
                                this.customDesignArray.push({
                                  vcr: 'vcrHidden',
                                  type: CustomDesignItemType.FIELD,
                                  columnName: 'M_Product_ID',
                                });
                                this.hideColumns.push('M_Product_ID');
                              }
                            } else {
                              this.customDesignArray.push({
                                vcr: 'vcrHidden',
                                type: CustomDesignItemType.FIELD,
                                columnName: 'M_Product_ID',
                              });
                              this.hideColumns.push('M_Product_ID');
                            }
                            if (response[i] !== undefined) {
                              if (response[i].ELEMENTTYPE === 'MC') {
                                // vcr = this.addVcr(i + j + 1);
                                this.customDesignArray.push({
                                  vcr: vcr,
                                  type: CustomDesignItemType.FIELD,
                                  columnName: 'C_Campaign_ID',
                                  cssClass: 'p-col-12 p-md-4',
                                });
                                this.displayColumns.push('C_Campaign_ID');
                                i++;
                              } else {
                                this.customDesignArray.push({
                                  vcr: 'vcrHidden',
                                  type: CustomDesignItemType.FIELD,
                                  columnName: 'C_Campaign_ID',
                                });
                                this.hideColumns.push('C_Campaign_ID');
                              }
                            } else {
                              this.customDesignArray.push({
                                vcr: 'vcrHidden',
                                type: CustomDesignItemType.FIELD,
                                columnName: 'C_Campaign_ID',
                              });
                              this.hideColumns.push('C_Campaign_ID');
                            }
                            if (response[i] !== undefined) {
                              if (response[i].ELEMENTTYPE === 'SA') {
                                // vcr = this.addVcr(i + j + 1);
                                this.customDesignArray.push({
                                  vcr: vcr,
                                  type: CustomDesignItemType.FIELD,
                                  columnName: 'C_SubAcct_ID',
                                  cssClass: 'p-col-12 p-md-4',
                                });
                                this.displayColumns.push('C_SubAcct_ID');
                                i++;
                              } else {
                                this.customDesignArray.push({
                                  vcr: 'vcrHidden',
                                  type: CustomDesignItemType.FIELD,
                                  columnName: 'C_SubAcct_ID',
                                });
                                this.hideColumns.push('C_SubAcct_ID');
                              }
                            } else {
                              this.customDesignArray.push({
                                vcr: 'vcrHidden',
                                type: CustomDesignItemType.FIELD,
                                columnName: 'C_SubAcct_ID',
                              });
                              this.hideColumns.push('C_SubAcct_ID');
                            }
                            if (response[i] !== undefined) {
                              if (response[i].ELEMENTTYPE === 'SR') {
                                // vcr = this.addVcr(i + j + 1);
                                this.customDesignArray.push({
                                  vcr: vcr,
                                  type: CustomDesignItemType.FIELD,
                                  columnName: 'C_SalesRegion_ID',
                                  cssClass: 'p-col-12 p-md-4',
                                });
                                this.displayColumns.push('C_SalesRegion_ID');
                                i++;
                              } else {
                                this.customDesignArray.push({
                                  vcr: 'vcrHidden',
                                  type: CustomDesignItemType.FIELD,
                                  columnName: 'C_SalesRegion_ID',
                                });
                                this.hideColumns.push('C_SalesRegion_ID');
                              }
                            } else {
                              this.customDesignArray.push({
                                vcr: 'vcrHidden',
                                type: CustomDesignItemType.FIELD,
                                columnName: 'C_SalesRegion_ID',
                              });
                              this.hideColumns.push('C_SalesRegion_ID');
                            }
                            if (response[i] !== undefined) {
                              if (response[i].ELEMENTTYPE === 'LF') {
                                // vcr = this.addVcr(i + j + 1);
                                this.customDesignArray.push({
                                  vcr: vcr,
                                  type: CustomDesignItemType.FIELD,
                                  columnName: 'C_LocFrom_ID',
                                  cssClass: 'p-col-12 p-md-4',
                                });
                                this.displayColumns.push('C_LocFrom_ID');
                                i++;
                              } else {
                                this.customDesignArray.push({
                                  vcr: 'vcrHidden',
                                  type: CustomDesignItemType.FIELD,
                                  columnName: 'C_LocFrom_ID',
                                });
                                this.hideColumns.push('C_LocFrom_ID');
                              }
                            } else {
                              this.customDesignArray.push({
                                vcr: 'vcrHidden',
                                type: CustomDesignItemType.FIELD,
                                columnName: 'C_LocFrom_ID',
                              });
                              this.hideColumns.push('C_LocFrom_ID');
                            }
                            if (response[i] !== undefined) {
                              if (response[i].ELEMENTTYPE === 'LT') {
                                // vcr = this.addVcr(i + j + 1);
                                this.customDesignArray.push({
                                  vcr: vcr,
                                  type: CustomDesignItemType.FIELD,
                                  columnName: 'C_LocTo_ID',
                                  cssClass: 'p-col-12 p-md-4',
                                });
                                this.displayColumns.push('C_LocTo_ID');
                                i++;
                              } else {
                                this.customDesignArray.push({
                                  vcr: 'vcrHidden',
                                  type: CustomDesignItemType.FIELD,
                                  columnName: 'C_LocTo_ID',
                                });
                                this.hideColumns.push('C_LocTo_ID');
                              }
                            } else {
                              this.customDesignArray.push({
                                vcr: 'vcrHidden',
                                type: CustomDesignItemType.FIELD,
                                columnName: 'C_LocTo_ID',
                              });
                              this.hideColumns.push('C_LocTo_ID');
                            }
                            if (response[i] !== undefined) {
                              if (response[i].ELEMENTTYPE === 'AY') {
                                // vcr = this.addVcr(i + j + 1);
                                this.customDesignArray.push({
                                  vcr: vcr,
                                  type: CustomDesignItemType.FIELD,
                                  columnName: 'C_Activity_ID',
                                  cssClass: 'p-col-12 p-md-4',
                                });
                                this.displayColumns.push('C_Activity_ID');
                                i++;
                              } else {
                                this.customDesignArray.push({
                                  vcr: 'vcrHidden',
                                  type: CustomDesignItemType.FIELD,
                                  columnName: 'C_Activity_ID',
                                });
                                this.hideColumns.push('C_Activity_ID');
                              }
                            } else {
                              this.customDesignArray.push({
                                vcr: 'vcrHidden',
                                type: CustomDesignItemType.FIELD,
                                columnName: 'C_Activity_ID',
                              });
                              this.hideColumns.push('C_Activity_ID');
                            }

                            this.customDesignArray.push(
                                {
                                  vcr: 'vcrLeft',
                                  type: CustomDesignItemType.FIELD,
                                  columnName: 'CreateNewCombination',
                                  cssClass: 'p-col-12',
                                },
                                /**
                                 * @start_custo_code
                                 */
                                {
                                  vcr: 'vcrTop', // vcrMiddle
                                  type: CustomDesignItemType.FIELD,
                                  columnName: 'Search',
                                  cssClass: 'p-col-12',
                                },
                                {
                                  vcr: 'vcrRight',
                                  type: CustomDesignItemType.FIELD,
                                  columnName: 'C_AcctSchema_ID',
                                  cssClass: 'p-col-12',
                                }
                                /**
                                 * @end_custo_code
                                 */
                            );
                            if (this.parentComponent instanceof EditViewUiComponent) {
                              if (
                                  (<EditViewUiComponent>this.parentComponent).editTabs[0].dataStored.data[
                                      this.parentComponent.container.accountEditor_ColumnSearch
                                      ] !== undefined &&
                                  (<EditViewUiComponent>this.parentComponent).editTabs[0].dataStored.data[
                                      this.parentComponent.container.accountEditor_ColumnSearch
                                      ] !== null
                              ) {
                                this.combination = (<EditViewUiComponent>this.parentComponent).editTabs[0].dataStored.data[
                                    this.parentComponent.container.accountEditor_ColumnSearch
                                    ].id;
                              }
                              this.customDesignArray.push({
                                vcr: 'vcrBot',
                                type: CustomDesignItemType.GRID,
                                tableName: 'AccountEditorWindow_table',
                                cssClass: 'p-col-12',
                                shouldCreateQuery: true,
                                shouldSelectFirst: true,
                                rowSelection: 'single',
                                hiddenColumns: this.hideColumns,
                              });
                              let recordId;
                              if (this.combination !== undefined && this.combination !== null) {
                                recordId = `C_ValidCombination_ID,${this.combination}`;
                              }
                              this.synchronizeData(recordId, true);
                            }
                          })
                  );
                  this.verifAcc = true;
                }
              })
      );
    }
  }

  /*addVcr(i: number) {
    let vcr: string;
    for (let k = 0; k < 5; k++) {
      if (i === 2 + k * 3) {
        vcr = 'vcrMiddle';
      }
      if (i === k * 3 && i !== 0) {
        vcr = 'vcrRight';
      }
      if (i === 4 + k * 3) {
        vcr = 'vcrLeft';
      }
    }
    return vcr;
  }*/

  selectComination() {
    // permet d'annuler le refresh de l'editViewParent à la fermeture de la fenetre
    if (<EditViewUiComponent>this.parentComponent) {
      this.parentComponent.isProcessLaunch = false;
    }
    this.dataStore.data.selections[0].selection =
        this.gridViews[0].GridTabInfinityScrollUiComponent.grid.api.getSelectedRows();
    const sourceFieldName = this.sourceComponent.data.columnName;
    if (sourceFieldName) {
      const event = {
        data: {
          Data_UUID: sourceFieldName + ',' + this.dataStore.data.selections[0].selection[0].C_ValidCombination_ID,
        },
      };
      (<AutocompleteUiComponent>this.sourceComponent).setSearchSelectItem(event);
    }
  }
  synchronizeData(ValidCombinationId: string, start = false) {
    if (ValidCombinationId) {
      const adWindowId = 153;
      const adTabId = 207;

      const request: DataStoreRequest = {
        windowId: adWindowId,
        record_id: ValidCombinationId,
        parent_constraint: null,
        compiereRequest: {
          windowType: CompiereDataGridType.WINDOW,
          entityId: adTabId,
          startRow: 0,
          endRow: 1,
        },
      };
      this.subscriptions.push(
          this.store.getWindowSingleData(request).subscribe((dataWs) => {
            if (dataWs !== undefined) {
              const tabOfColumns = Object.keys(dataWs.data);
              for (let i = 0; i < this.displayColumns.length; i++) {
                const value: string = this.displayColumns[i].toString();
                if (tabOfColumns.includes(value)) {
                  if (typeof dataWs.data[value] === 'string') {
                    if (dataWs.data[value] !== null) {
                      this.dataStore.data[value] = dataWs.data[value];
                    } else {
                      this.dataStore.data[value] = null;
                    }
                  }
                  if (typeof dataWs.data[value] === 'object') {
                    if (dataWs.data[value] !== undefined && dataWs.data[value] !== null) {
                      if (dataWs.data[value].displayValue !== null) {
                        if (value === 'AD_Org_ID') {
                          this.dataStore.data[value] = dataWs.data[value];
                        }
                      }
                    } else {
                      this.dataStore.data[value] = null;
                    }
                  }
                }
              }
            }

            if (start) {
              this.showSpecificWindow();
            }
            this.setDataContainersValueWithChangedStore();
          })
      );
    } else {
      if (start) {
        this.showSpecificWindow();
      }
    }
  }

  notifyFromRowSelected(rowSelected: any) {
    const LengthOfRowSelected = this.gridViews[0].GridTabInfinityScrollUiComponent.grid.api.getSelectedRows().length;
    if (this.lastRowSelected !== undefined) {
      const ValidCombination = this.lastRowSelected.C_ValidCombination_ID;

      this.gridViews[0].GridTabInfinityScrollUiComponent.grid.api.forEachNode(function (node) {
        if (LengthOfRowSelected === 0) {
          const data = node.getRowData();
          if (data && data.C_ValidCombination_ID === ValidCombination) {
            node.setSelected(true);
          }
        }
      });
    } else {
      const rowNode = this.gridViews[0].GridTabInfinityScrollUiComponent.grid.api.getRowAtIndex(0);
      if (rowNode) {
        const rowNodeData = rowNode.getRowData();
        this.gridViews[0].GridTabInfinityScrollUiComponent.grid.api.forEachNode(function (node) {
          const nodeData = node.getRowData();
          if (LengthOfRowSelected === 0) {
            if (nodeData.C_ValidCombination_ID === rowNodeData.C_ValidCombination_ID) {
              node.setSelected(true);
            }
          }
        });
        this.lastRowSelected = rowNodeData;
        this.dataStore.data.selections[0].selection = rowNodeData;
      }
    }

    if (rowSelected.length === 1) {
      this.firstRowSelected = rowSelected[0].C_ValidCombination_ID;
      const ValidCombinationId = rowSelected[0].Data_UUID;
      this.lastRowSelected = rowSelected[0];
      this.synchronizeData(ValidCombinationId);
    }
    if (rowSelected.length > 1) {
      this.gridViews[0].GridTabInfinityScrollUiComponent.grid.api.forEachNode((node) => {
        for (let i = 0; i < rowSelected.length; i++) {
          if (rowSelected[i] !== undefined) {
            const nodeData = node.getRowData();
            if (
                nodeData.C_ValidCombination_ID === rowSelected[i].C_ValidCombination_ID &&
                nodeData.C_ValidCombination_ID !== this.firstRowSelected
            ) {
              this.gridViews[0].GridTabInfinityScrollUiComponent.grid.api.deselectAll();
              this.gridViews[0].GridTabInfinityScrollUiComponent.grid.api
                  .getRowAtIndex(node.rowIndex)
                  .setSelected(true);
              const selectedSingleRow = rowSelected[i];
              const ValidCombinationId = rowSelected[i].Data_UUID;
              this.firstRowSelected = rowSelected[i].C_ValidCombination_ID;
              this.synchronizeData(ValidCombinationId);
              this.dataStore.data.selections[0].selection.pop();
              this.dataStore.data.selections[0].selection = selectedSingleRow;
              this.lastRowSelected = selectedSingleRow;
            }
          }
        }
      });
    }
  }
  checkBeforeProcessing() {
    if (this.processId === 0) {
      const { processId } = this.fields.find(({ data }) => data.columnName === 'CreateNewCombination');
      if (processId) {
        this.processId = processId;
      }
    }
    if (this.dataStore.data.AD_Org_ID === null || this.dataStore.data.Account_ID === null) {
      this.messageManager.newMessage(
          new IupicsMessage(
              this.translateService.instant('specificWindow.fillMandatory'),
              this.fields[this.orgIndex].data.label + ' , ' + this.fields[this.accountIndex].data.label,
              'error'
          )
      );
    } else if (this.hasAlias === 'Y' && this.dataStore.data.Alias) {
      const duplicateAlias =
          "(SELECT COUNT(*) AS COUNT FROM C_ValidCombination WHERE Alias = '" +
          this.dataStore.data.Alias +
          "' AND AD_Client_ID = " +
          this.dataStore.data.AD_Client_ID.id +
          ') cpt';

      const request: DataStoreRequest = {
        windowId: null,
        parent_constraint: '',
        compiereRequest: {
          startRow: 0,
          endRow: 0,
          tableName: duplicateAlias,
          filterModel: {},
        },
      };

      this.subscriptions.push(
          this.store.getDataGrid(request, true).subscribe((response) => {
            if (response && response.data[0]) {
              if (parseInt(response.data[0]['COUNT'], 10) > 0) {
                this.messageManager.newMessage(
                    new IupicsMessage(this.translateService.instant('specificWindow.accountEditor.duplicateAlias'), 'error')
                );
              } else {
                this.executeProcess(this.processId);
              }
            }
          })
      );
    } else {
      return true;
    }
  }
  notifyFromDataChange(item?: any) {
    if (item.data.isLaunchSearchGrid) {
      // the "Combination" field is not taken into account when modifying another field
      if (item.data.columnName === 'Combination') {
        this.removeExcludedFiltersToGrid('AccountEditorWindow_table');
      } else {
        this.addExcludedFiltersToGrid('AccountEditorWindow_table', 'Combination');
      }
      this.refreshGrids(this.dataStore, false, item.data);
    }
  }
}
