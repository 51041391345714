import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
  inject,
} from '@angular/core';
import {
  CompiereDataFieldType,
  CompiereDataJSON2,
  DataStore,
  DataStoreRequest,
} from '@compiere-ws/models/compiere-data-json';
import { LocationService } from '@compiere-ws/services/compiere-location/location.service';
import { IAutocomplete, isAutocompleteLike } from '@iupics-components/models/autocomplete-interfaces';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import AutocompleteUiComponent from '@iupics-components/standard/fields/autocomplete-ui/autocomplete-ui.component';
import ModalUiComponent from '@iupics-components/standard/layouts/modal-ui/modal-ui.component';
import { AppConfig } from '@iupics-config/app.config';
import { AbstractDataContainer } from '@iupics-manager/models/abstract-datacontainer';
import { Global } from '@iupics-manager/models/global-var';
import { DynamicContainerDirective } from '@iupics-util/directives/dynamic-container.directive';
import { LogicEvaluator } from '@iupics-util/tools/logic-evaluator';
import { TranslateModule } from '@ngx-translate/core';
import { cloneDeep, has, isNil } from 'lodash';
import { ButtonModule } from 'primeng/button';
import { Subscription, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import SpecificWindowUiComponent from '../specific-window-ui/specific-window-ui.component';
import { getCountryRequest } from './location-panel-utils';

/** @start_custo_code */
export enum Location {
  C_Location_ID = 'C_Location_ID',
  XX_TempLocation_ID = 'XX_TempLocation_ID',
  TempLocationReference = 95,
}
/** @end_custo_code */

@Component({
  selector: 'iu-location-panel',
  templateUrl: './location-panel.component.html',
  styleUrls: ['./location-panel.component.scss'],
  animations: Global.overlayAnimationAutocomplete,
  standalone: true,
  imports: [NgClass, ButtonModule, DynamicContainerDirective, NgTemplateOutlet, ModalUiComponent, TranslateModule],
})
export default class LocationPanelComponent
    extends SpecificWindowUiComponent
    implements OnInit, AfterViewInit, OnDestroy
{
  protected config = inject(AppConfig);

  get isNewLocationEnable(): boolean {
    return this.config.isModuleEnable('newLocation');
  }

  @ViewChild(DynamicContainerDirective, { read: ViewContainerRef })
  vcr: ViewContainerRef;
  @ViewChild('vcrAddress1', { read: ViewContainerRef })
  vcrAddress1: ViewContainerRef;
  @ViewChild('vcrAddress2', { read: ViewContainerRef })
  vcrAddress2: ViewContainerRef;
  @ViewChild('vcrAddress3', { read: ViewContainerRef })
  vcrAddress3: ViewContainerRef;
  @ViewChild('vcrAddress4', { read: ViewContainerRef })
  vcrAddress4: ViewContainerRef;
  @ViewChild('vcrRegionName', { read: ViewContainerRef })
  vcrRegionName: ViewContainerRef;
  @ViewChild('vcrC_Region_ID', { read: ViewContainerRef })
  vcrC_Region_ID: ViewContainerRef;
  @ViewChild('vcrC_Country_ID', { read: ViewContainerRef })
  vcrC_Country_ID: ViewContainerRef;
  @ViewChild('vcrC_City_ID', { read: ViewContainerRef })
  vcrC_City_ID: ViewContainerRef;
  @ViewChild('vcrPostal', { read: ViewContainerRef })
  vcrPostal: ViewContainerRef;
  @ViewChild('vcrPostal_Add', { read: ViewContainerRef })
  vcrPostal_Add: ViewContainerRef;
  @ViewChild('vcrCity', { read: ViewContainerRef }) vcrCity: ViewContainerRef;

  @Input() data: any;
  @Input() dataStored: DataStore;
  @Input() enableSize: number;

  locationPanelPosition = 'bottomPosition';

  @Output() locationEmitter = new EventEmitter();

  hasLocation = false;

  dataChanged$: Subscription;
  initStoreData = {};
  isAlreadyBuilt = false;

  #country: number | IAutocomplete;

  private locationService = inject(LocationService);

  /** @start_custo_code */
  fieldLocation: any;
  /** @end_custo_code */

  constructor() {
    super();
    this.isModal = false;
    if (!this.isNewLocationEnable) {
      // legacy behavior
      this.customDesignArray.push(
          {
            vcr: 'vcrAddress1',
            type: CustomDesignItemType.FIELD,
            columnName: 'Address1',
            cssClass: 'p-col-12 p-md-12 p-lg-12',
          },
          {
            vcr: 'vcrAddress2',
            type: CustomDesignItemType.FIELD,
            columnName: 'Address2',
            cssClass: 'p-col-12 p-md-12 p-lg-12',
          },
          {
            vcr: 'vcrAddress3',
            type: CustomDesignItemType.FIELD,
            columnName: 'Address3',
            cssClass: 'p-col-12 p-md-12 p-lg-12',
          },
          {
            vcr: 'vcrAddress4',
            type: CustomDesignItemType.FIELD,
            columnName: 'Address4',
            cssClass: 'p-col-12 p-md-12 p-lg-12',
          },
          {
            vcr: 'vcrRegionName',
            type: CustomDesignItemType.FIELD,
            columnName: 'RegionName',
            cssClass: 'p-col-12 p-md-12 p-lg-12',
          },
          {
            vcr: 'vcrC_Region_ID',
            type: CustomDesignItemType.FIELD,
            columnName: 'C_Region_ID',
            cssClass: 'p-col-12 p-md-12 p-lg-12 p-col-nopadding',
          },
          {
            vcr: 'vcrC_Country_ID',
            type: CustomDesignItemType.FIELD,
            columnName: 'C_Country_ID',
            cssClass: 'p-col-12 p-md-12 p-lg-12 p-col-nopadding',
          },
          {
            vcr: 'vcrCity',
            type: CustomDesignItemType.FIELD,
            columnName: 'City',
            cssClass: 'p-col-12 p-md-12 p-lg-12 ',
          },
          {
            vcr: 'vcrC_City_ID',
            type: CustomDesignItemType.FIELD,
            columnName: 'C_City_ID',
            cssClass: 'p-col-12 p-md-12 p-lg-12 p-col-nopadding',
          },
          {
            vcr: 'vcrPostal',
            type: CustomDesignItemType.FIELD,
            columnName: 'Postal',
            cssClass: 'p-col-12 p-md-12 p-lg-12',
          },
          {
            vcr: 'vcrPostal_Add',
            type: CustomDesignItemType.FIELD,
            columnName: 'Postal_Add',
            cssClass: 'p-col-12 p-md-12 p-lg-12',
          }
      );
    }
  }

  ngOnInit() {
    this.data = this.sourceComponent.data;
    this.dataStored = this.sourceComponent.dataStored;
    this.fieldValue = this.sourceComponent.fieldValue;
    if (
        this.sourceComponent.DOMParentComponent &&
        this.sourceComponent.DOMParentComponent.elementRef &&
        this.sourceComponent.elementRef.nativeElement
    ) {
      this.enableSize =
          this.sourceComponent.DOMParentComponent.elementRef.nativeElement.clientHeight -
          this.sourceComponent.elementRef.nativeElement.offsetTop;
    } else {
      this.enableSize = 1000;
    }
    if (this.enableSize - 300 < 0) {
      this.locationPanelPosition = 'topPosition';
    }
  }

  initData() {
    this.data = this.sourceComponent.data;
    this.dataStored = this.sourceComponent.dataStored;
    this.fieldValue = this.sourceComponent.fieldValue;
    if (
        this.sourceComponent.DOMParentComponent &&
        this.sourceComponent.DOMParentComponent.elementRef &&
        this.sourceComponent.elementRef.nativeElement
    ) {
      this.enableSize =
          this.sourceComponent.DOMParentComponent.elementRef.nativeElement.clientHeight -
          this.sourceComponent.elementRef.nativeElement.offsetTop;
    } else {
      this.enableSize = 1000;
    }
    if (this.enableSize - 300 < 0) {
      this.locationPanelPosition = 'topPosition';
    }
    const id = this.fieldValue ? (this.fieldValue.id ? this.fieldValue.id : this.fieldValue) : null;
    if (!this.dataStore || !id) {
      this.dataStore = this.store.newSpecificWindowData(this.formId);
      this.dataStore.data['AD_Org_ID'] = this.dataStored.data['AD_Org_ID']
          ? this.dataStored.data['AD_Org_ID']
          : this.dataStored.data['#AD_Org_ID'];
      this.dataStore.data['AD_Client_ID'] = this.dataStored.data['AD_Client_ID']
          ? this.dataStored.data['AD_Client_ID']
          : this.dataStored.data['#AD_Client_ID'];
      this.dataStore.data['C_Location_ID'] = this.dataStored.data['C_Location_ID'];
      this.dataStore.data['#C_Region_ID'] = 0;
      this.dataStore.data['#C_Country_ID'] = 0;
      this.dataStore.data['C_Region_ID'] = 0;
      this.dataStore.data['C_Country_ID'] = 0;
      this.dataChanged$ = this.dataStore.dataChange.subscribe((value) => {
        if (has(value.dataModified, 'C_Country_ID')) {
          this.checkCityVisibility();
        }
      });
    }
    if (id) {
      /** @start_custo_code */
      const obs =
          this.fieldLocation === Location.TempLocationReference
              ? this.locationService.getTempLocation(id)
              : this.locationService.getLocation(id);

      const sub = obs.subscribe({
        next: (response) => {
          if (response) {
            if (this.isNewLocationEnable) {
              this.dataStore.data = { ...this.dataStore.data, ...response };
              this.#country = this.dataStore.data['C_Country_ID'];
            } else {
              this.handleDataLocation(response);
            }
            this.initStoreData = cloneDeep(this.dataStore.data);
          }
          this.showSpecificWindow();
          sub.unsubscribe();
        },
        error: (error) => {
          this.showSpecificWindow();
        },
      });
      /** @end_custo_code */
    } else {
      this.data.nbrAddressSlot = 2;
      this.showSpecificWindow();
    }
  }

  /** @start_custo_code */
  private handleDataLocation(response: any) {
    if (response) {
      this.dataStore.data['Address1'] = response['Address1'];
      this.dataStore.data['Address2'] = response['Address2'];
      if (this.dataStore.data['Address1']) {
        this.data.nbrAddressSlot = 2;
      }
      this.dataStore.data['Address3'] = response['Address3'];
      if (this.dataStore.data['Address3']) {
        this.data.nbrAddressSlot = 3;
      }
      this.dataStore.data['Address4'] = response['Address4'];
      if (this.dataStore.data['Address4']) {
        this.data.nbrAddressSlot = 4;
      }
      this.dataStore.data['Postal'] = response['Postal'];
      if (response['C_Country_ID']) {
        this.dataStore.data['C_Country_ID'] = {
          id: response['C_Country_ID'],
          displayValue: '',
        };
      }
      if (response['C_Region_ID']) {
        this.dataStore.data['C_Region_ID'] = {
          id: response['C_Region_ID'],
          displayValue: '',
        };
      }
      if (response['C_City_ID']) {
        this.dataStore.data['C_City_ID'] = {
          id: response['C_City_ID'],
          displayValue: '',
        };
      } else if (response['City']) {
        this.dataStore.data['City'] = response['City'];
      }
    }
  }
  /** @end_custo_code */

  ngAfterViewInit() {
    // init using inputlocation data
    this.initData();
  }

  showSpecificWindow() {
    if (this.isNewLocationEnable) {
      if (!this.isAlreadyBuilt) {
        const C_Country_ID = this.getFormStoreData('C_Country_ID', -1);
        this.uiCreator
            .getLocationPanel(this.formId, isAutocompleteLike(C_Country_ID) ? C_Country_ID.id : C_Country_ID)
            .subscribe((specificWindow) => {
              this.buildWindow(specificWindow);
            });
        this.isAlreadyBuilt = true;
      } else {
        this.dataContainers.forEach((c) => {
          c.isSetDefaultValue = false;
          c.isSetMandatoryValue = false;
          c.setNewData(this.dataStore, true);
        });
      }
    } else {
      // legacy behavior
      if (this.isAlreadyBuilt) {
        this.dataContainers.forEach((c) => {
          c.isSetDefaultValue = false;
          c.isSetMandatoryValue = false;
          c.setNewData(this.dataStore, true);
        });
      } else {
        super.showSpecificWindow();
        this.isAlreadyBuilt = true;
      }
    }
  }

  save(event: Event) {
    event.stopPropagation();
    if (this.isNewLocationEnable) {
      this.saveNLLocation(event);
    } else {
      this.saveLegacyLocation(event);
    }
  }

  private saveLegacyLocation(event: Event) {
    const columnName = this.data && this.data.columnName ? this.data.columnName : 'C_Location_ID';
    const fieldValueId = this.fieldValue && this.fieldValue.id ? this.fieldValue.id : this.fieldValue;
    const data = {
      Data_UUID:
          this.dataStore && this.dataStore.data && this.dataStore.data[columnName] && this.dataStore.data[columnName].id
              ? 'C_Location_ID,' + this.dataStore.data[columnName].id
              : 'C_Location_ID,' + fieldValueId,
      C_Location_ID:
          this.dataStore && this.dataStore.data && this.dataStore.data[columnName] && this.dataStore.data[columnName].id
              ? this.dataStore.data[columnName].id
              : fieldValueId,
      AD_Client_ID:
          this.dataStored && this.dataStored.data && this.dataStored.data['AD_Client_ID']
              ? this.dataStored.data['AD_Client_ID']
              : this.connectorService.getIupicsUserContext()['#AD_Client_ID'],
      AD_Org_ID:
          this.dataStored && this.dataStored.data && this.dataStored.data['AD_Org_ID']
              ? this.dataStored.data['AD_Org_ID']
              : this.connectorService.getIupicsUserContext()['#AD_Org_ID'],
      IsActive:
          this.dataStored && this.dataStored.data && this.dataStored.data['IsActive']
              ? this.dataStored.data['IsActive']
              : 'Y',
      Address1: this.dataStore.data['Address1'] ? this.dataStore.data['Address1'] : null,
      Address2: this.dataStore.data['Address2'] ? this.dataStore.data['Address2'] : null,
      Address3: this.dataStore.data['Address3'] ? this.dataStore.data['Address3'] : null,
      Address4: this.dataStore.data['Address4'] ? this.dataStore.data['Address4'] : null,
      City: this.dataStore.data['C_City_ID']
          ? this.dataStore.data['C_City_ID'].id !== -1
              ? this.dataStore.data['C_City_ID'].displayValue
              : null
          : this.dataStore.data['City']
              ? this.dataStore.data['City']
              : null,
      C_City_ID: this.dataStore.data['C_City_ID']
          ? this.dataStore.data['C_City_ID'].id !== -1
              ? this.dataStore.data['C_City_ID'].id
              : null
          : null,
      Postal: this.dataStore.data['Postal'] ? this.dataStore.data['Postal'] : null,
      Postal_Add: null,
      C_Region_ID: this.dataStore.data['C_Region_ID']
          ? this.dataStore.data['C_Region_ID'].id !== -1
              ? this.dataStore.data['C_Region_ID'].id
              : null
          : null,
      RegionName: this.dataStore.data['C_Region_ID']
          ? this.dataStore.data['C_Region_ID'].id !== -1
              ? this.dataStore.data['C_Region_ID'].displayValue
              : null
          : null,
      C_Country_ID: this.dataStore.data['C_Country_ID']
          ? this.dataStore.data['C_Country_ID'].id
              ? this.dataStore.data['C_Country_ID'].id
              : null
          : null,
    };
    Object.keys(data).forEach((key) => {
      if (data[key] instanceof Object) {
        data[key] = data[key].id;
      }
    });
    const dataWs: CompiereDataJSON2 = {
      data: [data],
      data_UUID: ['C_Location_ID'],
      displayData: {},
      secondaryColumnFields: [],
      lastRow: 0,
      tab_id: 154,
    };

    this.store.saveLocation(dataWs).subscribe((compiereData) => {
      if (compiereData?.data[0]?.apiz_dataResult?.responseError) {
        throw new Error(compiereData.data[0].apiz_dataResult.responseError[0].message);
      } else {
        this.initStoreData = { ...this.initStoreData, ...compiereData.data[0] };
        const result = this.generateAdresseFromCompiereData(compiereData);
        this.sourceComponent.onLocationEmitter({
          action: 'save',
          event: event,
          result: result,
        });
        this.fieldValue = result;
      }
    });
  }

  private saveNLLocation(event: Event) {
    const columnNames = Object.keys(this.dataStore.data);
    let data = {};
    for (let i = 0; i < columnNames.length; i++) {
      const columnName = columnNames[i];
      const datastoreValue = this.dataStore.data[columnName];
      data[columnName] = datastoreValue;
      if (has(datastoreValue, 'id')) data[columnName] = datastoreValue.id;
    }

    const columnName = this.data && this.data.columnName ? this.data.columnName : 'C_Location_ID';
    const fieldValueId = this.fieldValue && this.fieldValue.id ? this.fieldValue.id : this.fieldValue;
    data = {
      ...data,
      IsActive: 'Y',
      Data_UUID:
          this.dataStore && this.dataStore.data && this.dataStore.data[columnName] && this.dataStore.data[columnName].id
              ? 'C_Location_ID,' + this.dataStore.data[columnName].id
              : 'C_Location_ID,' + fieldValueId,
      C_Location_ID:
          this.dataStore && this.dataStore.data && this.dataStore.data[columnName] && this.dataStore.data[columnName].id
              ? this.dataStore.data[columnName].id
              : fieldValueId,
      C_Region_ID: this.dataStore.data['C_Region_ID']
          ? this.dataStore.data['C_Region_ID'].id !== -1
              ? this.dataStore.data['C_Region_ID'].id
              : null
          : null,
    };

    const dataWs: CompiereDataJSON2 = {
      data: [data],
      data_UUID: ['C_Location_ID'],
      displayData: {},
      secondaryColumnFields: [],
      lastRow: 0,
      tab_id: 154,
    };

    this.store.saveLocation(dataWs).subscribe((compiereData) => {
      if (compiereData?.data[0]?.apiz_dataResult?.responseError) {
        throw new Error(compiereData.data[0].apiz_dataResult.responseError[0].message);
      } else {
        this.initStoreData = { ...this.initStoreData, ...compiereData.data[0] };
        const result = this.generateAdresseFromCompiereData(compiereData);
        this.sourceComponent.onLocationEmitter({
          action: 'save',
          event: event,
          result: result,
        });
        this.fieldValue = result;
      }
    });
  }

  cancel(event: Event) {
    event.stopPropagation();
    this.dataStore.data = cloneDeep(this.initStoreData);
    this.setDataContainersFieldValue();
    this.sourceComponent.onLocationEmitter({ action: 'cancel', event: event });
  }

  /** @start_custo_code */
  private generateAdresseFromCompiereData(compiereData: CompiereDataJSON2) {
    let addressData = [
      compiereData.data[0]['Address1'],
      compiereData.data[0]['Address2'],
      compiereData.data[0]['Address3'],
      compiereData.data[0]['Address4'],
      compiereData.data[0]['City'],
      compiereData.data[0]['Postal'],
    ];
    let joinedChar = ' - ';
    if (this.data && this.data.details.sqlDisplayData) {
      // logique suivi :MLocation.sqlString
      const regexMatchConc = this.data.details.sqlDisplayData.match(/(\|\|')(.*?)('\|\|)/im);
      joinedChar = regexMatchConc[0] ? regexMatchConc[0].replace(/\|\|'|'\|\|/g, '') : ' - ';
      const regexMatch = this.data.details.sqlDisplayData.match(/(SELECT)(.*)(FROM)/);
      if (regexMatch.length > 0) {
        const regexMatch2 = regexMatch[0]
            ? regexMatch[0]
                .replace(/SELECT|\s/g, '')
                .match(/(C_Location\.|C_Country\.|XX_TempLocation\.)(.*?)(\|\||FROM)/gi)
            : '';
        if (regexMatch2.length > 0) {
          addressData = [];
          regexMatch2.forEach((match) => {
            const trimmedMatch = match.replace(/\|\||FROM/gi, '').trim();

            const splitteMatch = trimmedMatch.split('.');
            if (splitteMatch.length === 2) {
              const tableName = splitteMatch[0];
              const columnName = splitteMatch[1];
              const matchFound = this.findMatch(columnName, tableName);
              if (compiereData.data[0][matchFound] !== undefined) {
                let value = compiereData.data[0][matchFound];
                if (matchFound.includes('_ID')) {
                  value = value.displayValue;
                }
                addressData.push(value);
              }
            }
          });
        }
      }
    }
    const addressStr = addressData
        .map((item) => {
          if (item === undefined || item === null) {
            return '';
          } else {
            return item;
          }
        })
        .join(joinedChar);
    return {
      id: compiereData.data[0][
          this.fieldLocation === Location.TempLocationReference ? Location.XX_TempLocation_ID : Location.C_Location_ID
          ],
      displayValue: addressStr,
    };
  }

  private findMatch(columnName: string, tableName: string) {
    let matchFound: string;
    if (columnName) {
      let propertiesToMatch = [];
      if (this.isNewLocationEnable) {
        propertiesToMatch = [
          'NL_ExtraInfo',
          'NL_Street',
          'NL_Number',
          'NL_ExtraStreet',
          'NL_BoxNumber',
          'NL_SpecialMention',
          'City',
          'Postal',
          'RegionName',
          'Postal_Add',
        ];
      } else {
        propertiesToMatch = [
          'Address1',
          'Address2',
          'Address3',
          'Address4',
          'City',
          'Postal',
          'RegionName',
          'Postal_Add',
        ];
      }
      if (tableName.toLowerCase() === 'c_country') {
        propertiesToMatch = ['Name'];
      }
      for (let i = 0; i < propertiesToMatch.length; i++) {
        if (columnName.toLowerCase().indexOf(propertiesToMatch[i].toLocaleLowerCase()) > -1) {
          matchFound = propertiesToMatch[i];
          break;
        }
      }
    }
    if (matchFound === 'Name') {
      matchFound = 'C_Country_ID';
    }
    return matchFound;
  }

  /** @end_custo_code */

  clearLocation(e, isClearEvent = true) {
    const baseColumnNames = [
      'Address1',
      'Address2',
      'Address3',
      'Address4',
      'C_Country_ID',
      'C_City_ID',
      'C_Region_ID',
      'City',
      'Postal',
    ];
    for (let i = 0; i < baseColumnNames.length; i++) {
      const columnName = baseColumnNames[i];
      if (this.dataStore.data.hasOwnProperty(columnName)) this.dataStore.data[columnName] = null;
    }

    if (this.isNewLocationEnable) {
      const nlColumnNames = [
        'NL_BoxNumber',
        'NL_ExtraInfo',
        'NL_ExtraStreet',
        'NL_IsNLFormat',
        'NL_Number',
        'NL_SpecialMention',
        'NL_Street',
      ];
      for (let i = 0; i < nlColumnNames.length; i++) {
        const nlColumnName = nlColumnNames[i];
        if (this.dataStore.data.hasOwnProperty(nlColumnName)) this.dataStore.data[nlColumnName] = null;
      }
    }
    this.fieldValue = null;
    this.setDataContainersFieldValue();
    e.stopPropagation();
    if (isClearEvent) this.sourceComponent.onLocationEmitter({ action: 'clear', event: e });
  }

  private checkCityVisibility() {
    const dataContainer: AbstractDataContainer = this.getDatacontainer('C_City_ID');
    const id =
        dataContainer.fieldValue && dataContainer.fieldValue.id ? dataContainer.fieldValue.id : dataContainer.fieldValue;
    let obs = of(null);
    if (id) {
      obs = (<AutocompleteUiComponent>dataContainer).getSearchItem(id);
    }
    this.subscriptions.push(
        obs
            .pipe(
                switchMap((dataWs) => {
                  // si on trouve on n'update rien
                  this.hasLocation = dataWs ? true : false;
                  // si on ne trouve rien on recherche les possibilités
                  if (!this.hasLocation) {
                    let validationCode: any;
                    if (dataContainer.data.validationCode) {
                      validationCode = LogicEvaluator.replaceVariables(
                          dataContainer.data.validationCode,
                          this.connectorService.getIupicsUserContext(),
                          dataContainer.getCurrentContext()
                      );
                    }
                    const entityId =
                        dataContainer.fieldType === CompiereDataFieldType.FIELD
                            ? dataContainer.data.fieldId
                            : dataContainer.data.columnId;

                    return this.store
                        .getAutocompleteData(
                            dataContainer.fieldType,
                            entityId,
                            dataContainer.data.isSearch,
                            null,
                            validationCode
                        )
                        .pipe(
                            switchMap((responses) => {
                              this.hasLocation = responses && responses.length > 0;
                              if (
                                  this.hasLocation &&
                                  !isNil(this.dataStore.data['Postal']) &&
                                  this.dataStore.data['Postal'].trim() !== ''
                              ) {
                                dataContainer.dataChange(responses[0]);
                              }
                              return of(this.hasLocation);
                            })
                        );
                  } else {
                    return of(this.hasLocation);
                  }
                })
            )
            .subscribe((hasLocation) => {
              if (!hasLocation) {
                const dataContainer2: AbstractDataContainer = this.getDatacontainer('City');
                dataContainer.dataChange(null);
                if (id && dataContainer2) {
                  dataContainer2.dataChange('');
                }
              }
            })
    );
  }

  private setCityAndPostal() {
    const dataContainer: AbstractDataContainer = this.getDatacontainer('Postal');
    const dataContainer2: AbstractDataContainer = this.getDatacontainer('City');
    if (!this.dataStore.data['Postal'] || this.dataStore.data['Postal'] === '') {
      const cityID = this.dataStore.data['C_City_ID']
          ? this.dataStore.data['C_City_ID'].id !== -1
              ? this.dataStore.data['C_City_ID'].id
              : null
          : null;
      if (cityID !== null) {
        const sub = this.locationService.getCity(cityID).subscribe((cities) => {
          if (cities && cities.length > 0) {
            dataContainer.dataChange(cities[0].postal);
            dataContainer2.dataChange(cities[0].name);
          } else {
            dataContainer.dataChange(null);
            dataContainer2.dataChange(
                this.dataStore.data['C_City_ID']
                    ? this.dataStore.data['C_City_ID'].id !== -1
                        ? this.dataStore.data['C_City_ID'].displayValue
                        : null
                    : null
            );
          }
          sub.unsubscribe();
        });
      }
    } else if (this.dataStore.data['C_City_ID'] && this.dataStore.data['C_City_ID'].id) {
      dataContainer2.dataChange(
          this.dataStore.data['C_City_ID']
              ? this.dataStore.data['C_City_ID'].id !== -1
                  ? this.dataStore.data['C_City_ID'].displayValue
                  : null
              : null
      );
    }
  }

  openMap() {
    let searchStr = '';
    if (this.dataStore.data['Address1']) {
      searchStr += encodeURI(this.dataStore.data['Address1'] + ' ');
    }
    if (this.dataStore.data['Address2']) {
      searchStr += encodeURI(this.dataStore.data['Address2'] + ' ');
    }
    if (this.dataStore.data['Postal']) {
      searchStr += encodeURI(this.dataStore.data['Postal'] + ' ');
    }
    if (this.dataStore.data['City']) {
      searchStr += encodeURI(this.dataStore.data['City'] + ' ');
    } else if (this.dataStore.data['C_City_ID'] && this.dataStore.data['C_City_ID'].displayValue) {
      searchStr += encodeURI(this.dataStore.data['C_City_ID'].displayValue + ' ');
    }
    if (this.dataStore.data['C_Country_ID'] && this.dataStore.data['C_Country_ID'].displayValue) {
      searchStr += encodeURI(this.dataStore.data['C_Country_ID'].displayValue + ' ');
    }
    if (searchStr) {
      window.open('https://www.google.com/maps?q=' + searchStr, '_blank');
    }
  }

  notifyFromDatacontainerInit(datacontainer: AbstractDataContainer) {
    if (
        datacontainer.data.columnName === 'C_Country_ID' ||
        datacontainer.data.columnName === 'C_City_ID' ||
        datacontainer.data.columnName === 'C_Region_ID'
    ) {
      if (this.dataStore.data[datacontainer.data.columnName]) {
        const id = this.dataStore.data[datacontainer.data.columnName].id
            ? this.dataStore.data[datacontainer.data.columnName].id
            : this.dataStore.data[datacontainer.data.columnName];
        this.subscriptions.push(
            (<AutocompleteUiComponent>datacontainer).getSearchItem(id).subscribe((dataWs) => {
              datacontainer.dataChange(dataWs);
              if (this.isNewLocationEnable && datacontainer.data.columnName === 'C_Country_ID') {
                this.#country = dataWs;
              }
            })
        );
      }
    } else {
      datacontainer.updateStore(this.dataStore.data[datacontainer.data.columnName]);
    }
  }

  getId(value: any): any {
    if (value?.hasOwnProperty('id')) return value.id;
    else return value;
  }

  notifyFromDataChange(item: any) {
    if (item.data.columnName === 'C_Country_ID' && this.getFormStoreData('C_Country_ID')) {
      if (this.isNewLocationEnable) {
        const newC_Country_ID = this.getId(this.getFormStoreData('C_Country_ID'));
        const oldC_Country_ID = this.getId(this.#country);
        if (newC_Country_ID !== oldC_Country_ID) {
          this.uiCreator.getLocationPanel(this.formId, newC_Country_ID).subscribe((specificWindow) => {
            this.#country = this.getFormStoreData('C_Country_ID');
            this.rebuildWindow(specificWindow);
          });
        }
      }
      const ad_language = this.connectorService.getIupicsDefaultLanguage().iso_code;
      const request: DataStoreRequest = getCountryRequest(this.getFormStoreData('C_Country_ID').id, ad_language);
      const sub = this.store.getDataGrid(request).subscribe((response) => {
        this.store.syncDataChanges(this.dataStore, {
          HasRegion: response.data[0]['HasRegion'],
        });
      });
      this.subscriptions.push(sub);
      return;
    }

    if (item.data.columnName === 'C_Region_ID' || item.data.columnName === 'Postal') {
      this.checkCityVisibility();
    }
    if (item.data.columnName === 'C_City_ID') {
      this.setCityAndPostal();
    }
  }
  setDataContainersValueWithChangedStore(dataStore?: DataStore) {}
  setDataContainersFieldValue() {
    this.dataContainers.forEach((datacontainer) => {
      if (this.dataStore.data && datacontainer.data && datacontainer.data.columnName) {
        datacontainer.changeFieldValue(this.dataStore, false, [], false, null);
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.dataChanged$.unsubscribe();
  }
}
