import { NgClass } from '@angular/common';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import {
  CompiereDataGridFilterType,
  CompiereDataGridResponseJSON,
  DataStore,
  DataStoreRequest,
} from '@compiere-ws/models/compiere-data-json';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import GridViewUiComponent from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import ModalUiComponent from '@iupics-components/standard/layouts/modal-ui/modal-ui.component';
import { DynamicContainerDirective } from '@iupics-util/directives/dynamic-container.directive';
import { ApizGridUtils } from '@iupics-util/tools/apiz-grid.utils';
import { TranslateModule } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import * as moment from 'moment';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SidebarModule } from 'primeng/sidebar';
import { zip } from 'rxjs';
import AutocompleteEditorComponent from '../editor/autocomplete-editor.component';
import ButtonEditorComponent from '../editor/button-editor.component';
import SpecificWindowUiComponent from '../specific-window-ui/specific-window-ui.component';

@Component({
  selector: 'iu-bpartner-balance',
  templateUrl: './bpartner-balance.component.html',
  styleUrls: ['./bpartner-balance.component.scss'],
  standalone: true,
  imports: [
    ProgressBarModule,
    ScrollPanelModule,
    DynamicContainerDirective,
    SidebarModule,
    ModalUiComponent,
    // UniversalFilterUiComponent,
    // GridTabInfinityScrollUiComponent,
    NgClass,
    TranslateModule,
  ],
})
export default class BpartnerBalanceComponent extends SpecificWindowUiComponent implements OnInit, AfterViewInit {
  frameworkComponents: any;
  dataStore: DataStore;

  constructor() {
    super();
    this.frameworkComponents = {
      buttonEditor: ButtonEditorComponent,
      autocompleteEditor: AutocompleteEditorComponent,
    };
  }

  ngOnInit(): void {
    super.ngOnInit();
    const ad_org = this.getFormStoreData('AD_Org_ID');
    if (ad_org) {
      if (ad_org.id === 0) {
        this.setExcludedFiltersToGrid('BP accounting_table', ['AD_Org_ID']);
      } else {
        this.removeExcludedFiltersToGrid('BP accounting_table');
      }
    }
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    // Global.workspace = this;
  }

  notifyFromGridRefresh(gridView: GridViewUiComponent, dataStoreRequest: DataStoreRequest) {
    const obsZip = [];
    // balance
    dataStoreRequest.compiereRequest.sortModel = undefined;
    dataStoreRequest.compiereRequest.headerCols = undefined;
    dataStoreRequest.compiereRequest.valueCols = [
      {
        id: 'DR',
		    colId: 'DR',
        aggFunc: 'sum',
        field: 'DR',
        displayName: 'DR',
      },
      {
        id: 'CR',
		    colId: 'CR',
        aggFunc: 'sum',
        field: 'CR',
        displayName: 'CR',
      },
    ];

    dataStoreRequest.compiereRequest = ApizGridUtils.cleanDataRequestForCompiereRequest(
      dataStoreRequest.compiereRequest
    );
    obsZip.push(this.store.getDataGrid(dataStoreRequest));

    // Balance (devise base)
    const dataStoreRequest2 = cloneDeep(dataStoreRequest);
    dataStoreRequest2.compiereRequest.sortModel = undefined;
    dataStoreRequest2.compiereRequest.filterModel['DueDate'] = {
      filterType: CompiereDataGridFilterType.DATE,
      values: [
        moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSS').substring(0, 26) +
          '' +
          moment(new Date())
            .format('YYYY-MM-DDTHH:mm:ss.SSS')
            .substring(27, moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSS').length),
      ],
      operators: [OperatorFilterType.LESSTHANOREQUAL],
    };
    // montantOuvertTotal
    dataStoreRequest2.compiereRequest.valueCols = [
      {
        id: 'OPENAMT_BASE',
		    colId: 'OPENAMT_BASE',
        aggFunc: 'sum',
        field: 'OPENAMT_BASE',
        displayName: 'OPENAMT_BASE',
      },
      //@start_custo_code
      {
        id: 'OPENAMT_ORIGIN',
		    colId: 'OPENAMT_ORIGIN',
        aggFunc: 'sum',
        displayName: 'OPENAMT_ORIGIN',
        field: 'OPENAMT_ORIGIN',
      },
      //@end_custo_code
    ];
    //@start_custo_code
    dataStoreRequest2.compiereRequest['sortModel'] = [];
    //@end_custo_code

    dataStoreRequest2.compiereRequest = ApizGridUtils.cleanDataRequestForCompiereRequest(
      dataStoreRequest2.compiereRequest
    );
    obsZip.push(this.store.getDataGrid(dataStoreRequest2));

    // total non echu && mintant ouvert total
    const dataStoreRequest3 = cloneDeep(dataStoreRequest);
    dataStoreRequest3.compiereRequest.sortModel = undefined;
    dataStoreRequest3.compiereRequest.valueCols = [
      {
        id: 'BASE_DR',
        colId: 'BASE_DR',
        aggFunc: 'sum',
        field: 'BASE_DR',
        displayName: 'BASE_DR',
      },
      {
        id: 'BASE_CR',
        colId: 'BASE_CR',
        aggFunc: 'sum',
        field: 'BASE_CR',
        displayName: 'BASE_CR',
      },
    ];
    //@start_custo_code
    dataStoreRequest3.compiereRequest['sortModel'] = [];
    //@end_custo_code

    dataStoreRequest3.compiereRequest = ApizGridUtils.cleanDataRequestForCompiereRequest(
      dataStoreRequest3.compiereRequest
    );
    obsZip.push(this.store.getDataGrid(dataStoreRequest3));

    //@start_custo_code
    //Total Echu
    const dataStoreRequest4 = cloneDeep(dataStoreRequest);
    dataStoreRequest4.compiereRequest.filterModel['DueDate'] = {
      filterType: CompiereDataGridFilterType.DATE,
      values: [
        moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSS').substring(0, 26) +
          '' +
          moment(new Date())
            .format('YYYY-MM-DDTHH:mm:ss.SSS')
            .substring(27, moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSS').length),
      ],
      operators: [OperatorFilterType.LESSTHANOREQUAL],
    };

    // total non echu
    dataStoreRequest4.compiereRequest.valueCols = [
      {
        id: 'OPENAMT_BASE',
        colId: 'OPENAMT_BASE',
        aggFunc: 'sum',
        field: 'OPENAMT_BASE',
        displayName: 'OPENAMT_BASE',
      },
    ];
    //@start_custo_code
    dataStoreRequest4.compiereRequest['sortModel'] = [];
    //@end_custo_code

    dataStoreRequest4.compiereRequest = ApizGridUtils.cleanDataRequestForCompiereRequest(
      dataStoreRequest4.compiereRequest
    );
    obsZip.push(this.store.getDataGrid(dataStoreRequest4));
    this.subscriptions.push(
      zip(...obsZip).subscribe(([/*response1, */ response2, response3, response4]: CompiereDataGridResponseJSON[]) => {
        // Balance (devise base)
        //@start_custo_code
        /*if (
          response1.data[0]['DR'] !== undefined &&
          response1.data[0]['DR'] !== null &&
          response1.data[0]['CR'] !== undefined &&
          response1.data[0]['CR'] !== null
        ) {
          const dr = response1.data[0]['DR'];
          const cr = response1.data[0]['CR'];
          const balance = dr - cr;
          this.setFormStoreData('Balance', balance.toFixed(2));
        }*/
        //@end_custo_code
        //montantOuvertTotal
        if (response2.data[0]['OPENAMT_BASE'] !== undefined && response2.data[0]['OPENAMT_BASE'] !== null) {
          const basebalance = response2.data[0]['OPENAMT_BASE'];
          /**
           * @start_custo_code
           */
          //this.setFormStoreData('DueTotal', Number(dueTotal.toFixed(2)));
          this.setFormStoreData('OPENAMT_BASE', Number(basebalance.toFixed(2)));
        }
        // this.setFormStoreData('NotDueTotal', this.getFormStoreData('Balance', 0) - this.getFormStoreData('DueTotal', 0));
        if (response2.data[0]['OPENAMT_ORIGIN'] !== undefined && response2.data[0]['OPENAMT_ORIGIN'] !== null) {
          const balance = response2.data[0]['OPENAMT_ORIGIN'];
          this.setFormStoreData('Balance', Number(balance.toFixed(2)));
        }

        /**
         * @end_custo_code
         */
        //total base Débit
        if (
          response3.data[0]['BASE_DR'] !== undefined &&
          response3.data[0]['BASE_DR'] !== null
          /**
           * @start_custo_code
           */
          /* &&response3.data[0]['BASE_CR'] !== undefined &&
          response3.data[0]['BASE_CR'] !== null*/
        ) {
          //const BALANCEBASE = response3.data[0]['BASE_DR'] - response3.data[0]['BASE_CR'];
          const BASE_DR = response3.data[0]['BASE_DR'];
          this.setFormStoreData('BASE_DR', Number(BASE_DR.toFixed(2)));
        }
        //total base crédit
        if (response3.data[0]['BASE_CR'] !== undefined && response3.data[0]['BASE_CR'] !== null) {
          const BASE_CR = response3.data[0]['BASE_CR'];
          this.setFormStoreData('BASE_CR', Number(BASE_CR.toFixed(2)));
        }
        //total Echu et Non Echu
        if (response4.data[0]['OPENAMT_ORIGIN'] !== undefined && response4.data[0]['OPENAMT_ORIGIN'] !== null) {
          const dueTotal = response4.data[0]['OPENAMT_ORIGIN'];
          this.setFormStoreData('DueTotal', Number(dueTotal.toFixed(2)));
        }
        const notDueTotal = this.getFormStoreData('Balance', 0) - this.getFormStoreData('DueTotal', 0);
        this.setFormStoreData('NotDueTotal', Number(notDueTotal.toFixed(2)));
        /**
         * @end_custo_code
         */
        this.setDataContainersValueWithChangedStore();
      })
    );
    super.notifyFromGridRefresh(gridView, dataStoreRequest);
  }

  notifyFromDataChange(item: any, vcrIndex?: number) {
    if (item.data['columnName'] === 'OnlyOpen' && this.dataStore) {
      if (!this.dataStore.data['OnlyOpen'] && this.dataStore.data['OnlyOpen'] === 'N') {
        this.setExcludedFiltersToGrid('BP accounting_table', ['OnlyOpen']);
      } else {
        this.removeExcludedFiltersToGrid('BP accounting_table');
      }
    }
    if (
      (item.data['columnName'] === 'C_BPartner_ID' && this.dataStore && this.dataStore.data['C_BPartner_ID']) ||
      (item.data['columnName'] === 'Type_piece' && this.dataStore && this.dataStore.data['Type_piece'])
    ) {
      this.setBPartnerInfos();
    }
    if (this.dataStore && this.dataStore.data['AD_Org_ID']) {
      const ad_orgIdValue = this.getFormStoreData('AD_Org_ID');
      if (ad_orgIdValue && ad_orgIdValue.id === 0) {
        this.setExcludedFiltersToGrid('BP accounting_table', ['AD_Org_ID']);
      } else {
        this.removeExcludedFiltersToGrid('BP accounting_table');
      }
    }
    super.notifyFromDataChange(item);
  }

  private setBPartnerInfos() {
    if (this.getFormStoreData('C_BPartner_ID')) {
      const v_C_BPartner_ID = this.getFormStoreData('C_BPartner_ID')['id'];
      let v_Type_piece = undefined;
      if (this.getFormStoreData('Type_piece')) {
        v_Type_piece = this.getFormStoreData('Type_piece')['id'];
      }
      const request: DataStoreRequest = {
        windowId: this.dataStore.key.windowId,
        record_id: this.dataStore.key.recordId.toString(),
        parent_constraint: undefined,
        compiereRequest: {
          tableName: 'C_BPartner',
          startRow: 0,
          endRow: 1,
          filterModel: {
            C_BPartner_ID: {
              filterType: CompiereDataGridFilterType.SET,
              values: [v_C_BPartner_ID],
              operators: [OperatorFilterType.EQUALS],
            },
          },
        },
      };
      this.store
        .getDataGrid(request)
        .subscribe((response) => this.setFormStoreData('BASE_CR', response.data[0]['BASE_CR']));

      //account
      let tableName =
        "( SELECT cva.Account_ID , bp.C_Bpartner_ID, bp.isVendor, bp.isCustomer FROM C_BPartner bp    left  join c_bp_customer_acct bpc on (bp.C_BPartner_iD = bpc.C_BPartner_iD  AND bp.IsCustomer= 'Y' " +
        (this.getFormStoreData('Type_piece') ? " AND '" + v_Type_piece + "' in ('AR', 'Al')" : '') +
        ' ) ' +
        " left join c_bp_vendor_acct bpv on (bp.C_BPartner_iD = bpv.C_BPartner_iD  AND bp.IsVendor = 'Y' " +
        (this.getFormStoreData('Type_piece') ? " AND '" + v_Type_piece + "' in ('AP', 'Al')" : '') +
        ' ) ' +
        ' INNER JOIN c_validcombination cva ON ( cva.c_validcombination_id = COALESCE(bpc.c_receivable_acct, bpv.v_liability_acct))' +
        ' where bp.c_bpartner_id = ' +
        v_C_BPartner_ID +
        ' ' +
        ') cpt';

      request.compiereRequest.tableName = tableName;

      this.store.getDataGrid(request).subscribe((response) => {
        if (response && response.data && response.data[0]) {
          if (
            response.data[0]['ISVENDOR'] === 'Y' &&
            response.data[0]['ISCUSTOMER'] === 'Y' &&
            (v_Type_piece === 'Al' || !v_Type_piece)
          ) {
            this.dataStore.data['Account_ID'] = null;
          } else {
            this.dataStore.data['Account_ID'] = response.data[0]['ACCOUNT_ID'];
          }
        } else {
          this.dataStore.data['Account_ID'] = null;
        }
      });
    }
  }
}
