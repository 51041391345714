import { Injectable } from '@angular/core';
import { CompiereDataGridFilterType, DataStoreRequest } from '@compiere-ws/models/compiere-data-json';
import { ProcessPingInfo, ProcessPingInfoPara } from '@compiere-ws/models/process-ping-info';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import * as AGChannel from 'ag-channel';
import { Observable, Subject } from 'rxjs';
import { SocketService } from '../socket/socket.service';

@Injectable({
  providedIn: 'root',
})
export class ProcessInProgressService {
  private openedWindow: string[] = [];
  private timeouts: NodeJS.Timeout[] = [];
  private progressSocket: AGChannel<any>;
  private pings: ProcessPingInfo[] = [];
  private pings$: Subject<ProcessPingInfo[]> = new Subject();

  constructor(
    private socketService: SocketService,
    private store: DataStoreService,
    private smService: SecurityManagerService
  ) {}

  public init() {
    this.socketService.initSocket();
    this.progressSocket = this.socketService.enableProcessInProgressChannel();
    const _self = this;
    if (!this.progressSocket.isSubscribed()) {
      (async () => {
        for await (const dataStr of this.progressSocket) {
          try {
            const ping = new ProcessPingInfo(dataStr);
            const pingIDX = _self.pings.findIndex((p) => p.Channel === ping.Channel);
            if (pingIDX === -1) {
              _self.pings.push(ping);
            } else {
              if (_self.pings[pingIDX].Status !== ping.Status) {
                _self.pings[pingIDX].Status = ping.Status;
              }
              if (
                _self.pings[pingIDX].AD_Org_ID !== undefined &&
                _self.pings[pingIDX].AD_Org_ID !== null &&
                _self.pings[pingIDX].AD_User_ID !== undefined &&
                _self.pings[pingIDX].AD_User_ID !== null &&
                  ping.Status !== 'finish'&&ping.Status !== 'cancel'
              ) {
                _self.pings$.next(_self.pings);
              }

              if (ping.Status === 'finish'||ping.Status === 'cancel') {
                _self.pings$.next(_self.pings);
                if (_self.openedWindow.length === 0) {
                  _self.removeFinishedProcess(_self.pings[pingIDX]);
                }
              }
            }
          } catch (e) {
            console.error(e);
          }
        }
      })();
    }
  }

  public updateSocket() {
    this.socketService.disableProcessInProgressChannel();
    this.init();
  }

  public getProcessInfo(ping: ProcessPingInfo) {
    const language = this.smService.getIupicsUserContext()['#AD_Language'];
    const pinstance = ping.AD_PInstance_ID;
    const request_instance_v: DataStoreRequest = {
      windowId: null,
      parent_constraint: null,
      compiereRequest: {
        tableName: 'INSTANCE_PARA_PING_V',
        startRow: 0,
        filterModel: {
          AD_PInstance_ID: {
            filterType: CompiereDataGridFilterType.NUMBER,
            operators: [OperatorFilterType.EQUALS],
            values: [pinstance.id],
          },
          AD_Language: {
            filterType: CompiereDataGridFilterType.TEXT,
            operators: [OperatorFilterType.EQUALS],
            values: [language],
          },
        },
      },
    };

    const sub = this.store.getDataGrid(request_instance_v, true).subscribe(({ data }) => {
      const pIDX = this.pings.findIndex(
        (p) => p.AD_PInstance_ID.id === pinstance.id && p.AD_Process_ID.id === ping.AD_Process_ID.id
      );
      this.pings[pIDX].setFromProcessPingInfoPara(data as ProcessPingInfoPara[]);
      sub.unsubscribe();
    });
  }

  /*
   * Retourne un Observable avec la référence du tableau de pings.
   * La référence peut être modifiée car le subscribe qui écoute sera trigger à chaque changement.
   */
  public watchProcessInProgress(): Observable<ProcessPingInfo[]> {
    this.init();
    return this.pings$.asObservable();
  }

  private removeFinishedProcess(ping: ProcessPingInfo) {
    const timeout = setTimeout(() => {
      const idx = this.pings.indexOf(ping);
      this.pings.splice(idx, 1);
      this.pings$.next(this.pings);
      this.timeouts = this.timeouts.filter((t) => t !== timeout);
    }, 5000);
    this.timeouts.push(timeout);
  }

  public openWindow(activeTabID: any): void {
    this.openedWindow.push(activeTabID);
    this.timeouts.forEach((timeout) => {
      clearTimeout(timeout);
    });
  }

  public closeWindow(activeTabID: any): void {
    this.openedWindow = this.openedWindow.filter((id) => id !== activeTabID);
    if (this.openedWindow.length === 0) {
      this.pings.forEach((ping) => {
        if (ping.Status === 'finish'||ping.Status === 'cancel') {
          this.removeFinishedProcess(ping);
        }
      });
    }
  }
  // Method to send stop signal via WebSocket
  async stopProcess(channel: string): Promise<void> {
    try {
      await this.socketService.stopProcess(channel);  // Send the stop signal
    } catch (err) {
      console.error('Failed to send stop request', err);
    }
  }
}
