import { formatNumber } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
  forwardRef,
  inject,
} from '@angular/core';
import {
  CompiereDataGridFilterType,
  CompiereDataGridGroupModel,
  CompiereDataGridRequestJSON,
  CompiereDataGridResponseJSON,
  CompiereDataGridType,
  DataGridExportFormat,
  DataStore,
  DataStoreRequest,
  DataStoreStatus,
} from '@compiere-ws/models/compiere-data-json';
import { CompierePrecisionData } from '@compiere-ws/models/compiere-precision-json';
import { CompiereExportDataService } from '@compiere-ws/services/compiere-export-data/compiere-export-data.service';
import {
  GridPreference,
  GridPreferenceType,
  GridPreferencesService,
} from '@compiere-ws/services/grid-preferences/grid-preferences.service';
import { OperatorFilterType } from '@iupics-components/models/universal-filter';
import { InfoDialogType } from '@iupics-components/specific/window/info-dialog/info-dialog.component';
import { SpecificGridQuery } from '@iupics-components/specific/window/specific-window-ui/specific-window-ui.component';
import GridViewUiComponent from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import BladeUiComponent from '@iupics-components/standard/layouts/blade-ui/blade-ui.component';
import EditTabUiComponent from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
import { EditViewUtils } from '@iupics-components/standard/layouts/edit-view-ui/utils/edit-view.utils';
import { AppConfig } from '@iupics-config/app.config';
import { CacheManagerService, CacheName } from '@iupics-manager/managers/cache-manager/cache-manager.service';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorUtils } from '@iupics-manager/managers/ui-creator/utils/ui-creator.utils';
import { WindowFactoryService } from '@iupics-manager/managers/ui-creator/window-factory/window-factory.service';
import { AbstractDynamicComponent } from '@iupics-manager/models/abstract-dynamic-component';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsData, IupicsTableDataHeader, NumberType } from '@iupics-manager/models/iupics-data';
import { IupicsCellEvent, IupicsEvent } from '@iupics-manager/models/iupics-event';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { IupicsColumnInfo } from '@iupics-manager/models/iupics_column_info';
import { ApizGridUtils } from '@iupics-util/tools/apiz-grid.utils';
import { LogicEvaluator } from '@iupics-util/tools/logic-evaluator';
import {
  ApizEventType,
  ApizGridComponent,
  ApizGridEvent,
  ApizGridHeadlessComponent,
  ApizSelect,
  AppliedItem,
  AppliedItemType,
  ColDef,
  ColumnState,
  ContextMenuItemDef,
  DataResponse,
  DataSource,
  DataSourceGetDataParams,
  GetContextMenuItemsFn,
  GetContextMenuItemsParams,
  GridApiService,
  GridOptionsAppliedItems,
  GridReadyEvent,
  RowDataState,
  RowDataStatus,
  RowSelectionMode,
  ValueFormatterParams,
  injectColumnApiService,
  injectGridApiService,
  provideColumnApiService,
  provideGridApiService,
  providePanelService,
} from '@iupics/apiz-grid';
import GridWidgetComponent from '@iupics/modules/iupics-widgets/components/grid-widget/grid-widget.component';
import { TranslateService } from '@ngx-translate/core';
import { IupicsMenuType } from '@web-desktop/models/menu-item-ui';
import { cloneDeep, debounce, isNil } from 'lodash';
import * as moment from 'moment';
import { Observable, Subscription, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { injectFilterCtxService, provideFilterCtxService } from '../filters/services/filter-ctx.service';
import { injectViewColumnsService, provideViewColumnsService } from '../services/view-columns.service';

class GridTabInfinityScrollConstant {
  static DEFAULT_MAXIMUM_NUMBER_LINES = 10;
  static readonly DEFAULT_EMPTY_HEIGHT = '165px';
  static readonly DEFAULT_EMPTY_HEIGHT_WITH_PINNED_COLS = '185px';
  static readonly ROW_PAGINATOR_HEIGHT = 28;
}

const templateUrl = './grid-tab-infinity-scroll-ui.component.html';
const styleUrls = ['./grid-tab-infinity-scroll-ui.component.scss'];
const encapsulation = ViewEncapsulation.None;
const standalone = true;
const imports = [ApizGridHeadlessComponent];

@Component({
  selector: 'iu-grid-tab-infinity-scroll-ui',
  templateUrl,
  styleUrls,
  encapsulation,
  standalone,
  imports,
  providers: [
    provideGridApiService(),
    provideColumnApiService(),
    providePanelService(),
    forwardRef(() => provideFilterCtxService()),
    provideViewColumnsService(),
  ],
})
export default class GridTabInfinityScrollUiComponent
  extends AbstractDynamicComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  #translate = inject(TranslateService);
  #windowFactory = inject(WindowFactoryService);
  #store = inject(DataStoreService);
  #connectorService = inject(SecurityManagerService);
  #config = inject(AppConfig);
  #exportDataService = inject(CompiereExportDataService);
  #messageManager = inject(MessageManagerService);
  #gridPreferencesService = inject(GridPreferencesService);
  #cacheService = inject(CacheManagerService);
  #cdRef = inject(ChangeDetectorRef);

  @Input() isZoomTargetGrid: boolean;
  @Input() isTabTopLevel: boolean;
  @Input() isSearch = false;
  @Input() isChangeLog = false;
  @Input() rowSelection: RowSelectionMode = 'multiple';
  rowMultiSelectWithClick = false;

  /** détermine de quelle type de fenetre vient cette grid */
  @Input() windowType = IupicsMenuType.WINDOW;

  forceCallWs = true;
  components: any;
  columns: IupicsColumnInfo[];
  columnsTableHeader: IupicsTableDataHeader[];

  @Input() overridedTableHeight = false;
  @Input() tableHeight: string;

  tableHeightBackup: string;
  calculeTableHeight = this.getConstant('DEFAULT_CALCULE_TABLE_HEIGHT');
  isOneColumn: boolean;
  dataStoreKeys = {};
  pinnedBottomRow: any;

  @Input() query: SpecificGridQuery;
  @Input() initRequest: CompiereDataGridRequestJSON;

  dataSourceCreated = false;
  shouldChangePage = false;
  gatheredComplexDatas = {};

  @Output() multiSelectEmitter = new EventEmitter<{ selected: boolean; data: {}[] }>();
  @Output() searchEmitter = new EventEmitter<any>();
  @Output() gridPrefLoaded = new EventEmitter<GridTabInfinityScrollUiComponent>();
  @Output() gridViewCellClicked = new EventEmitter<any>();
  @Output() notifyGridTabAfterViewInitEmitter = new EventEmitter<any>();
  @Output() gridRefreshed: EventEmitter<DataStoreRequest> = new EventEmitter();
  @Output() gridCellEditingStopped: EventEmitter<DataStoreRequest> = new EventEmitter();
  @Output() columnStateChanged: EventEmitter<ColumnState[]> = new EventEmitter();

  @Input() dataSource: DataSource;
  isDisplayFilter = false;
  maxConcurrentDatasourceRequests = 2;

  /** Number of line view when you scroll at bottom. */
  cacheOverflowSize = 2;
  paginationPageSize: number;

  /** Number row retrieve per Block. */
  @Input() cacheBlockSize: number;

  @Input() validation: string;
  @Input() initFromSwitch = false;
  @Input() shouldClearFilters = true;
  @Input() shouldClearSorts = true;
  @Input() shouldClearGroups = true;

  @Input() suppressRowClickSelection = false;
  @Input() rowClassRules: any;

  currentSelectIndex = -1;
  currentSelectPageIndex = 0;
  pinnedColKeys: string[];
  selectFirst = false;

  @ViewChild('grid', { static: true }) grid: ApizGridComponent;
  @Input() dataStored: DataStore;

  tempDataItems = [];

  parent_constraint: string = null;
  @Input() hasCheckBox = true;
  @Input() parentComponent: any;

  getContextMenuItems: GetContextMenuItemsFn = this.#getContextMenuItems.bind(this);

  currentStateRequest: CompiereDataGridRequestJSON;

  private isGetRowsInProgress = false;
  defaultColDef: ColDef = {
    sortable: true,
    resizable: false,
  };

  gridPreference: GridPreference;
  protected expandedColumnState: ColumnState[];
  protected reducedColumnState: ColumnState[];
  private subscriptionsGetRows: Map<string, Subscription> = new Map();

  isMobile = Global.isMobile();

  get isInSpecificOrNotLvl0(): boolean {
    return (this.data.AD_FormDetail_ID != undefined && this.data.AD_FormDetail_ID > 0) || !this.isTabTopLevel;
  }

  @Input() forcePaginationAutoPageSize = false;

  columnDatas: any[];
  rowIndexCounter = 0;

  @Input() pagination = true;
  @Input() gridState: ColumnState[];

  api = injectGridApiService();
  columnApi = injectColumnApiService();
  filterCtx = injectFilterCtxService();
  #viewColumnsService = injectViewColumnsService();

  protected headless = false;

  constructor() {
    super();
    this.cacheBlockSize = this.#config.getConstant('GridTabInfinityScrollUiComponent#cacheBlockSize');
    GridTabInfinityScrollConstant.DEFAULT_MAXIMUM_NUMBER_LINES = this.#config.getConstant(
      'GridTabInfinityScrollUiComponent#Maximum_Number_Lines'
    )
      ? this.#config.getConstant('GridTabInfinityScrollUiComponent#Maximum_Number_Lines')
      : 10;
    this.paginationPageSize = GridTabInfinityScrollConstant.DEFAULT_MAXIMUM_NUMBER_LINES;
    this.updateColumnState = debounce(this.updateColumnState, 500);
  }

  ngOnInit() {
    Global.startPerf(this);
    this.subscriptions.push(
      this.#viewColumnsService.colDefs$.subscribe(({ columns, columnsData, columnsTableHeader }) => {
        this.columnDatas = columnsData;
        this.columns = columns;
        this.paginationPageSize = this.getConstant('DEFAULT_MAXIMUM_NUMBER_LINES');
        this.rowMultiSelectWithClick = this.windowType === IupicsMenuType.FORM;
        moment.locale(this.#connectorService.getIupicsDefaultLanguage().iso_code);
        if (this.data.urlSearch) {
          this.isSearch = true;
          this.isTabTopLevel = false;
          if (!columnsTableHeader && this.data.searchColumns) {
            this.data.hasCheckbox = false;
          }

          if (!this.container && this.data['container']) {
            this.container = this.data['container'];
          }
        }

        this.columnsTableHeader = columnsTableHeader;
        if (<GridViewUiComponent>this.DOMParentComponent && this.columnsTableHeader) {
          (<GridViewUiComponent>this.DOMParentComponent).columnNames = this.columnsTableHeader.reduce((acc, col) => {
            if (col.field !== 'Data_UUID') {
              acc.push({
                id: col.field,
                displayValue: col.headerName,
              });
            }

            return acc;
          }, []);
        }

        this.data.items = [];
        this.subscriptions.push(this.componentEmitter.subscribe((event) => this.#windowFactory.newEventHandler(event)));

        if (!this.filterCtx.hasGridView()) this.filterCtx.registerGrid(this);

        this.#cdRef.markForCheck();
      })
    );

    this.setView();
  }

  setView() {
    this.#viewColumnsService.setView({
      tabId: this.tabId,
      fromSpecific: this.data.AD_FormDetail_ID != undefined && this.data.AD_FormDetail_ID > 0,
      columnFormatter: this.columnFormatter.bind(this),
      ctx: this.getCurrentContext(),
      data: this.data,
      hasCheckBox: this.hasCheckBox,
      windowType: this.windowType,
      shouldSetColDefs: false,
    });
  }

  ngAfterViewInit() {
    if (this.gridState) {
      this.expandedColumnState = this.gridState;
    }
    Global.endPerf(this, '');
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.cancelRequests();
    if (this.filterCtx.hasGridTab()) this.filterCtx.unregisterGrid(this);
    if (!this.headless) this.api.ngOnDestroy();
  }

  /**
   * Apply query from url
   **/
  #applyInitRequest() {
    if (Object.keys(this.initRequest ?? {}).length > 0) {
      const appliedItems: GridOptionsAppliedItems = ApizGridUtils.appliedItemsFromCompiereRequest(
        this.initRequest,
        this.api,
        this.columnApi
      );

      ApizGridUtils.updateAppliedItems(appliedItems, this.api, this.filterCtx, this.#store);

      if (this.initRequest.validation) {
        this.validation = this.validation
          ? this.validation + ' and ' + this.initRequest.validation
          : this.initRequest.validation;
      }
      this.initRequest = undefined;
    }
  }

  setAllNodesSelection(selected: boolean) {
    if (this.grid) {
      if (selected) this.api.selectAll();
      else this.api.deselectAll();
    }
  }

  private cancelRequests() {
    let sub: IteratorResult<Subscription, Subscription>;
    const it = this.subscriptionsGetRows.values();
    while (!(sub = it.next()).done) {
      sub.value?.unsubscribe();
    }
  }

  createServerDataSource(currentData?: any, indexEditViewToEdit?: number) {
    Global.startPerf(this, 'createServerDataSource');
    this.dataSourceCreated = false;
    let isDisplayed = true;
    let parentDataStore: DataStore;
    let isNewRecord = false;
    let recordChanged = false;
    if (!this.isTabTopLevel) {
      const grid = <GridViewUiComponent>this.DOMParentComponent;
      if (grid?.parentTab && grid.editViewParent) {
        parentDataStore = this.getParentStore();
        isDisplayed = grid.updateDisplayLogic();
        if (isDisplayed) {
          currentData ||= this.getCurrentContext();
          const previousParent_constraint = this.parent_constraint;
          isNewRecord = previousParent_constraint ? previousParent_constraint.split('=').length <= 1 : true;
          this.parent_constraint = LogicEvaluator.parseLogic(
            currentData,
            this.gridTabFilter[0],
            this.#connectorService.getIupicsUserContext()
          );
          if (this.parent_constraint !== previousParent_constraint) {
            recordChanged = true;
          }
        }
      } else if (this.isSearch) {
        this.onGridReadySearch();
      }
    } else if (this.isZoomTargetGrid && this.gridTabFilter && this.gridTabFilter.length > 0) {
      this.parent_constraint = this.gridTabFilter[0];
    }

    if (isDisplayed) {
      this.dataSource = {
        getData: (params: DataSourceGetDataParams) => {
          //   if (this.preventGetRows) {
          //     this.preventGetRows = false;
          //     return;
          //   }
          //   console.log(params.request);

          if (parentDataStore && parentDataStore.status === DataStoreStatus.NEWRECORD) {
            return of({ data: [], rowCount: 0 } as DataResponse);
          }

          let windowType;
          let entityId;
          let searchEntityType;
          let searchKeyColumn;
          let query: SpecificGridQuery;
          if (this.isSearch) {
            query = this.query;
            if (this.windowType === IupicsMenuType.FORM) {
              windowType = CompiereDataGridType.FORM;
              entityId = this.data['AD_FormDetail_ID'];
            } else {
              windowType = CompiereDataGridType.SEARCH_WINDOW;
              entityId = this.data.details.entityID;
              searchEntityType = this.data.details.entityType;
              searchKeyColumn = this.data.details.keyColumn;
            }
          } else {
            windowType = CompiereDataGridType.WINDOW;
            entityId = this.tabId;
          }

          const windowId = this.windowType === IupicsMenuType.WINDOW ? this.data.fieldId : this.data.columnId;
          const dataStoreRequest: DataStoreRequest = {
            windowId: this.container ? this.data.AD_Window_ID : windowId,
            compiereRequest: {
              windowType: windowType,
              entityId: entityId,
              startRow: params.request.startRow,
              endRow: params.request.endRow,
              windowCtx: this.getCurrentContext(),
              validation: this.getTabWhereclause(),
            },
          };

          if (this.isSearch) {
            dataStoreRequest.compiereRequest.headerCols = this.getColumnsDisplayLogic(this.columnsTableHeader).reduce(
              (acc, col) => {
                if (col.field !== 'Data_UUID') {
                  acc.push({
                    id: col.field,
                    field: col.field,
                    displayName: col.headerName,
                    valueFormatter: this.columnFormatter.bind(this),
                  } as CompiereDataGridGroupModel);
                }
                return acc;
              },
              []
            );
          }

          if (this.parent_constraint) {
            // On ajoute la contrainte du parent si il y en a une
            dataStoreRequest.parent_constraint = this.parent_constraint;
          }

          dataStoreRequest.compiereRequest = Object.assign(dataStoreRequest.compiereRequest, params.request);
          if (params.request.sortModel) {
            // On ajoute le tri si il y en a un
            dataStoreRequest.compiereRequest.sortModel = params.request.sortModel;
          }

          if (params.request.filterModel && Object.keys(params.request.filterModel).length > 0) {
            dataStoreRequest.compiereRequest.filterModel = params.request.filterModel;
          }

          if (params.request.rowGroupCols) {
            dataStoreRequest.compiereRequest.rowGroupCols = params.request.rowGroupCols;
          }

          if (params.request.valueCols) {
            dataStoreRequest.compiereRequest.valueCols = params.request.valueCols;
          }

          dataStoreRequest.compiereRequest.searchEntityType = searchEntityType;
          dataStoreRequest.compiereRequest.searchKeyColumn = searchKeyColumn;
          if (this.DOMParentComponent) {
            dataStoreRequest.compiereRequest.parent_formDetail_id = (<GridViewUiComponent>(
              this.DOMParentComponent
            )).parentFormID;
          }
          // query des forms
          if (query) {
            if (query?.specificGridOptions?.shouldResetRequest && dataStoreRequest?.compiereRequest) {
              dataStoreRequest.compiereRequest.filterModel = {};
            }
            this.createFilterModelFromForm(query, dataStoreRequest, false);
          }
          if (
            dataStoreRequest.compiereRequest.groupKeys.length === dataStoreRequest.compiereRequest.rowGroupCols.length
          ) {
            dataStoreRequest.compiereRequest.valueCols = [];
          }

          dataStoreRequest.compiereRequest = ApizGridUtils.cleanDataRequestForCompiereRequest(
            dataStoreRequest.compiereRequest
          );

          return this.getRows(dataStoreRequest, params, isNewRecord, indexEditViewToEdit, recordChanged, null);
        },
      };

      if (
        !this.dataSourceCreated &&
        this.windowType !== IupicsMenuType.FORM &&
        this.windowType !== IupicsMenuType.INFO_WINDOW
      ) {
        if (<GridViewUiComponent>this.DOMParentComponent) {
          if ((<GridViewUiComponent>this.DOMParentComponent).isLaunchSearchGrid) {
            this.grid.api.setDatasource(this.dataSource);
            this.dataSourceCreated = true;
          }
        } else {
          this.grid.api.setDatasource(this.dataSource);
          this.dataSourceCreated = true;
        }
        if (this.notifyGridTabAfterViewInitEmitter.observed) {
          // settimeout pour etre sur qu'on emit au moment ou le gridtab est init el famoso AfterAfterViewInit
          setTimeout(() => {
            this.notifyGridTabAfterViewInitEmitter.emit(this);
          }, 200);
        }
      } else if (this.windowType === IupicsMenuType.FORM || this.windowType === IupicsMenuType.INFO_WINDOW) {
        if (this.notifyGridTabAfterViewInitEmitter.observed) {
          // settimeout pour etre sur qu'on emit au moment ou le gridtab est init el famoso AfterAfterViewInit
          setTimeout(() => {
            this.notifyGridTabAfterViewInitEmitter.emit(this);
          }, 200);
        } else {
          // pour le cas des gridtab dans des forms défini dans la template html exemple => tree view
          this.grid.api.setDatasource(this.dataSource);
          this.dataSourceCreated = true;
        }
      }
    }

    Global.endPerf(this, 'createServerDataSource');
  }

  columnResize() {
    if (this.data && this.data.isFitResize && !Global.isMobileWidth()) {
      this.grid.columnApi.autoSizeAllColumns();
    } else {
      if (this.grid.api.groups().length > 0) {
        this.grid.columnApi.autoSizeColumn(this.grid.columnApi.getAllGridColumns()[0].getColId());
      }
      this.grid.columnApi.setColumnWidth(this.columnsTableHeader[0].field, 38);
    }
    if ((<GridViewUiComponent>this.DOMParentComponent)?.editViewParent && this.DOMParentComponent.parentTab) {
      // On check si le parent attends la réponse du resize pour scroll
      if ((<GridViewUiComponent>this.DOMParentComponent).editViewParent.isWaitingScroll) {
        this.DOMParentComponent.parentTab.scrollTo();
      }
    }
  }

  refresh(removeDataFromStore = true, query?: SpecificGridQuery) {
    if (this.isGetRowsInProgress) {
      if (query?.specificGridOptions?.isFromInputLaunch) {
        this.cancelRequests();
      } else {
        return;
      }
    }
    this.forceCallWs = removeDataFromStore;
    if (query) {
      this.query = query;
    }
    this.shouldChangePage = true;

    this.currentSelectPageIndex = this.api.getCurrentPage();
    if (this.dataSourceCreated) this.api.refresh();
    else {
      this.api.setDatasource(this.dataSource);
      this.dataSourceCreated = true;
    }
  }

  /** Passage en mode colonne unique. */
  onlyOneColumn(loadPref = true) {
    if (this.isTabTopLevel) {
      let columnsReduce = new Set(this.data.columnsReduceGrid);
      if (columnsReduce.size === 0) {
        columnsReduce = new Set(['Value', 'Name', 'DocumentNo']);
      }
      const cols = this.grid.columnApi.getAllGridColumns().filter((col) => !columnsReduce.has(col.getColDef().field));
      this.grid.columnApi.setColumnsVisible(cols, false);
      this.grid.columnApi.autoSizeAllColumns();
    }
    this.displayHideFilters(false);
    this.isOneColumn = true;
    // init du layout perso de l'utilisateur
    if (loadPref) {
      this.subscriptions.push(this.loadPreference(true).subscribe());
    }
  }

  allColumns(staySelect = true) {
    if (!staySelect) {
      this.grid.api.deselectAll();
      (<GridViewUiComponent>this.DOMParentComponent).selectedRecordId = null;
    }
    // init du layout perso de l'utilisateur
    this.subscriptions.push(
      this.loadPreference(true).subscribe((loaded) => {
        if (!loaded) {
          if (this.expandedColumnState) {
            this.grid.columnApi.applyColumnState({ state: this.expandedColumnState, applyOrder: true });
          } else if (this.isTabTopLevel) {
            for (const col of this.grid.columnApi.getAllGridColumns()) col.setVisible(true);
            this.columnResize();
          }
        }

        if (this.isTabTopLevel) {
          this.grid.api.refresh();
        }

        this.isOneColumn = false;
      })
    );
  }

  onChildUpdate(event): void {}

  onSiblingUpdate(event: IupicsEvent) {}

  onRemoveComponent(event: IupicsEvent) {}

  onGridReadySearch() {}

  /** handler of dataStateChanged event of apiz-grid */
  onDataStateChanged(event: ApizGridEvent<RowDataState>) {
    if (event.data.status === RowDataStatus.LOADED) {
      if (
        this.DOMParentComponent instanceof GridViewUiComponent &&
        !(this.DOMParentComponent.DOMParentComponent instanceof EditTabUiComponent)
      ) {
        (this.DOMParentComponent.DOMParentComponent as BladeUiComponent).notifyUrlChange();
      }
    }
  }

  displayHideFilters(isDisplay: boolean) {
    this.isDisplayFilter = isDisplay;
    this.columnsTableHeader[0].hide = true;
  }

  @Input() // TODO: <--- Check if this is necessary
  getCurrentContext() {
    let editViewParent;
    if (this.DOMParentComponent) {
      return (<GridViewUiComponent>this.DOMParentComponent).getCurrentContext();
    } else {
      if (this.parentComponent?.editTabs?.[0]) {
        editViewParent = this.parentComponent;
      }
      return EditViewUtils.getCurrentContext(
        editViewParent,
        this.dataStored ? this.dataStored : undefined,
        this.#connectorService.getIupicsUserContext()
      );
    }
  }

  getTabWhereclause() {
    let validation;
    let validationCode;
    if (this.validation) {
      // On ajoute la validation si il y en déjà a une
      validation = this.validation;
    }

    if (this.data.validationCode) {
      if (this.isSearch && this.container?.editTabs?.[0]?.editViewParent?.parentStore?.data) {
        const ctxWindow = EditViewUtils.mergeCurrentDataDeepCopy(
          this.getCurrentContext(),
          this.container.editTabs[0].editViewParent.parentStore.data
        );
        validationCode = LogicEvaluator.replaceVariables(
          this.data.validationCode,
          this.#connectorService.getIupicsUserContext(),
          ctxWindow,
          false,
          false,
          !this.isInUniversalFilter
        );
      } else {
        validationCode = LogicEvaluator.replaceVariables(
          this.data.validationCode,
          this.#connectorService.getIupicsUserContext(),
          this.getCurrentContext(),
          false,
          false,
          !this.isInUniversalFilter
        );
      }

      if (validationCode && this.windowType !== IupicsMenuType.FORM) {
        validation = validation ? validation + ' and ' + validationCode : validationCode;
      }
    }
    if (this.DOMParentComponent) {
      validation = (<GridViewUiComponent>this.DOMParentComponent).getTabWhereclause(validation);
    }

    return validation;
  }

  getParentStore() {
    if (this.DOMParentComponent) {
      return (<GridViewUiComponent>this.DOMParentComponent).getParentStore();
    } else if (this.parentComponent?.editTabs?.[0]) {
      return this.parentComponent.editTabs[0].dataStored;
    }
  }
  /**
   * Permet de cacher les columns dont la displaylogic est validée
   */
  private getColumnsDisplayLogic(cols: IupicsTableDataHeader[]) {
    cols = cols.map((col: IupicsTableDataHeader) => {
      col.valueFormatter = this.columnFormatter.bind(this);
      return col;
    });
    if (this.data.columnsDisplayLogicMap && this.data.columnsDisplayLogicMap.size > 0) {
      return cols.filter((col) => {
        let returnCol = null;
        const displayLogic = this.data.columnsDisplayLogicMap.get(col.field);
        if (this.data.hiddenColumns !== undefined) {
          if (!this.data.hiddenColumns.includes(col.field)) {
            if (
              !displayLogic ||
              displayLogic === '' ||
              LogicEvaluator.evaluateLogic(this.getCurrentContext(), displayLogic)
            ) {
              returnCol = col;
            }
          } else {
            col.hide = true;
            returnCol = col;
          }
          return returnCol;
        } else {
          if (
            !displayLogic ||
            displayLogic === '' ||
            LogicEvaluator.evaluateLogic(this.getCurrentContext(), displayLogic)
          ) {
            returnCol = col;
          }

          return returnCol;
        }
      });
    } else {
      return cols;
    }
  }

  onRowSelected(event: ApizGridEvent<ApizSelect>) {
    this.multiSelectEmitter.emit({
      selected: this.grid.api.getSelectedRows().length > 0,
      data: this.grid.api.getSelectedRows(),
    });
  }

  onCellClicked(event: ApizGridEvent<Partial<IupicsCellEvent>>) {
    if (!event.data.row.isExpandable) {
      if (event.data.origin && event.data.origin === 'edit') {
        this.grid.api.deselectAll();
        event.data.row.setSelected(true);
      }
      this.currentSelectIndex = event.data.row.rowIndex;
      if (event.data.colDef.field !== 'Data_UUID' || this.windowType === IupicsMenuType.FORM) {
        if (!this.isSearch) {
          const windowId = this.data.AD_Window_ID;
          const dataStoreKey = this.#store.generateDataStoreKey(
            windowId,
            this.tabId,
            event.data.data['Data_UUID'],
            this.parent_constraint
          );
          if (this.DOMParentComponent) {
            (<GridViewUiComponent>this.DOMParentComponent).selectedRecordId = event.data.data['Data_UUID'];
            (<GridViewUiComponent | GridWidgetComponent>this.DOMParentComponent).onRowSelectedOnGridTab(dataStoreKey);
          }
        } else if (
          this.windowType === IupicsMenuType.FORM &&
          this.data.editorColumns &&
          this.data.editorColumns.length > 0 &&
          this.data.editorColumns.indexOf(event.data.colDef.field) >= 0
        ) {
          this.gridViewCellClicked.emit(event.data);
        } else {
          if (this.dataStored) {
            // classic search panel
            this.dataStored.addContextData(event.data.data, this.#config.getConstant('ContextPrefixSearchWindow'));
          }
          this.searchEmitter.emit(event.data);
        }
      } else {
        this.multiSelectEmitter.emit({ selected: true, data: this.grid.api.getSelectedRows() });
      }
    }
  }

  getKeysAndDataArray(datas: any): any[] {
    const dataArray = [];
    for (const key in datas) {
      if (datas[key] && datas.hasOwnProperty(key)) {
        dataArray[datas[key].seqNo] = datas[key].data;
        this.dataStoreKeys[key] = datas[key].key;
      }
    }

    return dataArray.filter((n) => n !== undefined);
  }

  getKeysAndDataSearch(datas: any): any[] {
    const dataArray = [];
    if (this.columnsTableHeader && datas) {
      for (const data of datas) {
        const dataTransformed = {};
        for (let j = 0; j < data.length; j++) {
          dataTransformed[this.columnsTableHeader[j].field] = data[j];
        }
        dataArray.push(dataTransformed);
      }
    }

    return dataArray.filter((n) => n !== undefined);
  }

  openEmptyEditView() {
    (<GridViewUiComponent>this.DOMParentComponent).openNew();
  }

  selectFirstLine(data) {
    const windowId = this.data.AD_Window_ID;
    const dataStoreKey = this.#store.generateDataStoreKey(
      windowId,
      this.tabId,
      this.#store.getRecordIdString(data['Data_UUID']),
      this.parent_constraint
    );
    if (this.#store.findInCurrentStore(dataStoreKey)) {
      (<GridViewUiComponent>this.DOMParentComponent).onRowSelectedOnGridTab(dataStoreKey);
      setTimeout(() => {
        const firstNode = this.grid.api.getRowAtIndex(0);
        this.grid.api.deselectAll();
        if (firstNode) {
          firstNode.setSelected(true);
        }
        this.currentSelectIndex = 0;
      }, 10);
    }
  }

  /**
   *
   * @param castPageChange true si redirection vers bonne page
   */
  selectLastLineSelected(castPageChange: boolean = true) {
    if (
      this.grid &&
      <GridViewUiComponent>this.DOMParentComponent &&
      (<GridViewUiComponent>this.DOMParentComponent).selectedRecordId
    ) {
      this.setSelectedRow((<GridViewUiComponent>this.DOMParentComponent).selectedRecordId, castPageChange);
    } else if (this.grid && castPageChange) {
      this.grid.api.goToPage(this.currentSelectPageIndex);
    }
  }

  getAllValuesOfColumn(grid: ApizGridComponent, columnName) {
    const rowValues = [];
    grid.api.forEachNode((node) => {
      rowValues.push(node.getRowData()[columnName]);
    });
    return rowValues;
  }

  createPinnedSumRow(grid: ApizGridComponent, pinnedColKeys, callBack) {
    let windowType;
    let entityId;
    let searchEntityType;
    let searchKeyColumn;
    let query;
    if (this.isSearch) {
      query = this.query;
      windowType = CompiereDataGridType.SEARCH_WINDOW;
      switch (this.windowType) {
        case IupicsMenuType.FORM:
          windowType = CompiereDataGridType.FORM;
          entityId = this.data['AD_FormDetail_ID'];
          break;
        default:
          windowType = CompiereDataGridType.SEARCH_WINDOW;
          entityId = this.data.details.entityID;
          searchEntityType = this.data.details.entityType;
          searchKeyColumn = this.data.details.keyColumn;
          break;
      }
    } else {
      windowType = CompiereDataGridType.WINDOW;
      entityId = this.tabId;
    }

    const windowId = this.windowType === IupicsMenuType.WINDOW ? this.data.fieldId : this.data.columnId;
    const dataStoreRequest: DataStoreRequest = {
      windowId: this.container ? this.data.AD_Window_ID : windowId,
      compiereRequest: {
        windowType: windowType,
        entityId: entityId,
        startRow: 0,
        endRow: 0,
        windowCtx: this.getCurrentContext(),
        validation: this.getTabWhereclause(),
      },
    };

    if (this.parent_constraint) {
      // On ajoute la contrainte du parent si il y en a une
      dataStoreRequest.parent_constraint = this.parent_constraint;
    }
    const filterModel = this.api.getFilterModel();
    if (filterModel && Object.keys(filterModel).length > 0) {
      // On ajoute le filtre si il y en a un
      dataStoreRequest.compiereRequest.filterModel = filterModel;
    }
    dataStoreRequest.compiereRequest.searchEntityType = searchEntityType;
    dataStoreRequest.compiereRequest.searchKeyColumn = searchKeyColumn;
    if (this.DOMParentComponent) {
      dataStoreRequest.compiereRequest.parent_formDetail_id = (<GridViewUiComponent>(
        this.DOMParentComponent
      )).parentFormID;
    }
    // query des forms
    if (query) {
      this.createFilterModelFromForm(query, dataStoreRequest);
    }
    const bottomRow = {};
    const result = [];
    if (pinnedColKeys.length > 0) {
      const columnNames = [];
      dataStoreRequest.compiereRequest.valueCols = [];
      for (const key of pinnedColKeys) {
        const colDef = grid.columnApi.getColumn(key).getColDef();
        const columnName = colDef.field;
        columnNames.push(columnName);
        dataStoreRequest.compiereRequest.valueCols.push({
          id: columnName, // TODO - AFTER_MIGRATION_APIZ_GRID: voir pour simplifier l'objet en retirant id et en utilisant uniquement colId, cela impliquera de modifier le backend probablement
          aggFunc: 'sum',
          colId: columnName,
          field: columnName,
          displayName: colDef.headerName,
        });
      }
      dataStoreRequest.compiereRequest = ApizGridUtils.cleanDataRequestForCompiereRequest(
        dataStoreRequest.compiereRequest
      );
      this.#store.getDataGrid(dataStoreRequest).subscribe((response: CompiereDataGridResponseJSON) => {
        if (response.data?.[0]) {
          for (const columnName of columnNames) {
            if (response.data[0][columnName] !== null) {
              const value: number = response.data[0][columnName];
              bottomRow[columnName] = value;
            } else {
              bottomRow[columnName] = 0;
            }
          }
          result.push(bottomRow);
        }
        callBack(result);
      });
    }
  }
  /**
   * permet de setté une row correspondant à un recordId
   * @param recordId identifiant recherché
   * renvoie si l'élément a été trouvé
   */
  setSelectedRow(recordId: string, castPageChange: boolean = true): boolean {
    let found = false;
    // aller à la page sur laquelle on était
    if (castPageChange && this.currentSelectPageIndex > this.grid.api.getCurrentPage()) {
      this.grid.api.goToPage(this.currentSelectPageIndex);
      if (this.DOMParentComponent) {
        (<GridViewUiComponent>this.DOMParentComponent).selectedRecordId = recordId;
      }
      this.currentSelectIndex = null;
      this.grid.api.deselectAll();
      this.shouldChangePage = false;
    }
    this.grid.api.forEachNode((node) => {
      const data = node.getRowData();
      if (data && data['Data_UUID'] == recordId) {
        this.currentSelectIndex = node.rowIndex;
        const nbRowPerPage = this.grid.api.getPageSize();
        const currentSelectPageIndex = Math.trunc(+node.id / nbRowPerPage);
        if (castPageChange) {
          this.grid.api.goToPage(currentSelectPageIndex);
          this.shouldChangePage = false;
        }
        if (!node['selected']) {
          node.setSelected(true);
        }
        found = true;
      }
    });
    // if (!found) {
    // this.api.paginationGoToPage(this.api.paginationGetTotalPages() + 1);
    // }
    return found;
  }

  simplifyData(datas: any[], checkTempRow = false): any[] {
    if (checkTempRow && this.tempDataItems.length > 0) {
      for (const item of this.tempDataItems) datas.splice(0, 0, item);
    }

    const dataRefactored = [...datas];
    for (const data of dataRefactored) {
      for (const key in data) {
        if (data?.[key]?.id !== undefined) {
          this.gatheredComplexDatas[key + '$' + data[key].id] = data[key].displayValue;
          data[key] = data[key].id;
        }
      }
    }

    return dataRefactored;
  }

  getDisplayValue(columnName: string, id: number): any {
    return this.gatheredComplexDatas[columnName + '$' + id];
  }

  #getContextMenuItems(params: GetContextMenuItemsParams): ContextMenuItemDef[] {
    const subMenu: ContextMenuItemDef[] = Object.values(DataGridExportFormat).map<ContextMenuItemDef>((format) => {
      let icon;
      switch (format) {
        case 'xlsx':
          icon = 'icon-excel';
          break;
        case 'pdf':
          icon = 'icon-pdf';
          break;
        case 'csv':
          icon = 'icon-csv';
          break;
        // case 'html':
        //   icon = 'icon-html';
        //   break;
        default:
          break;
      }

      return {
        name: this.#translate.instant(`gridTools.context.${format}Export`),
        action: () => {
          if (this.currentStateRequest) {
            if (this.currentStateRequest.rowGroupCols.length > 0) {
              Global.infoDialog.message = {
                summary: '',
                detail: this.#translate.instant('gridTools.context.exportGroupDetails'),
              };

              Global.infoDialog.dialogType = InfoDialogType.CONFIRM_YESNO;
              Global.infoDialog.showInfoDialog();

              const dialogFn = (includeGroupDetails: boolean) => {
                this.createExport(format, includeGroupDetails);
                if (confirm !== undefined) {
                  confirm.unsubscribe();
                }
                if (cancel !== undefined) {
                  cancel.unsubscribe();
                }
              };

              const confirm = Global.infoDialog.confirm.subscribe((e: any) => dialogFn(true));
              const cancel = Global.infoDialog.cancel.subscribe((e) => dialogFn(false));
            } else {
              this.createExport(format);
            }
          } else {
            this.#messageManager.newMessage(
              new IupicsMessage(
                this.#translate.instant('exportData.messageTitle'),
                this.#translate.instant('exportData.exportDataFail'),
                'error'
              )
            );
          }
        },
        icon: `<span class="ag-icon ${icon}" unselectable="on" role="presentation"></span>`,
        type: 'item',
      };
    }) as ContextMenuItemDef[];
    const result: ContextMenuItemDef[] = [
      {
        name: this.#translate.instant(`gridTools.copy`),
        action: () => {
          const value = this.getValueByColDef(params.column.getColDef(), params.value, params.row.getRowData());
          EditViewUtils.copyToClipboard(value);
        },
        icon: '<span class="ag-icon ag-icon-copy" unselectable="on" role="presentation"></span>',
        type: 'item',
      },
      { type: 'separator' },
      {
        name: this.#translate.instant(`gridTools.export`),
        subMenu,
        icon: '<span class="icon-export" unselectable="on" role="presentation"></span>',
        type: 'item',
      },
    ];
    if (this.grid.api.groups().length > 0) {
      result.push(
        {
          name: this.#translate.instant(`gridTools.collapseAll`),
          action: () => this.grid.api.collapseAll(),
          icon: '<span class="icon-up" unselectable="on" role="presentation"></span>',
          type: 'item',
        },
        {
          name: this.#translate.instant(`gridTools.expandAll`),
          action: () => this.grid.api.expandAll(),
          icon: '<span class="icon-open" unselectable="on" role="presentation"></span>',
          type: 'item',
        }
      );
    }
    return result;
  }

  private createExport(type: DataGridExportFormat, includeGroupDetails = false) {
    const request: CompiereDataGridRequestJSON = { ...this.currentStateRequest };
    request.startRow = 0;
    request.endRow = this.#config.getConstant('MAX_RECORD_EXPORT');
    request.headerCols = this.grid.columnApi.getColumns().reduce<CompiereDataGridGroupModel[]>((acc, col) => {
      if (col.isVisible()) {
        acc.push({
          id: col.getColId(),
          field: col.getColId(),
          displayName: col.getColDef().headerName,
        });
      }

      return acc;
    }, []);

    if (this.data.tableName && this.grid?.api) {
      const selectedRows = this.grid.api.getSelectedRows();
      if (selectedRows.length > 0) {
        const keyColumns = [];
        for (const colData of this.columnDatas) {
          if (colData.data.IsKey) {
            keyColumns.push(colData.data.ColumnName);
          }
        }

        if (keyColumns.length === 0) {
          if (this.columnDatas.length > 0 && this.columnDatas[0]?.data?.ColumnName.includes('_ID')) {
            keyColumns.push(this.columnDatas[0].data.ColumnName);
          } else {
            throw new Error(this.#translate.instant('exportData.noKeys'));
          }
        }

        request.exportSelections = selectedRows.map((row) => {
          const obj = {};

          for (const key of keyColumns) {
            obj[key] = row[key];
          }

          return obj;
        });
      }
    }

    request.windowCtx = this.getCurrentContext();
    this.subscriptions.push(
      this.#exportDataService.exportDataGrid(request, type, includeGroupDetails).subscribe({
        next: (message: string) =>
          this.#messageManager.newMessage(
            new IupicsMessage(
              this.#translate.instant('exportData.messageTitle'),
              this.#translate.instant('exportData.exportDataStarted'),
              'success',
              message
            )
          ),
        error: (message: string) =>
          this.#messageManager.newMessage(
            new IupicsMessage(
              this.#translate.instant('exportData.messageTitle'),
              this.#translate.instant('exportData.exportDataFail'),
              'error',
              message
            )
          ),
      })
    );
  }

  public getExportSelections(): { [key: string]: any }[] {
    const selectedRows = this.grid.api.getSelectedRows();
    if (selectedRows.length > 0) {
      const selectedLines = [];
      selectedRows.map((row) => {
        const dsSplit = (row.Data_UUID as string).split(',');
        if (dsSplit.length > 1) {
          let obj = {};
          for (let i = 0; i < dsSplit.length; i += 2) {
            obj[dsSplit[i]] = dsSplit[i + 1];
          }
          selectedLines.push(obj);
        }
      });
      return selectedLines;
    }

    return null;
  }

  onRowGroupOpened(params) {
    if (params.node.key === '') {
      params.node.key = null;
    }

    if (this.windowType === IupicsMenuType.FORM && params.node.expanded) {
      // node was expanded
      this.tableHeight = this.getConstant('DEFAULT_MAX_TABLE_HEIGHT');
    }
  }

  onCellEditingStopped(event: any) {
    this.gridCellEditingStopped.emit(event);
  }
  /**
   * Permet de créer la validation du datagridRequest sur base de la query venant de la form
   */
  createValidationFromForm(validations) {
    if (!validations) return;

    let validation = '';
    const validationsReformat = { ...validations };
    for (const key in validationsReformat) {
      if (validationsReformat[key] instanceof Object) {
        validationsReformat[key] = validationsReformat[key].id;
      }
      validation += (validation.length > 0 ? ',' : '') + key + ',' + validationsReformat[key];
    }

    if (this.validation !== validation) {
      this.validation = validation;
      return true;
    } else {
      return false;
    }
  }
  /**
   * Creer un filterModel à partir de la query envoyée par le form
   * @param query : query contenant les filtres, les validations et un paramètre forced qui indique si il faut reformater le filtermodel
   * @param dataStoreRequest : request à alimenter
   * @param forced : savoir si il faut forcer le reformatage des values du filtermodel: value => id, displayvalue
   */
  createFilterModelFromForm(query, dataStoreRequest: DataStoreRequest, forced = false) {
    let filterModel = Object.assign({}, dataStoreRequest.compiereRequest.filterModel);
    let filterModelChanged = forced;
    const filters = query.filters;
    const validations = query.validations;
    const fieldsFromForm = query.fields ?? [];
    if (validations) {
      filterModelChanged = this.createValidationFromForm(query.validations);
      if (this.validation) dataStoreRequest.compiereRequest.validation = this.validation;
    }
    const filtersReformat = Object.assign({}, filters);
    const gridView = <GridViewUiComponent>this.DOMParentComponent;
    const datastore = gridView && gridView.fromForm && gridView.container ? gridView.container.dataStore : null;
    if (filters && Object.keys(filters).length > 0) {
      /*on supprime les filtres qui ont été vidé dans les filtres de la form */
      for (const key of Object.keys(fieldsFromForm)) {
        if (fieldsFromForm[key] && fieldsFromForm[key].data.isFilterSearch === true) {
          if (!datastore || datastore.data[key] === undefined || datastore.data[key] === null) {
            if (filterModel[key]) {
              filterModelChanged = true;
            }
            delete filterModel[key];
          }
          if (datastore && datastore.data[key] !== undefined && datastore.data[key] !== null) {
            if (!datastore.data[key.endsWith('_To') ? key.substring(0, key.length - 3) : key]) {
              filtersReformat[key] = datastore.data[key];
              if (!filterModel[key]) {
                filterModelChanged = true;
              }
            }
          }
        }
      }
      for (const key of Object.keys(filtersReformat)) {
        // On vérifie le type de champ pour formater la value à indiquer dans le filterModel
        const filterType = fieldsFromForm[key]
          ? UICreatorUtils.getFilterTypeFromReference(fieldsFromForm[key]['referenceId'])
          : typeof filtersReformat[key] === 'number'
            ? key.includes('_ID')
              ? CompiereDataGridFilterType.SET
              : CompiereDataGridFilterType.NUMBER
            : CompiereDataGridFilterType.TEXT;
        if (!(filtersReformat[key] instanceof Array)) {
          filtersReformat[key] = [filtersReformat[key]];
        }
        if (!filterModel) {
          filterModel = {};
        }
        if (filterModel[key]) {
          /*cas des Ranges */
          if (fieldsFromForm[key] && fieldsFromForm[key].data.isRange) {
            const filterFrom = filtersReformat[key][0];
            const filterTo = filtersReformat[key][1]
              ? filtersReformat[key][1]
              : datastore && datastore.data
                ? datastore.data[key + '_To']
                : null;
            let newOperator = OperatorFilterType.BETWEEN;
            let newValues = [filterFrom, filterTo];

            if (!filterFrom && filterTo) {
              newOperator = OperatorFilterType.LESSTHANOREQUAL;
              newValues = [filterTo];
            } else if (filterFrom && !filterTo) {
              newOperator = OperatorFilterType.BIGGERTHANOREQUAL;
              newValues = [filterFrom];
            }

            if (filterModel[key].operators.findIndex((operator) => operator === newOperator) !== -1) {
              // Même opérateur ==> on remplace la valeur existante par la nouvelle si elle est différente
              for (let i = 0; i < newValues.length; i++) {
                let filtersReformatValue = newValues[i];
                if (fieldsFromForm[key].referenceId === 15) {
                  filtersReformatValue =
                    moment(filtersReformatValue).format('YYYY-MM-DDTHH:mm:ss.SSS').substring(0, 26) +
                    '' +
                    moment(filtersReformatValue)
                      .format('YYYY-MM-DDTHH:mm:ss.SSS')
                      .substring(27, moment(filtersReformatValue).format('YYYY-MM-DDTHH:mm:ss.SSS').length);
                }
                const idFromFormTmp =
                  filtersReformatValue instanceof Object ? filtersReformatValue.id : filtersReformatValue;
                if (filterModel[key].values[i] != idFromFormTmp) {
                  // Dans le cas d'un autocomplete, la ou les valeurs doivent être dans un array
                  filterModel[key].values[i] =
                    filterType === CompiereDataGridFilterType.SET ? filtersReformatValue : idFromFormTmp;
                  filterModelChanged = true;
                }
              }
            } else {
              // Changement d'opérateur
              filterModel[key].values = newValues;
              filterModel[key].operators =
                newOperator === OperatorFilterType.BETWEEN ? [newOperator, newOperator] : [newOperator];
              filterModelChanged = true;
            }
          } else {
            const foundOperatorIndex = filterModel[key].operators.findIndex((operator) =>
              filterType === CompiereDataGridFilterType.TEXT
                ? operator === OperatorFilterType.CONTAINS
                : operator === OperatorFilterType.EQUALS
            );
            /*on ajoute un operateur equals car inexistant */
            if (foundOperatorIndex === -1) {
              filterModelChanged = true;
              filterModel[key].operators.push(OperatorFilterType.EQUALS);
              filterModel[key].values.push(filtersReformat[key]);
            } else {
              /*on remplace la valeur existante par la nouvelle si elle est différente */
              for (const id of filtersReformat[key]) {
                const idFromFormTmp = id instanceof Object ? id.id : id;
                if (
                  filterModel[key].values[foundOperatorIndex] != idFromFormTmp &&
                  (!(filterModel[key].values[foundOperatorIndex] instanceof Array) ||
                    filterModel[key].values[foundOperatorIndex].find((value) => {
                      const idTmp = value instanceof Object ? value.id : value;
                      if (idTmp == idFromFormTmp) {
                        return true;
                      }
                    }) === undefined)
                ) {
                  // Dans le cas d'un autocomplete, la ou les valeurs doivent être dans un array
                  filterModel[key].values[foundOperatorIndex] =
                    filterType === CompiereDataGridFilterType.SET ? filtersReformat[key] : filtersReformat[key][0];
                  filterModelChanged = true;
                }
              }
            }
          }
        } else {
          /*on ajoute le filtre qui n'existe pas encore */
          filterModelChanged = true;
          if (fieldsFromForm[key] && fieldsFromForm[key].data.isRange) {
            const filterFrom = filtersReformat[key][0];
            const filterTo = filtersReformat[key][1];

            if (!filterFrom && filterTo) {
              filterModel[key] = {
                filterType: filterType,
                values: [filterTo],
                operators: [OperatorFilterType.LESSTHANOREQUAL],
              };
            } else if (filterFrom && !filterTo) {
              filterModel[key] = {
                filterType: filterType,
                values: [filterFrom],
                operators: [OperatorFilterType.BIGGERTHANOREQUAL],
              };
            } else if (filterFrom && filterTo) {
              // Dans le cas d'un range
              filterModel[key] = {
                filterType: filterType,
                values: filtersReformat[key],
                operators: [OperatorFilterType.BETWEEN, OperatorFilterType.BETWEEN],
              };
            }
          } else {
            filterModel[key] = {
              filterType: filterType,
              values: [filterType === CompiereDataGridFilterType.SET ? filtersReformat[key] : filtersReformat[key][0]],
              operators: [
                filterType === CompiereDataGridFilterType.TEXT
                  ? OperatorFilterType.CONTAINS
                  : OperatorFilterType.EQUALS,
              ],
            };
          }
        }
      }
    } else {
      /*On supprime les filtres de la form vides */
      for (const key of Object.keys(fieldsFromForm)) {
        if (fieldsFromForm[key] && fieldsFromForm[key].data.isFilterSearch === true) {
          if (!datastore || datastore.data[key] === undefined || datastore.data[key] === null) {
            if (filterModel[key]) {
              filterModelChanged = true;
            }
            delete filterModel[key];
          }
          if (datastore && datastore.data[key] !== undefined && datastore.data[key] !== null) {
            filtersReformat[key] = datastore.data[key];
            if (!filterModel[key]) {
              filterModelChanged = true;
            }
          }
        }
      }
    }
    const ignoredFilter =
      gridView && gridView.fromForm && gridView.container
        ? gridView.container.gridExcludedFilterValidation.get(gridView.data.columnName)
        : null;
    // suppression des filtres ignorés par la spécific window
    if (ignoredFilter) {
      for (const fieldColumnName of ignoredFilter) {
        if (filterModel[fieldColumnName] || filtersReformat[fieldColumnName]) {
          delete filterModel[fieldColumnName];
          delete filtersReformat[fieldColumnName];
          filterModelChanged = true;
        }
      }
    }
    if (filterModelChanged) {
      /* en cas de modification du filterModel,il faut remettre le filtermodel sous forme id displayvalue pour le passer à l'universalfilter */
      for (const key of Object.keys(filtersReformat)) {
        // On vérifie le type de champ pour formater la value à indiquer dans le filterModel
        const filterType = fieldsFromForm[key]
          ? UICreatorUtils.getFilterTypeFromReference(fieldsFromForm[key]['referenceId'])
          : typeof filtersReformat[key] === 'number'
            ? key.includes('_ID')
              ? CompiereDataGridFilterType.SET
              : CompiereDataGridFilterType.NUMBER
            : CompiereDataGridFilterType.TEXT;
        if (filterModel[key]) {
          if (fieldsFromForm[key] && fieldsFromForm[key].data.isRange) {
            // Dans le cas d'un range
            filterModel[key].values = filtersReformat[key].filter((el) => el);
          } else {
            const foundOperatorIndex = filterModel[key].operators.findIndex((operator) =>
              filterType === CompiereDataGridFilterType.TEXT
                ? operator === OperatorFilterType.CONTAINS
                : operator === OperatorFilterType.EQUALS
            );
            filterModel[key].values[foundOperatorIndex] =
              filterType === CompiereDataGridFilterType.SET ? filtersReformat[key] : filtersReformat[key][0];
          }
        }
      }

      // synchronisation du filter model construit à partir de la query pour le mettre dans les applied items de l'api
      const appliedItems: AppliedItem<'filter'>[] = [];
      for (const col in filterModel) {
        appliedItems.push({
          ...filterModel[col],
          type: 'filter',
          colId: col,
          filterType: filterModel[col].filterType,
          operators: filterModel[col].operators,
          values: filterModel[col].values,
        });
      }
      this.api.updateAppliedItems(appliedItems, AppliedItemType.FILTER);
      dataStoreRequest.compiereRequest.filterModel = filterModel;
    }
    return forced ? true : filterModelChanged;
  }

  getRows(
    dataStoreRequest: DataStoreRequest,
    params: DataSourceGetDataParams,
    isNewRecord: boolean,
    indexEditViewToEdit: number,
    recordChanged: boolean,
    callBack = null
  ): void {
    Global.startPerf(this, 'GetRows');

    this.isGetRowsInProgress = true;
    const forced = this.forceCallWs;
    this.forceCallWs = true;
    let key = JSON.stringify(
      this.#store.generateDataStoreKey(
        dataStoreRequest.windowId,
        dataStoreRequest.compiereRequest.entityId,
        dataStoreRequest.record_id,
        dataStoreRequest.parent_constraint
      )
    );
    if (dataStoreRequest.compiereRequest.groupKeys.length > 0) key += dataStoreRequest.compiereRequest.groupKeys;
    if (this.subscriptionsGetRows.has(key)) this.subscriptionsGetRows.get(key)?.unsubscribe();

    if (dataStoreRequest.compiereRequest.groupKeys?.length == 0)
      this.currentStateRequest = dataStoreRequest.compiereRequest;

    this.subscriptionsGetRows.set(
      key,
      this.#store.getDataGrid(dataStoreRequest, forced).subscribe({
        next: (result) => {
          this.isGetRowsInProgress = false;
          if (result.compiereRequest.groupKeys?.length === 0) this.currentStateRequest = result.compiereRequest;
          if (result.compiereRequest.rowGroupCols.length > 0) {
            if (
              result.compiereRequest.groupKeys.length === 0 ||
              result.compiereRequest.groupKeys.length !== result.compiereRequest.rowGroupCols.length
            ) {
              for (const data of result.data) {
                for (const g of result.compiereRequest.rowGroupCols) {
                  if (data[g.id] === null || data[g.id] === undefined) {
                    data[g.id] = '';
                  }
                }
              }
            }
          }

          if (result) {
            this.subscriptions.push(
              this.fillPrecisionCache(result.data).subscribe(() => {
                if (this.isSearch) {
                  const data = result.data ? result.data : [];
                  if (this.grid) {
                    if (result.lastRow > -1 && params.request.startRow === 0 && this.tempDataItems.length > 0) {
                      result.lastRow = result.lastRow + this.tempDataItems.length;
                    }

                    params.success({
                      data: this.simplifyData(result.data, params.request.startRow === 0),
                      rowCount: result.lastRow,
                    });

                    if (data && data.length > 0 && this.data.shouldSelectFirst && this.grid.api.getRowAtIndex(0)) {
                      this.grid.api.getRowAtIndex(0).setSelected(true);
                    }
                    this.columnResize();
                  }

                  if (dataStoreRequest.compiereRequest.startRow + result.data.length > 0) {
                    if (recordChanged) {
                      this.selectFirstLine(result.data[0]);
                      recordChanged = false;
                    } else {
                      this.selectLastLineSelected(this.shouldChangePage || !recordChanged);
                    }
                  }

                  // creation d'une ligne de total pour les colonnes spécifiées
                  if (this.pinnedColKeys && dataStoreRequest.compiereRequest.groupKeys.length == 0) {
                    this.createPinnedSumRow(this.grid, this.pinnedColKeys, (response) => {
                      this.pinnedBottomRow = response;
                      this.grid.api.setPinnedRowData(this.pinnedBottomRow);
                    });
                  }
                } else {
                  params.success({
                    data: this.simplifyData(result.data),
                    rowCount: result.lastRow,
                  });

                  if (!this.isTabTopLevel && !this.overridedTableHeight) {
                    if (this.DOMParentComponent) (<GridViewUiComponent>this.DOMParentComponent).openRemainingEditView();
                  }
                  if (!isNewRecord && indexEditViewToEdit) {
                    if (dataStoreRequest.compiereRequest.startRow + result.data.length > 0) {
                      if (recordChanged) {
                        this.selectFirstLine(result.data[0]);
                        recordChanged = false;
                      } else {
                        this.selectLastLineSelected(this.shouldChangePage || !recordChanged);
                      }
                    } else if (this.currentSelectIndex >= 0) {
                      this.closeLinkedEditview();
                      this.currentSelectIndex = undefined;
                    }
                  } else {
                    this.selectLastLineSelected(this.shouldChangePage);
                  }
                  this.columnResize();
                }
              })
            );
          } else {
            // force to hide overlay when no data
            params.success({ data: [], rowCount: 0 });
          }
          this.gridRefreshed.emit(dataStoreRequest);
          if (callBack) {
            callBack();
          }
          if (this.subscriptionsGetRows.has(key)) {
            this.subscriptionsGetRows.get(key)?.unsubscribe();
          }
        },
        error: () => {
          this.isGetRowsInProgress = false;
          if (callBack) {
            callBack();
          }
          if (this.subscriptionsGetRows.has(key)) {
            this.subscriptionsGetRows.get(key)?.unsubscribe();
          }
          params.success({ data: [], rowCount: 0 });
        },
      })
    );
    Global.endPerf(this, 'GetRows', 'GetRows');
  }

  /**
   * Ferme l'editview liée
   */
  private closeLinkedEditview() {
    const linkedEditView = this.container.DOMChildrenComponent.find((child) => child.tabId === this.tabId);
    if (!isNil(linkedEditView)) {
      linkedEditView.removeComponent();
    }
  }

  public updateColumnState() {
    if (!this.grid) {
      return;
    }
    const grid = this.DOMParentComponent;
    const type = grid?.isGridCollapsed ? GridPreferenceType.REDUCED : GridPreferenceType.EXPANDED;
    if (type === GridPreferenceType.EXPANDED) {
      this.expandedColumnState = this.grid.columnApi.getColumnStates();
    } else {
      this.reducedColumnState = this.grid.columnApi.getColumnStates();
    }
    if (grid && (<GridViewUiComponent>grid).isTabTopLevel && grid?.container?.updateGridBlade) {
      grid.container.updateGridBlade(grid);
    }
    if (this.columnStateChanged) {
      this.columnStateChanged.emit(
        type === GridPreferenceType.EXPANDED ? this.expandedColumnState : this.reducedColumnState
      );
    }
  }

  onGridReady(params: ApizGridEvent<GridReadyEvent>) {
    Global.startPerf(this, 'onGridReady');
    this.#applyInitRequest();
    this.subscriptions.push(
      this.getInfoWindowDefaultValue()
        .pipe(switchMap(() => this.loadPreference(true)))
        .subscribe()
    );
    this.setListeners(params.data.api);
    Global.endPerf(this, 'onGridReady');
  }

  setListeners(api: GridApiService) {
    for (const event of [
      'columnGroupOpened',
      'columnMoved',
      'columnRowGroupChanged',
      'columnValueChanged',
      'columnVisible',
      'sortChanged',
    ] as ApizEventType[]) {
      api.addEventListener(event, () => this.updateColumnState());
    }
  }

  getInfoWindowDefaultValue() {
    Global.startPerf(this, 'getInfoWindowDefaultValue');

    if (this.windowType === IupicsMenuType.INFO_WINDOW && this.data.details.entityID) {
      return this.#store.getInfoWindowDefaultValues(this.data.details.entityID, this.getCurrentContext()).pipe(
        switchMap((defaultValues) => {
          let defaultValueApplied = false;
          // this.filterModel ||= {};
          if (defaultValues && this.data.columnsDetails) {
            const filters = [];
            for (const dv of defaultValues) {
              const columnDetail = (this.data.columnsDetails as Map<string, any>).get(dv.columnname);
              if (columnDetail) {
                // TODO - AFTER_MIGRATION_APIZ_GRID: vérifier ceci avec apiz-grid
                // pour les info window, on est obligé d'utiliser columnDetail.ColumnName comme colId malgré que ça ne soit pas le colId défini par la table
                filters.push({
                  type: 'filter',
                  filterType: UICreatorUtils.getFilterTypeFromReference(columnDetail.field.field.AD_Reference_ID),
                  operators: [OperatorFilterType.EQUALS],
                  values: [dv.defaultValue],
                  colId: columnDetail.ColumnName,
                });
              }
            }

            if (filters.length > 0) {
              this.grid.api.updateAppliedItems(filters, 'filter');
            }

            defaultValueApplied = true;
          }

          Global.endPerf(this, 'getInfoWindowDefaultValue');
          return of(defaultValueApplied);
        })
      );
    }

    Global.endPerf(this, 'getInfoWindowDefaultValue');
    return of(false);
  }

  loadPreference(isInit = false): Observable<boolean> {
    Global.startPerf(this, 'loadPreference');
    const grid = this.DOMParentComponent;
    const type = grid?.isGridCollapsed ? GridPreferenceType.REDUCED : GridPreferenceType.EXPANDED;
    const newGridPreference: GridPreference = {
      userGridPreferenceID: -1,
      tableID: this.data?.details?.entityID,
      tabID: this.data?.AD_Tab_ID,
      windowID: this.data?.AD_Window_ID,
      formDetailID: this.data?.AD_FormDetail_ID,
      gridState: JSON.stringify(this.grid.columnApi.getColumnStates()),
      name: '',
    };

    return (
      this.isZoomTargetGrid
        ? of(null)
        : this.#gridPreferencesService.getGridPreference(this.gridPreference ?? newGridPreference)
    ).pipe(
      map((gridPreferenceResponse) => {
        if (gridPreferenceResponse) {
          if (!this.gridPreference) {
            isInit = true;
          }

          this.gridPreference = gridPreferenceResponse;
          if (isInit) {
            if (<GridViewUiComponent>this.DOMParentComponent) {
              this.DOMParentComponent.gridPrefLoaded.emit(this);
            } else {
              this.gridPrefLoaded.emit(this);
            }
          }
        } else {
          this.gridPreference = newGridPreference;
        }

        Global.endPerf(this, 'loadPreference');
        return this.loadGridPreferenceResponse(type, isInit);
      }),
      catchError(() => of(false))
    );
  }

  getColumnApi() {
    return this.grid?.columnApi ?? null;
  }

  loadGridPreferenceResponse(type: GridPreferenceType, isInit = false, isFromUF = false) {
    let loaded = false;
    let columnState: ColumnState[];
    let columnStateReduced: ColumnState[];

    if (isInit && this.gridPreference && this.gridPreference.userGridPreferenceID > 0) {
      columnState = JSON.parse(this.gridPreference.gridState);
      columnStateReduced = JSON.parse(this.gridPreference.gridStateReduced);
      const gridRequest: CompiereDataGridRequestJSON = JSON.parse(this.gridPreference.gridRequest);
      const request: CompiereDataGridRequestJSON = this.api.getRequest();
      if (!isNil(request) && !this.isRequestEmpty(request)) {
        Object.assign(gridRequest, request);
      }
      this.gridPreference.gridRequest = JSON.stringify(gridRequest);
      loaded = true;
      const appliedItems = ApizGridUtils.appliedItemsFromCompiereRequest(gridRequest, this.api, this.columnApi);
      this.api.updateAppliedItems(appliedItems, undefined);
    }

    if (loaded) {
      this.expandedColumnState = columnState;
      this.reducedColumnState = columnStateReduced;
      this.applyColumnState(type === GridPreferenceType.EXPANDED ? columnState : columnStateReduced, type);
    } else {
      this.applyColumnState(
        type === GridPreferenceType.EXPANDED ? this.expandedColumnState : this.reducedColumnState,
        type
      );
      loaded = true;
    }

    if (isInit && !this.dataSourceCreated) {
      this.createServerDataSource();
    }

    return loaded;
  }

  private isRequestEmpty(request: CompiereDataGridRequestJSON): boolean {
    return (
      !request ||
      ((isNil(request.filterModel) || Object.keys(request.filterModel).length === 0) &&
        (isNil(request.sortModel) || request.sortModel.length === 0) &&
        (isNil(request.rowGroupCols) || request.rowGroupCols.length === 0))
    );
  }

  applyColumnState(columnStates: ColumnState[], type: GridPreferenceType = null): void {
    if (columnStates) {
      this.grid.columnApi.applyColumnState({ state: columnStates, applyOrder: true });
    } else if (type === GridPreferenceType.EXPANDED) {
      this.grid.columnApi.resetColumnState();
    } else {
      this.onlyOneColumn(false);
    }
  }

  onPageChange() {
    this.currentSelectPageIndex = this.grid.api.getCurrentPage();
  }

  private getConstant(prop: string) {
    const maxNbLines = this.data?.['maxNbLines'] ?? GridTabInfinityScrollConstant.DEFAULT_MAXIMUM_NUMBER_LINES;
    const extraHeight = this.pinnedColKeys && this.pinnedColKeys.length > 0 ? 127 : 100;
    switch (prop) {
      case 'DEFAULT_MAX_TABLE_HEIGHT':
        return maxNbLines * GridTabInfinityScrollConstant.ROW_PAGINATOR_HEIGHT + extraHeight + 'px';
      case 'DEFAULT_CALCULE_TABLE_HEIGHT':
        return maxNbLines * GridTabInfinityScrollConstant.ROW_PAGINATOR_HEIGHT + extraHeight;
      case 'DEFAULT_MAXIMUM_NUMBER_LINES':
        return maxNbLines;

      default:
        break;
    }
  }

  private getValueByColDef(colDef: ColDef, value: any, data: any) {
    const fieldInfo = this.columnDatas.find((cd) => cd.data.ColumnName === colDef.field);
    const componentName = fieldInfo
      ? UICreatorUtils.getComponentNameFromReference(fieldInfo.data.AD_Reference_ID)
      : null;

    const displayValueFound = this.getDisplayValue(colDef.field, value);
    if (displayValueFound) {
      return displayValueFound;
    }

    if (colDef.field === 'Data_UUID') {
      return '';
    }

    if (value?.displayValue) {
      return value.displayValue;
    }

    if (value && value.displayValue === null) {
      return '';
    }

    /**
     * @start_custo_code
     */
    if (/^[0-9]{4}[-][0-9]{2}[-][0-9]{2}[T][0]{2}[:][0]{2}[:][0]{2}/.test(value)) {
      // Test si date ==> parse pour la locale
      return moment(value).format('L').slice(0, 10);
    } else if (/^[0-9]{4}[-][0-9]{2}[-][0-9]{2}[T]/.test(value)) {
      return moment(value).format('L').slice(0, 10) + ' ' + moment(value).format('LTS');
    }
    /**
     * @end_custo_code
     */

    if (!isNil(value)) {
      if (
        componentName === 'CalendarUiComponent' &&
        (isNil(colDef.cellEditor) || colDef.cellEditor !== 'calendarEditor') &&
        value
      ) {
        // Test si date ==> parse pour la locale
        if (fieldInfo.data.AD_Reference_ID === 15 /*Date*/) {
          return moment(value).format('L').slice(0, 10);
        } else {
          return moment(value).format('L LT').slice(0, 16);
        }
      } else if (componentName === 'InputNumberUiComponent') {
        return this.formatNumberPrecision(value, fieldInfo, data);
      } else {
        return value;
      }
    }

    return '';
  }

  private columnFormatter(params: ValueFormatterParams) {
    const value = params?.value ?? params?.data[params.colDef.field];
    return this.getValueByColDef(params.colDef, value, params.data);
  }

  private fillPrecisionCache(rowData: any[]): Observable<any> {
    const precisionRequests: CompierePrecisionData[] = [];
    const currentCtx = this.getCurrentContext();
    for (const row of rowData) {
      for (const cacheDataToInit of this.#getDataToCacheInit(row, currentCtx)) {
        let tableName = cacheDataToInit.tableName;
        let id = cacheDataToInit.id;
        if (id && id.id !== undefined) {
          id = id.id;
        }
        id = parseInt(id, 10);
        if (
          id &&
          !isNaN(id) &&
          precisionRequests.findIndex((pr) => pr.tableName === tableName && pr.id === id) === -1
        ) {
          precisionRequests.push({ tableName: tableName, id });
        }
      }
    }

    if (precisionRequests.length > 0) {
      return this.#cacheService.getPrecisionDatas(precisionRequests);
    } else {
      return of([]);
    }
  }

  #getDataToCacheInit(row: any, currentCtx: any) {
    const cacheDataToInits = [];

    if (row['C_UOM_ID']) {
      cacheDataToInits.push({ tableName: 'C_UOM', id: row['C_UOM_ID'] });
    }
    if (row['M_PriceList_Version_ID']) {
      cacheDataToInits.push({ tableName: 'M_PriceList_Version', id: row['M_PriceList_Version_ID'] });
    }
    if (row['M_PriceList_ID']) {
      cacheDataToInits.push({ tableName: 'M_PriceList', id: row['M_PriceList_ID'] });
    }
    if (row['C_Currency_ID']) {
      cacheDataToInits.push({ tableName: 'C_Currency', id: row['C_Currency_ID'] });
    }
    if (row['C_AcctSchema_ID']) {
      cacheDataToInits.push({ tableName: 'C_AcctSchema', id: row['C_AcctSchema_ID'] });
    }
    if (currentCtx['$C_Currency_ID']) {
      cacheDataToInits.push({ tableName: 'C_Currency', id: currentCtx['$C_Currency_ID'] });
    }
    if (currentCtx['$C_AcctSchema_ID']) {
      cacheDataToInits.push({ tableName: 'C_AcctSchema', id: currentCtx['$C_AcctSchema_ID'] });
    }

    return cacheDataToInits;
  }

  private getAmountBasePrecision(colInfo: any, currentCtx: any) {
    //150061
    if (colInfo.data.AD_Reference_ID === 12 || colInfo.data.AD_Reference_ID === 37) {
      return this.getPrecision(colInfo, currentCtx, true);
    } else {
      return null;
    }
  }

  private getPrecision(colInfo: any, currentCtx: any, amountBasePrecision: boolean = false) {
    let tableName = null;
    let id = null;
    let precision = 0;
    const componentName = colInfo ? UICreatorUtils.getComponentNameFromReference(colInfo?.data.AD_Reference_ID) : null;
    const numberType = this.#getNumberType(colInfo?.data.AD_Reference_ID);
    if (numberType === NumberType.QUANTITY && currentCtx['C_UOM_ID']) {
      tableName = 'C_UOM';
      id = this.extractId(currentCtx['C_UOM_ID'], colInfo, componentName);
    } else if (numberType === NumberType.AMOUNT) {
      if (currentCtx['M_PriceList_Version_ID'] && !amountBasePrecision) {
        tableName = 'M_PriceList_Version';
        id = this.extractId(currentCtx['M_PriceList_Version_ID'], colInfo, componentName);
      } else if (currentCtx['M_PriceList_ID'] && !amountBasePrecision) {
        tableName = 'M_PriceList';
        id = this.extractId(currentCtx['M_PriceList_ID'], colInfo, componentName);
      } else if (currentCtx['C_Currency_ID']) {
        tableName = 'C_Currency';
        id = this.extractId(currentCtx['C_Currency_ID'], colInfo, componentName);
      } else if (currentCtx['C_AcctSchema_ID']) {
        tableName = 'C_AcctSchema';
        id = this.extractId(currentCtx['C_AcctSchema_ID'], colInfo, componentName);
      } else if (currentCtx['$C_Currency_ID']) {
        tableName = 'C_Currency';
        id = this.extractId(currentCtx['$C_Currency_ID'], colInfo, componentName);
      } else if (currentCtx['$C_AcctSchema_ID']) {
        tableName = 'C_AcctSchema';
        id = this.extractId(currentCtx['$C_AcctSchema_ID'], colInfo, componentName);
      } else if (currentCtx['#StdPrecision']) {
        precision = currentCtx['#StdPrecision'];
      }
    }
    if (tableName !== null && id > 0) {
      let cacheValue = this.#cacheService.getCacheValueById(
        CacheName.PRECISION_CURRENCY,
        this.#cacheService.generatePrecisionDataId(tableName, id)
      );
      return cacheValue ? cacheValue.stdPrecision : currentCtx['#StdPrecision'];
    } else {
      return precision;
    }
  }

  #getNumberType(AD_Reference_ID: number) {
    switch (AD_Reference_ID) {
      case 12:
      case 37:
        return NumberType.AMOUNT;
      case 11:
        return NumberType.INTEGER;
      case 22:
        return NumberType.FLOAT;
      case 29:
        return NumberType.QUANTITY;
      default:
        return null;
    }
  }

  private extractId(valueToExtract: any, colInfo: any, componentName: string): any {
    let value = cloneDeep(valueToExtract);
    if (value && value.id !== undefined) {
      value = value.id;
    }
    let id = value;
    if (
      componentName === 'AutocompleteUiComponent' &&
      (!colInfo.details || colInfo.details.tableName !== 'AD_Ref_List')
    ) {
      if (value !== undefined && value !== null && /^[-+]?(\d+|Infinity)$/.test(value)) {
        id = parseInt(value, 10);
      }
    }
    return id;
  }

  private formatNumberPrecision(value: number, fieldInfo: any, params: any) {
    const parentData: IupicsData = params.data;
    const ctx = EditViewUtils.mergeCurrentDataDeepCopy(this.getCurrentContext(), parentData, true, true);
    const precision = this.getPrecision(fieldInfo, ctx);
    const amountBasePrecision = this.getAmountBasePrecision(fieldInfo, ctx); //150061
    let numberFormat = precision
      ? `1.${amountBasePrecision && precision > amountBasePrecision ? amountBasePrecision : precision}-${precision}`
      : ''; //150061
    return value != null || value != undefined
      ? formatNumber(value, this.#connectorService.getIupicsDefaultLanguage().iso_code.replace('_', '-'), numberFormat)
      : value;
  }
}

/*
 Pas de provide ici pour profiter du provide défini dans grid-view
 */
@Component({
  selector: 'iu-grid-tab-infinity-scroll-headless-ui',
  templateUrl,
  styleUrls,
  encapsulation,
  standalone,
  imports,
})
export class GridTabInfinityScrollHeadlessUiComponent extends GridTabInfinityScrollUiComponent {
  protected headless: boolean = true;
}
