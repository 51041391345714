import {
  CdkDrag,
  CdkDragDrop,
  CdkDragHandle,
  CdkDragPlaceholder,
  CdkDragPreview,
  CdkDropList,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { AsyncPipe, NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import {
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
  ViewEncapsulation,
  forwardRef,
  inject,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  CompiereDataFieldType,
  CompiereDataGridFilterModel,
  CompiereDataGridFilterType,
  CompiereDataGridRequestJSON,
  CompiereDataGridSortModelType,
  DataStore,
  DataStoreStatus,
} from '@compiere-ws/models/compiere-data-json';
import {
  ColumnFilterAutocomplete,
  OperatorFilterAutocomplete,
} from '@iupics-components/models/autocomplete-interfaces';
import { FilterOperator, OperatorFilterType, filterOperators } from '@iupics-components/models/universal-filter';
import { ViewType } from '@iupics-components/models/view-type.enum';
import { CalendarConfig } from '@iupics-components/overrided/prime-calendar/prime-calendar.component';
import { OperatorFilterPipe } from '@iupics-components/pipes/operator-filter/operator-filter.pipe';
import AutocompleteUiComponent from '@iupics-components/standard/fields/autocomplete-ui/autocomplete-ui.component';
import { FilterCtxService } from '@iupics-components/standard/grid/filters/services/filter-ctx.service';
import ModalUiComponent from '@iupics-components/standard/layouts/modal-ui/modal-ui.component';
import { AppConfig } from '@iupics-config/app.config';
import { DataStoreService } from '@iupics-manager/managers/data-store/data-store.service';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { SecurityManagerService } from '@iupics-manager/managers/security-manager/security-manager.service';
import { UICreatorService } from '@iupics-manager/managers/ui-creator/ui-creator.service';
import { UICreatorUtils } from '@iupics-manager/managers/ui-creator/utils/ui-creator.utils';
import { AbstractDataContainer } from '@iupics-manager/models/abstract-datacontainer';
import { AbstractDynamicComponent } from '@iupics-manager/models/abstract-dynamic-component';
import { IupicsData, NumberType } from '@iupics-manager/models/iupics-data';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { createComponent } from '@iupics-util/tools/component-cache-loader';
import { SubscriptionsUtils } from '@iupics-util/tools/subscriptions.util';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { IupicsMenuType } from '@web-desktop/models/menu-item-ui';
import { clone, cloneDeep, has, isNil } from 'lodash';
import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { OverlayPanel } from 'primeng/overlaypanel';
import { TooltipModule } from 'primeng/tooltip';
import { Observable, catchError, map, of, tap, zip } from 'rxjs';
import { v4 as uuid } from 'uuid';
import PrimeChipsComponent from '../../../overrided/prime-chips/prime-chips.component';
import PrimeOverlayComponent from '../../../overrided/prime-overlay/prime-overlay.component';
import { AutocompleteSuggestionsFilterPipe } from '../../../pipes/autocomplete-suggestions-filter/autocomplete-suggestions-filter.pipe';
import { ColumnGroupFilterPipe } from '../../../pipes/column-group-filter/column-group-filter.pipe';
import { ToDatePipe } from '../../../pipes/to-date/to-date.pipe';
import { UniversalFilterColFilterTypePipe } from '../../../pipes/universal-filter-col-filter-type/universal-filter-col-filter-type.pipe';
import CalendarUiComponent from '../../fields/calendar-ui/calendar-ui.component';
import InputNumberUiComponent from '../../fields/input-number-ui/input-number-ui.component';
import InputTimeUiComponent from '../../fields/input-time-ui/input-time-ui.component';
import { DataToDisplay, FilterChip, FilterToDisplay, UniversalFilterUtils } from '../utils/universal-filter-utils';

@Component({
  selector: 'iu-universal-filter-standalone-ui',
  templateUrl: './universal-filter-standalone-ui.component.html',
  styleUrls: ['./universal-filter-standalone-ui.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgTemplateOutlet,
    PrimeChipsComponent,
    FormsModule,
    SharedModule,
    NgClass,
    ButtonModule,
    TooltipModule,
    PrimeOverlayComponent,
    NgStyle,
    CdkDropList,
    CdkDrag,
    CdkDragPreview,
    CdkDragPlaceholder,
    CdkDragHandle,
    forwardRef(() => AutocompleteUiComponent),
    InputNumberUiComponent,
    CalendarUiComponent,
    InputTimeUiComponent,
    AsyncPipe,
    TranslateModule,
    OperatorFilterPipe,
    UniversalFilterColFilterTypePipe,
    AutocompleteSuggestionsFilterPipe,
    ColumnGroupFilterPipe,
    ToDatePipe,
    ModalUiComponent,
  ],
})
export default class UniversalFilterStandaloneUiComponent implements OnInit, OnDestroy {
  //#region DI
  #renderer = inject(Renderer2);
  #messageManager = inject(MessageManagerService);
  #config = inject(AppConfig);
  #translator = inject(TranslateService);
  #connectorService = inject(SecurityManagerService);
  #store = inject(DataStoreService);
  #uiCreator = inject(UICreatorService);
  #ufUtils = inject(UniversalFilterUtils);
  //#endregion

  //#region ViewChild
  @ViewChild('filtersContainer', { read: ElementRef }) filtersContainer: ElementRef;
  @ViewChild('sortsContainer', { read: ElementRef }) sortsContainer: ElementRef;
  @ViewChild('groupsContainer', { read: ElementRef }) groupsContainer: ElementRef;
  @ViewChild('buttonGroupFilter', { read: ElementRef }) buttonGroupFilter: ElementRef;
  @ViewChildren('vcrFilter', { read: ViewContainerRef }) vcrFilters: QueryList<ViewContainerRef>;
  @ViewChild('overlayFilter') overlayFilter: OverlayPanel;
  //#endregion

  @Input() tabId: number;
  _columns: any[];

  @Input()
  set columns(value) {
    let wasInit = this._columns ? true : false;
    this._columns = value;
    if (wasInit) {
      this.#initUniversalFilter();
    }
  }
  get columns() {
    return this._columns;
  }

  @Input() canFilter = true;
  @Input() canSort = true;
  @Input() canGroup = true;
  @Input() showFavorite = true;
  @Input() isMobile = false;
  @Input() widthChipsContainer = 270;
  @Input() hasScroll = true;

  //#region standalone
  @Input() windowType: IupicsMenuType;
  @Output() filterChange = new EventEmitter<{ filterToApply: CompiereDataGridRequestJSON; isNotFromUF: boolean }>();
  @Input() formId: number;
  @Input() setFilterEmitter: EventEmitter<CompiereDataGridRequestJSON>;
  //#endregion

  #columnNames: { items: ColumnFilterAutocomplete[] };
  private columnNames$: Observable<{ items: ColumnFilterAutocomplete[] }>;
  #operators: { items: OperatorFilterAutocomplete[] };
  private operators$: Observable<{ items: OperatorFilterAutocomplete[] }>;
  source$: Observable<{
    columnFilters: { items: ColumnFilterAutocomplete[] };
    operatorFilters: { items: OperatorFilterAutocomplete[] };
  }>;

  // display attributes
  isDisplayGroupFilter = false;
  displayGroupFilterListener: Function;

  // selected favorite filter
  selected: DataToDisplay;

  // default elements for filter
  defaultColumn: ColumnFilterAutocomplete;
  defaultOperator: OperatorFilterAutocomplete;

  // filter chips to be displayed
  filterChips: FilterChip[] = [];

  // override the buildind of a chips
  buildChipsOnSave = this.#buildChipsOnSave.bind(this);
  conditionToAdd = this.#checkToAdd.bind(this);
  tableName = '';

  #subscriptions: any[] = [];
  #selectionColumns: ColumnFilterAutocomplete[] = [];
  #localDatastore: DataStore;
  #initialStateDatastore: DataStore;
  #copylocalDatastoreData: DataStore;
  #copySelected: DataToDisplay;
  #fieldToUpdates = [];
  #parentContext: { [key: string]: any };
  #componentRefs: ComponentRef<any>[] = [];
  #ctxArea: any = {};

  usedFilterColumnNames = [];

  //#region template related
  readonly CompiereDataGridFilterType = CompiereDataGridFilterType;
  readonly ViewType = ViewType;
  //#endregion

  ngOnInit() {
    this.#initUniversalFilter();

    if (this.setFilterEmitter) {
      this.#subscriptions.push(this.setFilterEmitter.subscribe((newFilter: CompiereDataGridRequestJSON) => this.#handleUpdateFilter(newFilter)));
    }
  }

  #initUniversalFilter() {
    this.#initLocalDatastore();
    this.#getColumnFilters();
    this.#getOperatorFilters();
    this.#getSource();
    this.#initData();
  }

  #initLocalDatastore() {
    const windowType = this.windowType;
    switch (windowType) {
      case IupicsMenuType.FORM:
        const formId = this.formId;
        this.#localDatastore = this.#store.newSpecificWindowData(formId);
        break;
      case IupicsMenuType.WINDOW:
        if (this.columns) {
          // build own datastructure to fit this searchpanel columns
          const dataTransformed: DataStore = new DataStore();
          const newStructure = {};
          for (const col of this.columns) {
            if (col.field) newStructure[col.field.ColumnName] = null;
          }
          const generatedTabId = uuid();
          const generatedWindowId = uuid();
          this.#store.addWindowDataStructure(generatedTabId, newStructure);
          const dataStorekey = this.#store.generateDataStoreKey(generatedWindowId, generatedTabId, uuid(), null);
          Object.assign(dataTransformed.data, newStructure);
          dataTransformed.key = dataStorekey;
          dataTransformed.status = DataStoreStatus.NEWRECORD;
          this.#localDatastore = dataTransformed;
        }
        break;
      default:
        this.#localDatastore = new DataStore();
        break;
    }

    // 131192 Avoid to set defaultValue for thoses columns
    this.#localDatastore.data['DocStatus'] = null;
    this.#localDatastore.data['DocAction'] = null;
    this.#initialStateDatastore = cloneDeep(this.#localDatastore);
  }

  ngOnDestroy() {
    SubscriptionsUtils.unsubscribe(...this.#subscriptions);
    for (const cr of this.#componentRefs) cr?.destroy();
  }

  /**
   * Change l'affichage d'une modal selon son nom et ajoute un listener de fermeture selon son id dans l'html
   * @param event
   * @param name
   * @param id
   * @param display
   */
  updatePanelFilter(event: any, name: string, id: string, display: boolean = !this[`isDisplay${name}`]) {
    if (event?.stopPropagation) event.stopPropagation();
    this[`isDisplay${name}`] = display;
    if (this[`isDisplay${name}`] === true) {
      // keep a copy of initial chips and store
      if (!this.#localDatastore) {
        this.#initLocalDatastore();
      }
      if (this.#localDatastore && this.#copylocalDatastoreData) {
        this.#localDatastore.data = cloneDeep(this.#copylocalDatastoreData);
      }
      this.#copylocalDatastoreData = cloneDeep(this.#localDatastore.data);
      this.#copySelected = cloneDeep(this.selected);
      this.#fieldToUpdates = [];
      if (this[`display${name}Listener`] === undefined) {
        this[`display${name}Listener`] = this.#renderer.listen(document.documentElement, 'mousedown', (e) => {
          const path = e.path ? e.path : e.composedPath();
          if (
            path.findIndex((target: any) => target.id === id) < 0 &&
            path.findIndex((target: any) => target.className === 'iu-modal-background') < 0 &&
            path.findIndex((target: any) => target.className?.includes('p-overlaypanel')) < 0 &&
            path.findIndex((target: any) => target?.className?.includes('p-autocomplete-panel')) < 0 &&
            path.findIndex((target: any) => target?.className?.includes('p-datepicker')) < 0
          ) {
            // reset chips and store if closed without applying
            if (this.selected && this.#copySelected) {
              this.selected = cloneDeep(this.#copySelected);
            }
            this.updateChips();

            if (name === 'GroupFilter' && this.isDisplayGroupFilter) {
              this.overlayFilter.hide();
              this.overlayFilter.cd.markForCheck();
            }

            this[`isDisplay${name}`] = false;
            this[`display${name}Listener`]();
            this[`display${name}Listener`] = undefined;
          }
        });
      }
      if (name === 'GroupFilter') {
        this.handleViewChange();
        if (this.#selectionColumns.length) {
          for (const sc of this.#selectionColumns) {
            const index =
              this.selected.filters.length > 0
                ? this.selected.filters.findIndex((f) =>
                    f.column.id !== -1 ? f.column.columnInfo.fieldEntity.field.ColumnName === sc.id : false
                  )
                : -1;
            if (index === -1) {
              this.selected.filters = this.selected.filters.filter((f) => f.column.id !== -1);
              this.addFilter(document.createEvent('Event'));
              this.setColumnFilter(sc, this.selected.filters.length - 1, false);
            }
          }
        }
        setTimeout(() => {
          this.#componentRefs = [];
          this.#checkVCR();
          this.overlayFilter.show(event, this.buttonGroupFilter.nativeElement);
        }, 50);
      }
    } else {
      if (name === 'GroupFilter') {
        this.overlayFilter.hide();
      }
      if (this[`display${name}Listener`] !== undefined) {
        this[`display${name}Listener`]();
        this[`display${name}Listener`] = undefined;
      }
    }
  }

  setColumnFilter(value: ColumnFilterAutocomplete, index: number, fromHTML = true) {
    if (value) {
      this.selected.filters[index].column = value;
      if (!this.#localDatastore) {
        this.#initLocalDatastore();
      }

      const filter =
        has(this.#localDatastore.data, value.id) && !isNil(this.#localDatastore.data[value.id])
          ? this.#localDatastore.data[value.id]
          : undefined;
      const filterTo =
        has(this.#localDatastore.data, value.id + '_To') && !isNil(this.#localDatastore.data[value.id + '_To'])
          ? this.#localDatastore.data[value.id + '_To']
          : undefined;

      this.selected.filters[index].filter = filter;
      this.selected.filters[index].filterTo = filterTo;

      if (fromHTML) {
        this.#setVCR(index);
      }

      const operatorPipe: OperatorFilterPipe = new OperatorFilterPipe();
      const operatorFilters = operatorPipe.transform(
        this.#operators,
        this.selected.filters[index].column.columnInfo.filterType
      );
      this.setOperatorFilter(operatorFilters.items[0], index);
    }
  }

  setOperatorFilter(value: OperatorFilterAutocomplete, index: number) {
    if (value) {
      const previousType = this.selected.filters[index]?.operator?.operator?.type ?? '';
      this.selected.filters[index].operator = value;
      if (!value.operator.isRange) {
        this.selected.filters[index].filterTo = undefined;
      }

      if (value.operator.filterType === CompiereDataGridFilterType.NUMBER) {
        const compoRef = this.#componentRefs[index];
        const isMultiple = value.operator
          ? value.operator.type === OperatorFilterType.EQUALS || value.operator.type === OperatorFilterType.NOT_EQUALS
          : true;
        if (compoRef && compoRef.instance && compoRef.instance.multiple !== isMultiple) {
          const fieldValue = compoRef.instance.fieldValue;
          compoRef.instance.multiple = isMultiple;
          if (fieldValue) {
            compoRef.instance.fieldValue = isMultiple ? [fieldValue] : fieldValue[0];
            this.selected.filters[index].filter = isMultiple
              ? [this.selected.filters[index].filter]
              : this.selected.filters[index].filter[0];
          }
        }
      }

      if (
        (value.operator.type === OperatorFilterType.IS_NULL && previousType !== OperatorFilterType.IS_NULL) ||
        (previousType === OperatorFilterType.IS_NULL && value.operator.type !== OperatorFilterType.IS_NULL) ||
        (value.operator.type === OperatorFilterType.IS_NOT_NULL && previousType !== OperatorFilterType.IS_NOT_NULL) ||
        (previousType === OperatorFilterType.IS_NOT_NULL && value.operator.type !== OperatorFilterType.IS_NOT_NULL)
      ) {
        this.setFilter('', index, index);
        this.#setVCR(index);
      }
    }
    if (this.selected?.filters?.[index]?.column?.id != -1) {
      this.updateChips();
    }
  }

  setFilter(value: any, filterIndex: number, compoRefIndex: number) {
    // init store because grid is completly rendered after universalfilter
    if (!this.#localDatastore) {
      this.#initLocalDatastore();
    }
    if (this.#localDatastore) {
      const dataModified = {};
      dataModified[this.selected.filters[filterIndex].column.id] = value;

      if (value === undefined || value === null || (!(value instanceof Date) && value.length === 0)) {
        value = null;
      }
      this.selected.filters[filterIndex].filter = value;
      this.updateChips();
      const compoRef = this.#componentRefs[compoRefIndex];
      if (compoRef?.instance?.fieldValue) {
        compoRef.instance.fieldValue = value;
      }
      this.#store.syncDataChanges(this.#localDatastore, dataModified, true);
    }
  }

  setFilterTo(value: any, index: number) {
    // init store because grid is completly rendered after universalfilter
    if (!this.#localDatastore) {
      this.#initLocalDatastore();
    }
    if (this.#localDatastore) {
      const dataModified = {};
      dataModified[this.selected.filters[index].column.id + '_To'] = value;

      if (value === undefined || value === null || (!(value instanceof Date) && value.length === 0)) {
        value = null;
      }
      this.selected.filters[index].filterTo = value;
      this.updateChips();
      this.#store.syncDataChanges(this.#localDatastore, dataModified, true);
    }
  }

  setGroup(value: ColumnFilterAutocomplete, index: number) {
    if (value) {
      this.selected.groups[index] = value;
      this.updateChips();
    }
  }

  setSorting(value: ColumnFilterAutocomplete, index: number) {
    if (value) {
      this.selected.sortings[index].column = value;
      this.updateChips();
    }
  }

  addFilter(event?: Event) {
    if (event) {
      event.stopPropagation();
    }
    const emptyFilter: FilterToDisplay = {
      column: { id: -1, displayValue: '' },
      operator: { id: -1, displayValue: '' },
      filter: '',
      filterTo: '',
    };
    this.selected.filters.push(emptyFilter);
    setTimeout(() => {
      if (this.filtersContainer) {
        this.filtersContainer.nativeElement.scrollTop =
          this.filtersContainer.nativeElement.scrollHeight - this.filtersContainer.nativeElement.clientHeight;
      }
    }, 50);
  }

  removeFilter(index: number, event?: Event) {
    if (event) {
      event.stopPropagation();
    }

    if (this.selected.filters?.[index]?.column?.id) {
      // keep track of changed values to reset store later if localdatastore is shared with a specific window
      this.#fieldToUpdates.push(this.selected.filters[index].column.id);
      this.#localDatastore.data[this.selected.filters[index].column.id] = clone(
        this.#initialStateDatastore.data[this.selected.filters[index].column.id]
      );
    }
    this.selected.filters.splice(index, 1);
    this.#componentRefs.splice(index, 1);
    this.updateChips();
  }

  addGroup(event?: Event) {
    if (event) {
      event.stopPropagation();
    }
    const emptyGroup = { id: -1, displayValue: '' };
    this.selected.groups.push(emptyGroup);
    setTimeout(() => {
      if (this.groupsContainer) {
        this.groupsContainer.nativeElement.scrollTop =
          this.groupsContainer.nativeElement.scrollHeight - this.groupsContainer.nativeElement.clientHeight;
      }
    }, 50);
  }

  removeGroup(index: number, event?: Event) {
    if (event) {
      event.stopPropagation();
    }
    this.selected.groups.splice(index, 1);
    this.updateChips();
  }

  addSorting(event?: Event) {
    if (event) {
      event.stopPropagation();
    }
    const emptySorting = {
      column: { id: -1, displayValue: '' },
      sortingType: CompiereDataGridSortModelType.ASC,
    };
    this.selected.sortings.push(emptySorting);

    setTimeout(() => {
      if (this.sortsContainer) {
        this.sortsContainer.nativeElement.scrollTop =
          this.sortsContainer.nativeElement.scrollHeight - this.sortsContainer.nativeElement.clientHeight;
      }
    }, 50);
  }

  removeSorting(index: number, event?: Event) {
    event?.stopPropagation();
    this.selected.sortings.splice(index, 1);
    this.updateChips();
  }

  addFilterByChips(event: Event, value: string) {
    event?.stopPropagation();
    const containFilter = {
      column: this.defaultColumn,
      operator: this.defaultOperator,
      filter: value,
      filterTo: '',
    };
    if (!this.#localDatastore) {
      this.#initLocalDatastore();
    }
    if (this.#localDatastore) {
      const dataModified = {};
      dataModified[containFilter.column.id] = value;

      this.#store.syncDataChanges(this.#localDatastore, dataModified, true);
    }
    if (
      this.selected.filters.findIndex(
        (filter) =>
          filter.column.id === -1 || filter.operator.id === -1 || filter.filter === undefined || filter.filter === ''
      ) < 0
    ) {
      this.selected.filters.push(containFilter);
    } else {
      this.selected.filters[0] = containFilter;
    }

    this.#apply();
  }

  removeFilterByChips(event: Event, value: FilterChip) {
    event?.stopPropagation();
    let shouldApply = true;

    if (this.selected.filters?.[value.index]?.column?.id) {
      // keep track of changed values to reset store later if localdatastore is shared with a specific window
      this.#fieldToUpdates.push(this.selected.filters[value.index].column.id);
      this.#localDatastore.data[this.selected.filters[value.index].column.id] = null;
    }
    this.selected[value.type].splice(value.index, 1);
    // Cette ligne sert à reconstruire les chips pour update les index enregistré dans ceux-ci
    this.updateChips();
    if (shouldApply) {
      this.#apply();
    }
  }

  applyFilter(event: Event) {
    event.stopPropagation();
    this.updateChips();
    this.#apply();
    this.updatePanelFilter(event, 'GroupFilter', 'group-filter-panel', false);
  }

  /**
   *
   * @param event
   * @param type
   */
  moveItem(event: CdkDragDrop<any[]>, type: keyof Pick<DataToDisplay, 'filters' | 'groups' | 'sortings'>) {
    if (this.selected[type]) {
      moveItemInArray(this.selected[type] as any[], event.previousIndex, event.currentIndex);
    }
    if (type === 'filters') {
      moveItemInArray(this.#componentRefs, event.previousIndex, event.currentIndex);
    }
  }

  onAddError() {
    if (this.defaultColumn === undefined || this.defaultColumn === null) {
      this.#messageManager.newMessage(
        new IupicsMessage(
          this.#translator.instant('generic.warning'),
          this.#translator.instant('universalFilter.noDefaultColumn'),
          'warning'
        )
      );
    }
  }

  /*
   * *****************************************************************************
   * *****************************************************************************
   * *****************************************************************************
   * ***************************** PRIVATE METHODS *******************************
   * *****************************************************************************
   * *****************************************************************************
   * *****************************************************************************
   */

  #getColumnFilters() {
    this.columnNames$ =
      !this.tabId || this.tabId === -1
        ? of(this.columns).pipe(
            map((columns) => {
              return {
                items: columns.map<ColumnFilterAutocomplete>((column) => {
                  return {
                    id: column.field.ColumnName,
                    displayValue: column.field.name,
                    columnInfo: {
                      fieldEntity: column,
                      filterType: UICreatorUtils.getFilterTypeFromReference(column.field.AD_Reference_ID),
                    },
                  };
                }),
              };
            }),
            tap((columnInfos) => FilterCtxService.sortColumnInfosItemsByDisplayValue(columnInfos)),
            tap(
              (cfas) =>
                (this.#selectionColumns = cfas.items
                  .filter(
                    ({ columnInfo: { fieldEntity } }) =>
                      [10, 14, 40, 38, 39].includes(fieldEntity.field.AD_Reference_ID) &&
                      (fieldEntity?.field?.isQueryCriteria ?? fieldEntity?.field?.IsSelectionColumn)
                  )
                  .sort(
                    (a, b) =>
                      a.columnInfo.fieldEntity.field.SelectionSeqNo - b.columnInfo.fieldEntity.field.SelectionSeqNo
                  ))
            ),
            tap((_) => (this.defaultColumn = this.#selectionColumns[0])),
            tap((cfas) => (this.#columnNames = cfas)),
            catchError((err) => {
              this.#messageManager.newMessage(new IupicsMessage('Erreur', err, 'error'));
              return [];
            })
          )
        : this.#uiCreator.getColumnInfos(this.tabId, this.windowType === 'Form').pipe(
            map((columnInfos) => ({
              items: columnInfos
                .filter(
                  (ci) =>
                    ((ci.fieldEntity.field.Name !== null &&
                      ci.fieldEntity.field.Name !== undefined &&
                      ci.fieldEntity.field.Name.trim() !== '') ||
                      (ci.fieldEntity.field.name !== null &&
                        ci.fieldEntity.field.name !== undefined &&
                        ci.fieldEntity.field.name.trim() !== '')) &&
                    (ci.fieldEntity.field.AD_Field_ID === -2 ||
                      ci.fieldEntity.field.IsDisplayed ||
                      ci.fieldEntity.field.IsKey)
                )
                .map((ci) => {
                  return {
                    id: ci.fieldEntity.field.ColumnName,
                    displayValue:
                      (ci.fieldEntity.field.AD_Field_ID === -2
                        ? this.#translator.instant('mandatoryColumns.' + ci.fieldEntity.field.ColumnName)
                        : ci.fieldEntity.field?.Name ?? ci.fieldEntity.field?.name) + // 130422
                      (ci.fieldEntity.field.IsKey ? ' (ID)' : ''),
                    columnInfo: ci,
                  };
                }),
            })),
            tap((columnInfos) => FilterCtxService.sortColumnInfosItemsByDisplayValue(columnInfos)),
            tap(
              (cfas) =>
                (this.#selectionColumns = cfas.items
                  .filter((cfa) => cfa.columnInfo.fieldEntity.field.IsSelectionColumn === true)
                  .sort((a, b) =>
                    a.columnInfo.fieldEntity.AD_FormDetail_ID > 0
                      ? a.columnInfo.fieldEntity.SeqNo - b.columnInfo.fieldEntity.SeqNo
                      : a.columnInfo.fieldEntity.field.SelectionSeqNo - b.columnInfo.fieldEntity.field.SelectionSeqNo
                  ))
            ),
            tap((_) => (this.defaultColumn = this.#selectionColumns[0])),
            tap((cfas) => (this.#columnNames = cfas)),
            catchError((err) => {
              this.#messageManager.newMessage(
                new IupicsMessage(this.#translator.instant('generic.error'), err, 'error')
              );
              return [];
            })
          );
  }

  #getOperatorFilters() {
    try {
      const operators = structuredClone(filterOperators).map((op) => ({
        ...op,
        ...{ label: this.#translator.instant(op.label) },
      }));
      const ofas = {
        items: operators.map((operator: FilterOperator) => ({
          id: operator.id,
          displayValue: operator.label,
          operator: operator,
        })),
      };
      this.defaultOperator = ofas.items.find(
        (ofa) =>
          ofa.operator.filterType === CompiereDataGridFilterType.TEXT &&
          ofa.operator.type === OperatorFilterType.CONTAINS
      );
      this.#operators = ofas;
    } catch (err) {
      console.error(err);
      this.#messageManager.newMessage(new IupicsMessage(this.#translator.instant('generic.error'), err, 'error'));
    }
  }

  #getSource() {
    this.source$ = zip(this.columnNames$, of(this.#operators)).pipe(
      map(([columnFilters, operatorFilters]) => {
        return {
          columnFilters: columnFilters,
          operatorFilters: operatorFilters,
        };
      }),
      catchError((err) => {
        this.#messageManager.newMessage(new IupicsMessage(this.#translator.instant('generic.error'), err, 'error'));
        return [];
      })
    );
  }

  #initData() {
    this.selected = {
      favorite: '',
      isDefault: false,
      groups: [],
      filters: [],
      sortings: [],
      notUFData: {},
    };
  }

  #apply(isNotFromUF = false) {
    // update copy of chips and store
    this.#copylocalDatastoreData = cloneDeep(this.#localDatastore.data);
    this.#copySelected = cloneDeep(this.selected);
    this.#fieldToUpdates = [];
    const filterToApply = this.getDataToDisplayToCompiereDataGrid(this.selected, this.tabId, false);

    this.filterChange.emit({ filterToApply, isNotFromUF });
  }

  #buildChipsOnSave(value: string): FilterChip {
    let filterChip: FilterChip;
    if (value.startsWith('orderBy:')) {
      const columnName = value.replace('orderBy:', '').trim();
      const column = this.#columnNames.items.find((c) => c.id === columnName);
      if (column) {
        filterChip = {
          displayValue: `${column.displayValue}`,
          icon: 'icon-tri-az',
          type: 'sortings',
          index: this.selected.sortings.length,
        };
      }
    } else if (value.startsWith('groupBy:')) {
      const columnName = value.replace('groupBy:', '').trim();
      const column = this.#columnNames.items.find((c) => c.id === columnName);
      if (column) {
        filterChip = {
          displayValue: `${columnName}`,
          icon: 'icon-group-check',
          type: 'groups',
          index: this.selected.groups.length,
        };
      }
    } else {
      filterChip = {
        displayValue: `${this.defaultColumn.displayValue} ${this.defaultOperator.displayValue} '${value}'`,
        icon: 'icon-filter',
        type: 'filters',
        index: this.selected.filters.length,
      };
    }
    return filterChip;
  }

  #checkToAdd() {
    return this.defaultColumn !== undefined && this.defaultColumn !== null;
  }

  #checkVCR() {
    for (let i = 0; i < this.vcrFilters.length ?? 0; i++) {
      this.#setVCR(i);
    }
  }

  #setVCR(index: number) {
    // init store because grid is completly rendered after universalfilter
    if (!this.#localDatastore) {
      this.#initLocalDatastore();
    }

    if (
      this.selected.filters[index]?.operator?.operator?.type === OperatorFilterType.IS_NULL ||
      this.selected.filters[index]?.operator?.operator?.type === OperatorFilterType.IS_NOT_NULL
    ) {
      this.vcrFilters.find((_, i) => i === index)?.clear();
    } else {
      let compoRef: ComponentRef<any>;
      if (this.selected.filters[index].column && this.selected.filters[index].column.id !== -1) {
        let componentName = UICreatorUtils.getComponentNameFromReference(
          this.selected.filters[index].column.columnInfo.fieldEntity.field.AD_Reference_ID
        );
        const componentNameToTest = new Set(['InputTextareaUiComponent', 'InputTextHtmlUiComponent']);
        if (componentNameToTest.has(componentName)) {
          componentName = 'InputTextUiComponent';
        } else if (componentName === 'ButtonUiComponent') {
          componentName = 'InputSwitchUiComponent';
        }
        const vcr = this.vcrFilters.find((_, i) => index === i);
        vcr.clear();
        compoRef = createComponent(vcr, componentName);
        compoRef.instance.isStandalone = true;
        compoRef.instance.isInUniversalFilter = true;
        compoRef.instance.isSetDefaultValue = true;
        if (componentName === 'AutocompleteUiComponent') {
          (<AutocompleteUiComponent>compoRef.instance).isInsideOverflow = true;
        }
        /* Set le fieldType utilisé pour le ws /DataField */
        (<AbstractDynamicComponent>compoRef.instance).fieldType = this.#getFieldType();

        this.#subscriptions.push(
          compoRef.instance.fieldValueModified.subscribe((value: any) => {
            const compoRefIndex = this.#componentRefs.findIndex((c) => c === compoRef);
            const filterIndex = this.selected.filters.findIndex(
              (c) => c.column.id === compoRef.instance.data.columnName
            );
            this.setFilter(value, filterIndex, compoRefIndex);
          })
        );
        // ? by default enterKey is Faster than fieldValueModified due to the originalEvent used in both cases
        if (compoRef.instance.hasOwnProperty('enterKey')) {
          this.#subscriptions.push(
            compoRef.instance.enterKey.subscribe((e: Event) => {
              e.stopPropagation();
              const compoRefIndex = this.#componentRefs.findIndex((c) => c === compoRef);
              const filterIndex = this.selected.filters.findIndex(
                (c) => c.column.id === compoRef.instance.data.columnName
              );
              this.setFilter(compoRef.instance.inputRef.nativeElement.value, filterIndex, compoRefIndex);
              if (
                this.#componentRefs[compoRefIndex].instance.multiple === false &&
                componentName === 'InputNumberUiComponent'
              ) {
                return;
              }
              this.#apply();
              this.updatePanelFilter(e, 'GroupFilter', 'group-filter-panel', false);
            })
          );
        }
        if (componentName === 'CalendarUiComponent') {
          compoRef.instance.hasTodayBtn = true;
          (compoRef.instance as AbstractDataContainer).calendarConfig = this.selected.filters[index]?.configs
            ?.calendarConfig || {
            todayMode: false,
          };
          (compoRef.instance as AbstractDataContainer).calendarConfigChange.subscribe((calendarConfig) => {
            const i = this.#componentRefs.findIndex((c) => c === compoRef);
            this.onCalendarConfigChange(calendarConfig, i);
          });
        }
        if (componentName === 'InputSwitchUiComponent') {
          compoRef.instance.isTriState = true;
        }
        if (componentName === 'InputNumberUiComponent') {
          compoRef.instance.multiple = this.selected.filters[index].operator.operator
            ? this.selected.filters[index].operator.operator.type === OperatorFilterType.EQUALS ||
              this.selected.filters[index].operator.operator.type === OperatorFilterType.NOT_EQUALS
            : true;
          compoRef.instance.cssClass = 'UniversalFilterInputNumber';
        }
        // create internal datastore and use datamodified for that
        // it will be used as a local ctx for the universal filter
        if (componentName === 'AutocompleteUiComponent') {
          compoRef.instance.multiple = true;
        }
        const fieldEntity = this.selected.filters[index].column.columnInfo.fieldEntity;
        const field = fieldEntity ? fieldEntity.field : null;
        compoRef.instance.data = <IupicsData>{
          fieldId: field.AD_Field_ID,
          columnId: field.AD_Column_ID,
          formId: field.AD_Form_ID,
          fieldType: field.IsEncryptedColumn || field.IsEncryptedField ? 'password' : 'text',
          isCopy: field.IsCopy,
          items: fieldEntity.data,
          description: field.Description,
          columnName: field.ColumnName,
          details: fieldEntity.details,
          urlList: fieldEntity.urlList,
          urlSearch: fieldEntity.urlSearch,
          tableName: field.tableName,
          tabId: field.AD_Tab_ID,
          isSearch:
            field.AD_Reference_ID === 30 || (fieldEntity.urlSearch !== null && fieldEntity.urlSearch !== undefined),
          isAccount: field.AD_Reference_ID === 25,
          needTime: field.AD_Reference_ID === 16 || field.AD_Reference_ID === 24,
          defaultValue: field.DefaultValue,
          defaultValue2: field.DefaultValue2,
          searchColumns: fieldEntity.details ? fieldEntity.details.searchColumns : null,
          numberType:
            field.AD_Reference_ID === 12 || field.AD_Reference_ID === 37
              ? NumberType.AMOUNT
              : field.AD_Reference_ID === 11
                ? NumberType.INTEGER
                : field.AD_Reference_ID === 22
                  ? NumberType.FLOAT
                  : field.AD_Reference_ID === 29
                    ? NumberType.QUANTITY
                    : null,
          validationCode: field.ValidationCode ? field.ValidationCode.replace(/\n/g, ' ') : null,
          componentName: componentName,
        };
        if (this.#localDatastore && this.#localDatastore.data) {
          const getCurrentContext = () => {
            this.#parentContext = {};
            const data = {};
            const keys = [
              ...Object.keys(this.#parentContext),
              ...Object.keys(this.#localDatastore.data),
              ...Object.keys(this.#ctxArea),
            ];
            for (const key of keys) {
              if (this.#localDatastore.data[key]) data[key] = this.#localDatastore.data[key];
              else if (this.#parentContext[key]) data[key] = this.#parentContext[key];
              else if (this.#ctxArea && this.#ctxArea[key]) data[key] = this.#ctxArea[key];
            }
            return data;
          };
          compoRef.instance.getCurrentContext = getCurrentContext.bind(this);
          if (compoRef.instance.setNewData) {
            compoRef.instance.setNewData(this.#localDatastore);
          }
        }
        this.#componentRefs.splice(index, 0, compoRef);
      }
    }
  }

  #getFieldType(): CompiereDataFieldType {
    let fieldType = CompiereDataFieldType.FIELD;
    const windowType = this.windowType;
    switch (windowType) {
      case IupicsMenuType.FORM:
        fieldType = CompiereDataFieldType.FORM_ITEM;
        break;
      case IupicsMenuType.PROCESS:
        fieldType = CompiereDataFieldType.PROCESS_PARA;
        break;
      case IupicsMenuType.WINDOW:
        fieldType = CompiereDataFieldType.FIELD;
        break;
      default:
        break;
    }
    return fieldType;
  }

  #handleUpdateFilter(
    newFilter: CompiereDataGridRequestJSON,
    firstChipsName?: any,
    shouldApply = true,
    callback = () => {}
  ) {
    // init store because grid is completly rendered after universalfilter
    if (!this.#localDatastore) {
      this.#initLocalDatastore();
    }
    this.#adaptFilterModelToUniversalFilter(newFilter.filterModel).then((newFilterModel) => {
      newFilter.filterModel = newFilterModel;
      const newDisplayFilters = this.#ufUtils.filterModelToFilterToDisplay(
        this.#columnNames,
        this.#operators,
        newFilter
      );
      const newDisplaySorts = this.#ufUtils.sortModelToFilterToDisplay(this.#columnNames, newFilter);
      const newDisplayGroups = this.#ufUtils.groupModelToFilterToDisplay(this.#columnNames, newFilter);
      if (newDisplayFilters) {
        if (firstChipsName) {
          const indexFound = newDisplayFilters.findIndex((f) => f.column.id === firstChipsName);
          if (indexFound !== -1) {
            newDisplayFilters.splice(0, 0, newDisplayFilters.splice(indexFound, 1)[0]);
          } else {
            const indexColumnDate = newDisplayFilters.findIndex(
              (f) => f.column.columnInfo.filterType === CompiereDataGridFilterType.DATE
            );
            if (indexColumnDate !== -1) {
              newDisplayFilters.splice(0, 0, newDisplayFilters.splice(indexColumnDate, 1)[0]);
            }
          }
        }
        this.selected.filters = newDisplayFilters;
      }
      if (newDisplaySorts) {
        this.selected.sortings = newDisplaySorts;
      }
      if (newDisplayGroups) {
        this.selected.groups = newDisplayGroups;
      }
      this.selected.notUFData = {
        valueCols: newFilter.valueCols,
      };
      this.updateChips();

      if (this.#localDatastore) {
        const dataModified = {};
        for (const f of newDisplayFilters) {
          if (f.filterTo) dataModified[f.column.id + '_To'] = f.filterTo;
          dataModified[f.column.id] = f.filter;
        }
        // adapt localStore to empty filter
        if (
          this.selected &&
          this.selected.filters &&
          this.selected.filters.length === 0 &&
          this.#copySelected &&
          this.#copySelected.filters &&
          this.#copySelected.filters.length > 0
        ) {
          for (const f of this.#copySelected.filters) {
            if (f.column && f.column.id) dataModified[f.column.id] = null;
          }
        }
        this.#store.syncDataChanges(this.#localDatastore, dataModified, true);
      }
      if (shouldApply) {
        this.#apply(true);
      }
      callback();
    });
  }

  private handleViewChange() {
    this.selected = this.#ufUtils.cleanDataToDisplay(this.selected);
    if (this.canFilter && this.selected.filters.length === 0) {
      this.addFilter(document.createEvent('Event'));
    }
  }

  /**
   * reformate le fitermodel pour pouvoir être utilisé par l'universalfilter
   * @param filterModel
   */
  #adaptFilterModelToUniversalFilter(filterModel): Promise<{
    [columnName: string]: CompiereDataGridFilterModel;
  }> {
    return new Promise((resolve) => {
      if (filterModel) {
        const results$ = [];
        const resultsColumn = new Map<number, { columnKey: string; indexValue: number }>();
        const columnKeys = Object.keys(filterModel);
        for (const columnKey of columnKeys) {
          const column = this.#columnNames.items.find(
            (columnFilter) =>
              columnFilter.id === columnKey || columnFilter.columnInfo.fieldEntity.ColumnName === columnKey
          );

          if (column) {
            let fieldType: CompiereDataFieldType;
            fieldType = this.#getFieldType();
            const columnType = fieldType;
            const columnEntity =
              columnType === CompiereDataFieldType.FIELD
                ? column.columnInfo.fieldEntity.field['AD_Field_ID']
                : column.columnInfo.fieldEntity.field['AD_Column_ID'];
            if (filterModel[columnKey].filterType === CompiereDataGridFilterType.SET && filterModel[columnKey].values) {
              for (let i = 0; i < filterModel[columnKey].values.length ?? 0; i++) {
                const value = filterModel[columnKey].values[i];
                if (value instanceof Array) {
                  for (const f of value) {
                    if (!(f instanceof Object)) {
                      results$.push(this.#store.getAutocompleteDataById(fieldType, columnEntity, f,null,true));
                    } else {
                      results$.push(of(f));
                    }
                    resultsColumn.set(results$.length - 1, {
                      columnKey: columnKey,
                      indexValue: i,
                    });
                  }
                } else {
                  if (!(value instanceof Object)) {
                    results$.push(this.#store.getAutocompleteDataById(fieldType, columnEntity, value,null,true));
                  } else {
                    results$.push(of(value));
                  }
                  resultsColumn.set(results$.length - 1, { columnKey: columnKey, indexValue: i });
                }
                filterModel[columnKey].values = [];
              }
            }
          }
        }

        if (results$.length > 0) {
          zip(...results$).subscribe((response) => {
            for (let i = 0; i < response.length ?? 0; i++) {
              const value = response[i];
              const columnKey = resultsColumn.get(i).columnKey;
              const indexValue = resultsColumn.get(i).indexValue;
              if (value && (!(value instanceof Array) || value[0])) {
                if (!filterModel[columnKey].values[indexValue]) {
                  filterModel[columnKey].values[indexValue] = [value instanceof Array ? value[0] : value];
                } else {
                  filterModel[columnKey].values[indexValue].push(value instanceof Array ? value[0] : value);
                }
              }
            }

            resultsColumn.forEach((value) => {
              const columnKey = value.columnKey;
              const indexValue = value.indexValue;
              if (filterModel[columnKey]) {
                if (
                  filterModel[columnKey].values[indexValue] === undefined ||
                  (filterModel[columnKey].values[indexValue] instanceof Array &&
                    filterModel[columnKey].values[indexValue].length <= 0)
                ) {
                  delete filterModel[columnKey];
                }
              }
            });
            resolve(filterModel);
          });
        } else {
          resolve(filterModel);
        }
      }
    });
  }

  resetFilters(event: Event, dataToDisplayOverride?: DataToDisplay) {
    event.stopPropagation();
    if (this.selected.filters) {
      for (const f of this.selected.filters) {
        if (f.column) {
          if (this.#fieldToUpdates) this.#fieldToUpdates.push(f.column.id);
          if (this.#localDatastore?.data) this.#localDatastore.data[f.column.id] = null;
        }
      }
    }
    this.#initData();
    if (dataToDisplayOverride) {
      this.selected.favorite = dataToDisplayOverride.favorite;
      this.selected.isDefault = dataToDisplayOverride.isDefault;
      this.selected.widgetID = dataToDisplayOverride.widgetID;
    }
    this.filterChips = [];
    this.usedFilterColumnNames = [];
    if (this.#selectionColumns.length > 1) {
      for (const column of this.#selectionColumns) {
        this.addFilter(document.createEvent('Event'));
        this.setColumnFilter(column, this.selected.filters.length - 1, false);
      }
    } else {
      this.addFilter(document.createEvent('Event'));
    }
    setTimeout(() => {
      this.#componentRefs = [];
      this.#checkVCR();
    }, 50);
  }

  updateChips() {
    this.filterChips = this.#ufUtils.dataToDisplayToChips(this.selected);
    this.usedFilterColumnNames = [];
    for (const f of this.selected.filters) {
      if (f && f.column) this.usedFilterColumnNames.push(f?.column?.id);
    }
  }

  updateNotUFData() {
    this.selected.notUFData = {};
  }

  getDataToDisplayToCompiereDataGrid(
    dataToDisplay: DataToDisplay,
    tabdId: number,
    updateNotUFData = true
  ): CompiereDataGridRequestJSON {
    if (updateNotUFData) {
      this.updateNotUFData();
    }
    return this.#ufUtils.dataToDisplayToCompiereDataGrid(dataToDisplay, tabdId);
  }

  onCalendarConfigChange(calendarConfig: CalendarConfig, index: number, configTo = false) {
    if (isNil(this.selected.filters[index].configs)) {
      this.selected.filters[index].configs = {};
    }
    if (isNil(this.selected.filters[index].configsTo)) {
      this.selected.filters[index].configsTo = {};
    }
    if (configTo) {
      this.selected.filters[index].configsTo.calendarConfig = calendarConfig;
    } else {
      this.selected.filters[index].configs.calendarConfig = calendarConfig;
    }
  }

  #sortColumnInfosItemsByDisplayValue(columnInfos: { items: ColumnFilterAutocomplete[] }) {
    columnInfos.items.sort((a, b) => a.displayValue.toLowerCase().localeCompare(b.displayValue.toLowerCase()));
    return columnInfos;
  }
}
