import { AppConfig } from '@iupics-config/app.config';

export namespace DocUtils {
  export const fileExtensionRegex = /\.[^.]+$/;

  export function getFileExtension(fileName, slicePoint = true) {
    if (!slicePoint) {
      return fileExtensionRegex.exec(fileName)?.[0];
    }

    return fileExtensionRegex.exec(fileName)?.[0].slice(1);
  }

  export function getFileIcon(fileName) {
    switch (getFileExtension(fileName, false)) {
      case '.pdf':
        return 'icon-pdf';
      case '.csv':
        return 'icon-csv';
      case '.txt':
        return 'icon-txt';
      case '.xls':
      case '.xlsx':
        return 'icon-excel';
      case '.doc':
      case '.docx':
        return 'icon-word';
      case '.sql':
        return 'icon-sql';
      case '.zip':
        return 'icon-zip';
      case '.html':
        return 'icon-html';
      default:
        return 'icon-file';
    }
  }

  export function getUrlIcon(config: AppConfig, url: string) {
    const urlPatterns = config.getConstant('regexUrlPatterns');
    if (new RegExp(urlPatterns['google-drive'].join('|')).test(url)) {
      // google-drive
      return 'icon-google-drive';
    } else if (new RegExp(urlPatterns['onedrive'].join('|')).test(url)) {
      // onedrive
      return 'icon-onedrive';
    } else if (new RegExp(urlPatterns['dropbox'].join('|')).test(url)) {
      // dropbox
      return 'icon-dropbox';
    } else if (new RegExp(urlPatterns['youtube'].join('|')).test(url)) {
      // youtube
      return 'icon-youtube';
    } else if (new RegExp(urlPatterns['github'].join('|')).test(url)) {
      // github
      return 'icon-github';
    } else {
      return 'icon-html';
    }
  }

  export function isFileExtensionImage(fileName) {
    switch (getFileExtension(fileName, false)) {
      case '.png':
      case '.jpg':
      case '.jpeg':
      case '.gif':
      case '.bmp':
      case '.ico':
      case '.webp':
      case '.svg':
        return true;
      default:
        return false;
    }
  }
}
