import { inject, Injectable } from '@angular/core';
import {
  CompiereLocationCity,
  CompiereLocationCountry,
  CompiereLocationRegion,
} from '@compiere-ws/models/compiere-location';
import { LocationModel } from '@compiere-ws/models/compiere-location-json';
import { SpecificWindowCompiereWS } from '@compiere-ws/models/specific-window-json';
import { AppConfig } from '@iupics-config/app.config';
import { interpolate } from '@iupics-manager/models/global-var';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  private http = inject(ApiService);
  private config = inject(AppConfig);

  private urlCountries = this.config.getBackendResource('countries');
  private urlCities = this.config.getBackendResource('cities');
  private urlLocation = this.config.getBackendResource('location');
  private urlLocationUI = this.config.getBackendResource('locationUI');
  private urlLocationUIByCountry = this.config.getBackendResource('locationUIByCountry');
  private countryDisplaySequences = new Map<number, string>();

  /**
   * @start_custo_code
   */
  private urlTempLocation = this.config.getBackendResource('tempLocation');
  /**
   * @end_custo_code
   */

  get isNewLocationEnable(): boolean {
    return this.config.isModuleEnable('newLocation');
  }

  /**
   * @returns {Observable<any>} L'ensemble des pays de compiere
   */
  getLocation(c_location_id: number): Observable<LocationModel> {
    return this.http.get<LocationModel>(this.urlLocation + '/' + c_location_id);
  }

  /**
   * @returns {Observable<any>} L'ensemble des pays de compiere
   * @start_custo_code
   */
  getTempLocation(tempLocationId: number): Observable<any> {
    return this.http.get<any>(this.urlTempLocation + '/' + tempLocationId);
  }
  /**
   * @end_custo_code
   */

  /**
   * @returns {Observable<CompiereLocationCountry[]>} L'ensemble des pays de compiere
   */
  getCountries(): Observable<CompiereLocationCountry[]> {
    return this.http.get<CompiereLocationCountry[]>(this.urlCountries);
  }

  /**
   *
   * @param {number} country_id L'ID du pays
   * @returns {Observable<CompiereLocationRegion>} L'ensemble des regions du pays renseigné
   */
  getRegions(country_id: number): Observable<CompiereLocationRegion> {
    return this.http.get<CompiereLocationRegion>(this.urlCountries + `/${country_id}`);
  }

  /**
   *
   * @param {number} country_id L'ID du pays
   * @returns {Observable<CompiereLocationCity[]>} L'ensemble des villes du pays renseigné
   */
  getCities(country_id: number): Observable<CompiereLocationCity[]> {
    return this.http.get<CompiereLocationCity[]>(this.urlCities + `/${country_id}`);
  }

  /**
   *
   * @param {number} city_id L'ID de la ville
   * @returns {Observable<CompiereLocationCity[]>} ville trouvée
   */
  getCity(city_id: number): Observable<CompiereLocationCity[]> {
    return this.http.get<CompiereLocationCity[]>(this.urlCities + `/${city_id}`);
  }

  /**
   * Permet de récupérer le masque de saisie demandé dans le nouveau format normalisé.
   * (Voir [redmine #154724](https://helpdesk.audaxis.com/issues/show/154724))
   * @param {number} ad_form_id L'ID de la form qui servira de container pour l'appel WS
   * @param {number} c_country_id L'ID du pays dont on veut le masque de saisie. Si manquant, le masque de saisie par défaut sera retourné
   * @returns {Observable<SpecificWindowCompiereWS>}
   */
  public getLocationUI(ad_form_id: number, c_country_id: number = -1): Observable<SpecificWindowCompiereWS> {
    if (c_country_id == -1) {
      return this.http.get<SpecificWindowCompiereWS>(`${this.urlLocationUI}/${ad_form_id}`);
    } else {
      const url = interpolate(this.urlLocationUIByCountry, { formID: ad_form_id });
      return this.http.get<SpecificWindowCompiereWS>(`${url}/${c_country_id}`);
    }
  }

  public getCountryDisplaySequence(c_country_id: number): Observable<string> {
    if (c_country_id == -1) return of(null);
    if (this.countryDisplaySequences.has(c_country_id)) return of(this.countryDisplaySequences.get(c_country_id));
    return this.http.get<string>(`${this.urlLocationUI}/Country/${c_country_id}/displaysequence`).pipe(
        map((displaySequence) => {
          this.countryDisplaySequences.set(c_country_id, displaySequence);
          return displaySequence;
        })
    );
  }

  replaceVariables(template: string, obj: Record<string, any>): string {
    // Iterate through the object keys and replace placeholders
    Object.keys(obj).forEach((key) => {
      const regex = new RegExp(`@${key}@`, 'g'); // Create a regex to match @key@
      let value = obj[key]?.displayValue ?? obj[key];
      template = template.replace(regex, value);
    });
    return template;
  }

  public parseAddress(data: LocationModel, displaySequence: string = null, newLine = false): string {
    const infos: string[] = [];

    const addIfExists = (value: string, array: string[]): void => {
      if (value && value.length > 0) array.push(value);
    };

    if (!data.ParsedAddress || data.ParsedAddress.length === 0) {
      if (displaySequence && displaySequence.length > 0) {
        data.ParsedAddress = this.replaceVariables(displaySequence, data);
      }
    }

    if (data.ParsedAddress) {
      const cleanedAddress = data.ParsedAddress.replace(/\n\s*\n/g, '\n');
      infos.push(newLine ? cleanedAddress : cleanedAddress.replace(/\n/g, ', '));
    } else {
      if (this.isNewLocationEnable) {
        addIfExists(data.NL_ExtraInfo, infos);
        addIfExists(data.NL_ExtraStreet, infos);
        addIfExists(data.NL_SpecialMention, infos);

        if (data.NL_Street) {
          const streetInfos: string[] = [];
          streetInfos.push(data.NL_Street);
          addIfExists(data.NL_BoxNumber, streetInfos);
          addIfExists(data.NL_Number, streetInfos);
          infos.push(streetInfos.join(' '));
        }
      } else {
        addIfExists(data.Address1, infos);
        addIfExists(data.Address2, infos);
        addIfExists(data.Address3, infos);
        addIfExists(data.Address4, infos);
      }

      infos.push(data.C_City_ID ? data.C_City_ID.displayValue : data.City);
      addIfExists(data.Postal, infos);
      addIfExists(data.C_Country_ID?.displayValue, infos);
    }

    return infos.join(newLine ? '\n' : ', ');
  }
}
