import { Injectable } from '@angular/core';
import { Tag } from '@compiere-ws/models/tag-json';
import { AppConfig } from '@iupics-config/app.config';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiService } from '../api/api.service';

@Injectable({
  providedIn: 'root',
})
export class TagService {
  private tagServiceUrl: string;
  private tags: Tag[];

  constructor(
    private http: ApiService,
    private config: AppConfig
  ) {
    this.tagServiceUrl = this.config.getBackendResource('tag');
  }

  getTags(): Observable<Tag[]> {
    if (this.tags) return of(this.tags);
    return this.http.get<Tag[]>(this.tagServiceUrl).pipe(
      map((response) => {
        this.tags = response;
        return this.tags;
      }),
      catchError((_) => of([]))
    );
  }

  resetCachingData() {
    this.tags = undefined;
  }
}
