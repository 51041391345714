import { NgClass, NgTemplateOutlet } from '@angular/common';
import {
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  ViewEncapsulation,
  computed,
  forwardRef,
  input,
  output,
  signal,
  viewChild,
  viewChildren,
} from '@angular/core';
import AutocompleteUiComponent from '../autocomplete-ui/autocomplete-ui.component';

@Component({
  selector: 'iu-multi-level-autocomplete-ui',
  standalone: true,
  imports: [forwardRef(() => AutocompleteUiComponent), NgTemplateOutlet, NgClass],
  templateUrl: './multi-level-autocomplete-ui.component.html',
  styleUrl: './multi-level-autocomplete-ui.component.scss',
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'iu-multi-level-autocomplete-ui',
  },
})
export class MultiLevelAutocompleteUiComponent {
  placeholder = input<string>(undefined);

  disabled = input(false);
  @HostBinding('attr.disabled')
  get _disabled() {
    return this.disabled();
  }

  groups = input<MultiLevelAutocompleteData[]>();

  multiple = input(false);

  autocomplete = viewChild('autocomplete', { read: AutocompleteUiComponent });

  chips = viewChildren('.input-chip', { read: ElementRef<HTMLElement> });

  fieldValue = '';

  selected = signal<MultiLevelAutocompleteDataItem[]>([]);

  groupFiltered = computed(
    () =>
      this.groups()?.reduce((acc, { groupName }) => {
        if (this.multiple() || !this.selected()?.find((s) => s.name === groupName)) {
          acc.push({ id: groupName, value: groupName, displayValue: groupName });
        }

        return acc;
      }, []) ?? []
  );

  activeGroupName = signal<string>('');
  activeGroup = computed(() => this.#getGroup(this.activeGroupName()));
  activeItems = computed(() => this.#getActiveItems(this.activeGroup()));

  edition = signal<MultiLevelAutocompleteDataItem>(undefined);
  editionGroup = computed(() => this.#getGroup(this.edition()?.name));
  editionActiveItems = computed(() => this.#getActiveItems(this.editionGroup(), this.edition()?.value));

  selectionChanged = output<MultiLevelAutocompleteOutputData>();

  #getGroup(groupName: string) {
    return this.groups()?.find((g) => g.groupName === groupName);
  }

  #getActiveItems(group: MultiLevelAutocompleteData, editionValue = undefined) {
    return (
      group?.items?.reduce((acc, item) => {
        if ((editionValue && item?.value === editionValue) || !this.selected()?.find((s) => s.id === item.id)) {
          acc.push(item);
        }
        return acc;
      }, []) ?? []
    );
  }

  reset() {
    this.activeGroupName.set('');
    this.#resetValue();
  }

  #resetValue() {
    const autocomplete = this.autocomplete();
    if (autocomplete) {
      autocomplete.fieldValue = '';
    }
  }

  addGroupNameValue(event: any) {
    this.activeGroupName.set(event.value);
    if (this.activeItems()?.length === 1) {
      this.addValue(this.activeItems()[0]);
      return;
    }
    this.#resetValue();
    this.focus();
  }

  addValue(item: MultiLevelAutocompleteDataItem) {
    this.selected.update((s) => [...s, item]);
    this.reset();
    this.emitValue('add', item);
    this.focus();
  }

  updateValue(item: MultiLevelAutocompleteDataItem) {
    this.selected.update((s) => {
      const index = s.findIndex((v) => v.id === this.edition().id);
      if (index > -1) s.splice(index, 1, item);
      return [...s];
    });
    this.edition.set(undefined);
    this.emitValue('update', item);
    this.focus();
  }

  removeValue(item: MultiLevelAutocompleteDataItem, shouldFocus = true) {
    let removed: MultiLevelAutocompleteDataItem[];
    this.selected.update((s) => {
      const index = s.findIndex(({ id }) => id === item.id);
      if (index > -1) removed = s.splice(index, 1);
      return [...s];
    });

    if (removed) this.emitValue('remove', removed);
    if (shouldFocus) this.focus();
  }

  removeItemOnBackspace(event: KeyboardEvent) {
    if (event.key !== 'Backspace') return;
    const fieldValue = this.autocomplete()?.fieldValue;
    if (fieldValue?.length > 0) return;
    const selected = this.selected() ?? [];
    if (selected.length === 0) return;
    this.removeValue(selected[selected.length - 1]);
  }

  removeItemIfEmpty(item?: MultiLevelAutocompleteDataItem) {
    if (!item) {
      this.reset();
      return false;
    }

    if (!item?.value) {
      this.removeValue(item);
      return true;
    }

    return false;
  }

  onRemove(event: MouseEvent, item: MultiLevelAutocompleteDataItem) {
    event.stopPropagation();
    this.removeValue(item, false);
  }

  switchToEdit(item: MultiLevelAutocompleteDataItem) {
    this.edition.set(item);
    this.focus();
  }

  onEdit(event: MouseEvent, item: MultiLevelAutocompleteDataItem) {
    event.stopPropagation();
    this.switchToEdit(item);
  }

  reverseEdition() {
    this.edition.set(undefined);
  }

  emitValue(
    type: 'add' | 'remove' | 'update',
    value: MultiLevelAutocompleteDataItem | MultiLevelAutocompleteDataItem[]
  ) {
    const state: MultiLevelAutocompleteOutputData = {
      current: this.selected(),
    };
    const vvalue = Array.isArray(value) ? value : [value];
    switch (type) {
      case 'add':
        state['added'] = vvalue;
        break;
      case 'remove':
        state['removed'] = vvalue;
        break;
      case 'update':
        state['updated'] = vvalue;
        break;
    }

    this.selectionChanged.emit(state);
  }

  @HostListener('click', ['$event'])
  focus(event?: MouseEvent) {
    event?.stopPropagation();
    setTimeout(() => {
      (<HTMLElement>this.chips()?.[this.chips()?.length - 1]?.nativeElement)?.scrollIntoView({
        behavior: 'instant',
        inline: 'end',
      });
      this.autocomplete()?.autoComplete?.inputEL?.nativeElement?.focus();
    }, 10);
  }
}

export type MultiLevelAutocompleteData = {
  groupName: string;
  items: MultiLevelAutocompleteDataItem[];
};

export type MultiLevelAutocompleteDataItem = {
  id: string;
  name: string;
  value: any;
  displayValue?: string;
};

export type MultiLevelAutocompleteOutputData = {
  removed?: MultiLevelAutocompleteDataItem[];
  added?: MultiLevelAutocompleteDataItem[];
  updated?: MultiLevelAutocompleteDataItem[];
  current: MultiLevelAutocompleteDataItem[];
};
