import { NgClass } from '@angular/common';
import { Component } from '@angular/core';
import GridTabInfinityScrollUiComponent from '@iupics-components/standard/grid/grid-tab-infinity-scroll-ui/grid-tab-infinity-scroll-ui.component';
import GridViewUiComponent from '@iupics-components/standard/grid/grid-view-ui/grid-view-ui.component';
import EditViewUiComponent from '@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component';
import ModalUiComponent from '@iupics-components/standard/layouts/modal-ui/modal-ui.component';
import UniversalFilterUiComponent from '@iupics-components/standard/menu/universal-filter-ui/universal-filter-ui.component';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { TranslateModule } from '@ngx-translate/core';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import SpecificWindowUiComponent from '../specific-window-ui/specific-window-ui.component';

@Component({
  selector: 'iu-non-product-catalog',
  templateUrl: './non-product-catalog.component.html',
  styleUrls: ['./non-product-catalog.component.scss'],
  imports: [
    NgClass,
    ProgressBarModule,
    ScrollPanelModule,
    TranslateModule,
    ModalUiComponent,
    GridTabInfinityScrollUiComponent,
    UniversalFilterUiComponent,
  ],
  standalone: true,
})
export default class NonProductCatalogComponent extends SpecificWindowUiComponent {
  notifyFromDataChange(item: any) {
    if (item.data.columnName === 'XX_Thickness' || item.data.columnName === 'XX_Length') {
      const thickness = this.dataStore.data['XX_Thickness'];
      const length = this.dataStore.data['XX_Length'];

      this.subscriptions.push(
        this.uiCreator
          .getDBSelect(
            'SELECT (SELECT CHARGEPERTN FROM XX_CutCharge WHERE XX_Thickness = ? AND ROWNUM = 1) ChargePerTN,' +
              ' (SELECT XX_LENGTH FROM XX_NoCutCharge WHERE XX_Length = ? AND ROWNUM = 1) XX_Length' +
              ' FROM DUAL',
            [thickness, length],
            []
          )
          .subscribe((response) => {
            if (response !== undefined && response[0]) {
              const chargePerTNContainer = this.dataContainers.find((elem) => elem.data.columnName === 'ChargePerTN');
              if (item.data.columnName === 'XX_Thickness' && !response[0].XX_LENGTH && response[0].CHARGEPERTN) {
                chargePerTNContainer.updateStore(response[0].CHARGEPERTN);
              } else if (item.data.columnName === 'XX_Length' && response[0].XX_LENGTH && response[0].CHARGEPERTN) {
                chargePerTNContainer.updateStore(0);
              }
            }
          })
      );
    }

    super.notifyFromDataChange(item);
  }

  checkBeforeProcessing() {
    super.checkBeforeProcessing();
    // console.log('Hello .');
    // console.log(this);
    const p_XX_Length = this.dataStore.data.XX_Length;
    const p_XX_Width = this.dataStore.data.XX_Width;
    const p_XX_Thickness = this.dataStore.data.XX_Thickness;

    if (
      !p_XX_Length ||
      p_XX_Length === '0' ||
      !p_XX_Width ||
      p_XX_Width === '0' ||
      !p_XX_Thickness ||
      p_XX_Thickness === '0'
    ) {
      this.messageManager.newMessage(
        new IupicsMessage(this.translateService.instant('blmErrors.diemnsionError'), 'error')
      );
      // console.log('Failed');
      return false;
    } else {
      // console.log('Success');
      return true;
    }
  }

  onEndProcess() {
    this.refreshAllBlades();

    super.closeForm();
  }

  refreshAllBlades() {
    let editViews: EditViewUiComponent[];
    if (
      this.parentComponent &&
      this.parentComponent.DOMParentComponent &&
      this.parentComponent.DOMParentComponent.DOMChildrenComponent
    ) {
      if (this.parentComponent.DOMParentComponent.DOMChildrenComponent[0]) {
        const windowId = (<GridViewUiComponent>this.parentComponent.DOMParentComponent.DOMChildrenComponent[0]).data
          .AD_Window_ID;
        editViews = <EditViewUiComponent[]>(
          this.parentComponent.DOMParentComponent.DOMChildrenComponent.slice().filter(
            (child) =>
              child instanceof EditViewUiComponent &&
              child.currentDataStoreKey &&
              windowId == child.currentDataStoreKey.windowId
          )
        );
      }
    }
    editViews.forEach((editView) => {
      editView.refreshData();
      editView.isProcessLaunch = false;
    });
  }
}
