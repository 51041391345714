import { NgClass } from '@angular/common';
import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { CustomDesignItemType } from '@iupics-components/models/custom-design';
import AutocompleteUiComponent from '@iupics-components/standard/fields/autocomplete-ui/autocomplete-ui.component';
import ButtonUiComponent from '@iupics-components/standard/fields/button-ui/button-ui.component';
import EditViewUiComponent from '@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component';
import ModalUiComponent from '@iupics-components/standard/layouts/modal-ui/modal-ui.component';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';
import { DynamicContainerDirective } from '@iupics-util/directives/dynamic-container.directive';
import { TranslateModule } from '@ngx-translate/core';
import { ProgressBarModule } from 'primeng/progressbar';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SidebarModule } from 'primeng/sidebar';
import SpecificWindowUiComponent from '../specific-window-ui/specific-window-ui.component';

@Component({
  selector: 'iu-product-view-mfg',
  templateUrl: './product-view-mfg.component.html',
  styleUrls: ['./product-view-mfg.component.scss'],
  standalone: true,
  imports: [
    ProgressBarModule,
    ScrollPanelModule,
    DynamicContainerDirective,
    NgClass,
    ButtonUiComponent,
    SidebarModule,
    ModalUiComponent,
    // UniversalFilterUiComponent,
    // GridTabInfinityScrollUiComponent,
    TranslateModule,
  ],
})
export default class ProductViewMFGComponent extends SpecificWindowUiComponent implements OnInit {
  @ViewChild('vcrHidden', { read: ViewContainerRef, static: true })
  vcrHidden: ViewContainerRef;

  // @Input()
  //isReadOnly = false;

  ngOnInit() {
    // @start_custo_code
    if (this.parentComponent instanceof EditViewUiComponent) {
      if (!this.dataStore) {
        this.dataStore = this.store.newSpecificWindowData(
            this.formId,
            (<EditViewUiComponent>this.parentComponent).editTabs[0].dataStored
        );
      }
    }
    // @end_custo_code
    this.subscriptions.push(
        this.uiCreator.getSpecificWindow(this.formId).subscribe((specificWindow) => {
          specificWindow.items.forEach((item) => {
            switch (item.data.columnName) {
              case 'Price_History_Button':
                this.customDesignArray.push({
                  vcr: 'vcrHidden',
                  type: CustomDesignItemType.FIELD,
                  columnName: item.data.columnName,
                  cssClass: 'p-lg-3 p-md-3 p-col-12',
                });
                break;
              case 'Product Info MFG Table':
                this.customDesignArray.push({
                  vcr: 'vcr',
                  type: CustomDesignItemType.GRID,
                  tableName: item.name,
                  cssClass: item.cssClass,
                });
                break;
              default:
                if (item.component === 'GridViewUiComponent') {
                  this.customDesignArray.push({
                    vcr: 'vcr',
                    type: CustomDesignItemType.GRID,
                    tableName: item.name,
                    cssClass: item.cssClass,
                  });
                } else if (item.component === 'ButtonUiComponent') {
                  this.customDesignArray.push({
                    vcr: 'vcr',
                    type: CustomDesignItemType.FIELD,
                    columnName: item.data.columnName,
                    cssClass: 'p-col-12 p-md-4 p-lg-2',
                  });
                } else {
                  let vcr = 'vcr';
                  if (item.data.IsOptional) {
                    vcr = 'vcrOptional';
                  }
                  this.customDesignArray.push({
                    vcr: vcr,
                    type: CustomDesignItemType.FIELD,
                    columnName: item.data.columnName,
                    cssClass: 'p-col-12 p-md-4 p-lg-2',
                  });
                }
                break;
            }
          });
          this.showSpecificWindow();
          if (this.gridViews) {
            const gridFound = this.gridViews.find((grid) => grid.data.columnName === 'Product Info MFG Table');
            let validationCode = null;
            validationCode = gridFound.data.validationCode;
            if (gridFound.data.validationCode) {
              gridFound.data.validationCode = validationCode.replaceAll('M_Product', 'Product_Info_MFG');
            }
          }
        })
    );
  }
  showPriceHistoryForm() {
    const selections = this.getGridSelection('Product Info MFG Table');
    const button = this.getDatacontainer('Price_History_Button');
    const gridFound = this.gridViews.find((grid) => grid.data.columnName === 'Product Info MFG Table');
    if (gridFound && selections && selections.length > 0) {
      if (
          selections[0]['MFG_Product_ID'] > 0 &&
          (!selections[0]['M_Product_ID'] ||
              (selections[0]['M_Product_ID'] &&
                  (!selections[0]['M_Product_ID'].id || selections[0]['M_Product_ID'].id < 0)))
      ) {
        this.messageManager.newMessage(
            new IupicsMessage(this.translateService.instant('specificWindow.productViewMFG.priceHistoryERR'), 'error')
        );
      } else {
        this.updateModalDisplay(
            {
              key: 'displayFormUI',
              value: true,
              sourceComponent: gridFound.GridTabInfinityScrollUiComponent,
            },
            { key: 'formId', value: button.data['formId'] }
        );
      }
    } else {
      this.messageManager.newMessage(
          new IupicsMessage(
              this.translateService.instant('infodialog.dialogs.noLineSelected.title'),
              this.translateService.instant('infodialog.dialogs.noLineSelected.message'),
              'warning'
          )
      );
    }
  }

  setSelectedRowOnAutocomplete() {
    let selections = [];
    const gridFound = this.gridViews.find((grid) => grid.data.columnName === 'Product Info MFG Table');
    if (gridFound) {
      selections = gridFound.GridTabInfinityScrollUiComponent.grid.api.getSelectedRows();
    }
    if (
        selections &&
        selections.length > 0 &&
        this.parentComponent instanceof EditViewUiComponent &&
        (<EditViewUiComponent>this.parentComponent).editTabs &&
        (<EditViewUiComponent>this.parentComponent).editTabs[0] &&
        (<EditViewUiComponent>this.parentComponent).editTabs[0].dataContainers
    ) {
      const mfgProduct = (<EditViewUiComponent>this.parentComponent).editTabs[0].dataContainers.find(
          (datacontainer) => datacontainer.DOMParentComponent.DOMChildrenComponent[0].data.columnName === 'MFG_Product_ID'
      );

      if (
          selections[0]['MFG_Product_ID'] > 0 &&
          (!selections[0]['M_Product_ID'] ||
              (selections[0]['M_Product_ID'] &&
                  (!selections[0]['M_Product_ID'].id || selections[0]['M_Product_ID'].id < 0)))
      ) {
        const event = { data: { Data_UUID: 'MFG_Product_ID' + ',' + selections[0]['MFG_Product_ID'] } };
        (<AutocompleteUiComponent>this.sourceComponent).dataChange(null);
        (<AutocompleteUiComponent>mfgProduct).setSearchSelectItem(event);
      } else {
        this.addContextDataToParentStore(
            selections[0],
            this.connectorService.getConfig().getConstant('ContextPrefixSearchWindow')
        );
        const event = { data: { Data_UUID: 'M_Product_ID' + ',' + selections[0]['M_Product_ID'] } };
        (<AutocompleteUiComponent>mfgProduct).dataChange(null);
        (<AutocompleteUiComponent>this.sourceComponent).setSearchSelectItem(event);
      }
    }
  }
  zoomOnLineSelected() {
    let selections = [];
    const gridFound = this.gridViews.find((grid) => grid.data.columnName === 'Product Info MFG Table');
    if (gridFound) {
      selections = gridFound.GridTabInfinityScrollUiComponent.api.getSelectedRows();
    }
    if (
        selections &&
        selections.length > 0 &&
        selections[0]['MFG_Product_ID'] > 0 &&
        (!selections[0]['M_Product_ID'] ||
            (selections[0]['M_Product_ID'] && (!selections[0]['M_Product_ID'].id || selections[0]['M_Product_ID'].id < 0)))
    ) {
      this.messageManager.newMessage(
          new IupicsMessage(this.translateService.instant('specificWindow.productViewMFG.zoomERR'), 'error')
      );
    } else {
      super.zoomOnLineSelected();
    }
  }
}
