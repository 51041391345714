import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { MessageManagerService } from '@iupics-manager/managers/message/message-manager.service';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsMessage } from '@iupics-manager/models/iupics-message';

@Injectable()
export class IuErrorHandler implements ErrorHandler {
  constructor(private messageManager: MessageManagerService) {}

  handleError(error: any): void {
    if (error instanceof HttpErrorResponse) {
      if (Global.activeXHRRequest > 0) {
        Global.activeXHRRequest--;
      }
      console.error(error);
      /**
       * @start_custo_code
       */
      if (error.status > 0) {
        this.messageManager.newMessage({
          name: `${error.name} - ${error.status}`,
          message: error.error.message,
          stack: `${error.message}\n${error.url}`,
        });
      }
      /**
       * @end_custo_code
       */
    } else if (error instanceof IupicsMessage) {
      if (error.type !== 'success') {
        console.error(error);
      }
      this.messageManager.newMessage(error);
    } else {
      console.error(error);
      this.messageManager.newMessage(error);
    }
  }
}
