import { Pipe, PipeTransform, inject } from '@angular/core';
import { DocServerService } from '@compiere-ws/services/doc-server/doc-server.service';
import { AppConfig } from '@iupics-config/app.config';
import { DocUtils } from '@iupics-util/tools/doc.utils';

@Pipe({ name: 'fileUploadLogoFile', standalone: true })
export class PrimeFileUploadLogoFilePipe implements PipeTransform {
  #docServerService = inject(DocServerService);
  #config = inject(AppConfig);

  transform(file: any, fromDocServer: boolean, isImage?: (file: File) => boolean): any {
    if (file.provider === 'external') {
      const url = file.src;
      return { isUrl: false, iconClass: DocUtils.getUrlIcon(this.#config, url) };
    }
    if (isImage?.(file)) {
      return { isUrl: true, url: file.objectURL };
    }

    const fileName = file.name ? file.name.toLowerCase() : '';
    if (fileName && DocUtils.isFileExtensionImage(fileName)) {
      const urlPreview = fromDocServer ? this.#docServerService.getUrlPreview(file.src) : file.src;
      return { isUrl: true, url: urlPreview };
    } else {
      return { isUrl: false, iconClass: DocUtils.getFileIcon(fileName) };
    }
  }
}
