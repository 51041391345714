import {Injectable} from '@angular/core';
import {
    CalloutStatus,
    CompiereDataFieldType,
    CompiereDataGridFilterType,
    CompiereDataGridResponseJSON,
    CompiereDataGridType,
    CompiereDataJSON2,
    DataStore,
    DataStoreKey,
    DataStoreName,
    DataStoreRequest,
    DataStoreSet,
    DataStoreState,
    DataStoreStatus,
} from '@compiere-ws/models/compiere-data-json';
import {CalloutDataJSON, TreeCompiereJSON} from '@compiere-ws/models/compiere-tree-json';
import {InfoWindowDV} from '@compiere-ws/models/info-window-default-value-json';
import {CompiereDataService} from '@compiere-ws/services/compiere-data/compiere-data.service';
import {PoService} from '@compiere-ws/services/po/po.service';
import {OperatorFilterType} from '@iupics-components/models/universal-filter';
import {AbstractDataContainer} from '@iupics-manager/models/abstract-datacontainer';
import {DataConflict} from '@iupics-manager/models/data-conflict';
import {IupicsMessage} from '@iupics-manager/models/iupics-message';
import {IupicsColumnInfo} from '@iupics-manager/models/iupics_column_info';
import {IupicsJsonDef} from '@iupics-manager/models/iupics_json_def';
import {ProcessUI} from '@iupics-manager/models/processUI';
import {LogicEvaluator} from '@iupics-util/tools/logic-evaluator';
import {TranslateService} from '@ngx-translate/core';
import {IupicsMenuType} from '@web-desktop/models/menu-item-ui';
import {environment} from 'environments/environment';
import {cloneDeep, has} from 'lodash';
import * as moment from 'moment';
import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {map, switchMap, tap} from 'rxjs/operators';
import {v4 as uuid} from 'uuid';
import {MessageManagerService} from '../message/message-manager.service';
import {SecurityManagerService} from '../security-manager/security-manager.service';

@Injectable({
    providedIn: 'root',
})
export class DataStoreService {
    private dataStore = {
        windowStore: {
            old: {},
            current: {},
            remote: {},
        },
        specificWindowStore: {
            old: {},
            current: {},
            remote: {},
        },
        processStore: {
            old: {},
            current: {},
            remote: {},
        },
        dataUUIDs: {},
    };
    private newDataStructure = {
        window: {},
        specificWindow: {},
        process: {},
    };

    // private windowCtx: { [windowId: string]: { [domWinId: string]: DataStoreKey[] } } = {};
    private calloutCallBack: any;
    private dateColumnCache: Map<string, boolean> = new Map();
    private iupics_column_info: Map<string, IupicsColumnInfo[]> = new Map();
    private iupics_json_def: Map<number, IupicsJsonDef> = new Map();
    addProcess: BehaviorSubject<ProcessUI> = new BehaviorSubject(null);
    processCheck: BehaviorSubject<boolean> = new BehaviorSubject(false);
    processesDisplay: any[] = [];

    constructor(
        private dataService: CompiereDataService,
        private messageManager: MessageManagerService,
        private translateService: TranslateService,
        private poService: PoService,
        private connectorService: SecurityManagerService
    ) {
    }

    /**
     * Génération d'une clé unique pour la donnée stockée dans le DataStore
     * @param window_id ID de la fenêtre où se trouve la donnée
     * @param tab_id ID de l'onglet où se trouve la donnée
     * @param record_id ID de la donnée
     * @param filter whereClause afin de filtré les données qui sont liées à un parent
     */
    public generateDataStoreKey(
        window_id: number,
        tab_id: number,
        record_id?: string,
        parent_constraint?: string
    ): DataStoreKey {
        let parentId = '';
        if (parent_constraint) {
            parentId = parent_constraint.replace(/=/g, ',');
        }
        record_id = record_id ? String(record_id) : '';
        return {
            windowId: window_id,
            tabId: tab_id,
            parentId: parentId,
            recordId: record_id,
        };
    }

    /*
     * ************************************************************************************************
     * ************************************************************************************************
     * ************************************************************************************************
     * ************************************************************************************************
     * ************************* FOLLOWING METHOD USE CACHE IN STORE **********************************
     * ************************************************************************************************
     * ************************************************************************************************
     * ************************************************************************************************
     * ************************************************************************************************
     */

    public getDataGrid(
        request: DataStoreRequest,
        forceCallWs = false,
        type: string = 'data'
    ): Observable<CompiereDataGridResponseJSON> {
        let dataRequest = cloneDeep(request);
        const dataStorekey = this.generateDataStoreKey(
            dataRequest.windowId,
            dataRequest.compiereRequest.entityId,
            dataRequest.record_id,
            dataRequest.parent_constraint
        );

        dataRequest = this.prepareDataRequest(dataRequest);
        let datas;
        // FROM DataStore si pas d'action particulière (filtre,groupe,pivot,tri)
        if (dataRequest.compiereRequest.windowType === CompiereDataGridType.WINDOW) {
            datas = this.searchDataInStore(dataRequest, dataStorekey, true);
            if (forceCallWs) {
                datas = undefined;
            }
            if (datas) {
                return of(datas);
            }
        }
        // Si pas présent dans le DataStore, on appel le WS
        if (!datas) {
            return this.dataService.getDataGrid(dataRequest.compiereRequest, type).pipe(
                map((dataResponse) => {
                    if (dataResponse && dataResponse.data.length > 0) {
                        this.replaceComplexType(dataResponse);
                        if (
                            dataRequest.compiereRequest.windowType === CompiereDataGridType.WINDOW &&
                            (dataRequest.compiereRequest.rowGroupCols.length <= 0 ||
                                dataRequest.compiereRequest.groupKeys.length === dataRequest.compiereRequest.rowGroupCols.length)
                        ) {
                            this.transformNewDataWSAndStore(dataStorekey, dataResponse, this.dataStore.windowStore.current);
                            datas = this.findInStore(dataStorekey, this.dataStore.windowStore.current);
                            this.copyToOldStore(datas, this.dataStore.windowStore);
                        }
                    }
                    dataResponse.compiereRequest = dataRequest.compiereRequest;
                    return dataResponse;
                })
            );
        }
    }

    public getWindowSingleData(
        request: DataStoreRequest,
        forceCallWs: boolean = false,
        fromZoom: boolean = false
    ): Observable<any> {
        let dataRequest = cloneDeep(request);
        const dataStorekey = this.generateDataStoreKey(
            dataRequest.windowId,
            dataRequest.compiereRequest.entityId,
            dataRequest.record_id,
            dataRequest.parent_constraint
        );
        dataRequest = this.prepareDataRequest(dataRequest);
        // FROM DataStore si pas d'action particulière (filtre,groupe,pivot,tri)
        let datas = this.searchDataInStore(dataRequest, dataStorekey);
        if (forceCallWs) {
            datas = undefined;
        }
        // const hasAllKeys = datas ? this.hasAllKeys(!(datas instanceof DataStore) ? datas[Object.keys(datas)[0]] : datas) : true;
        if (datas && !dataStorekey.recordId) {
            const keys = Object.keys(datas);
            datas = datas[keys[0]];
        }
        if (datas) {
            datas.currentContext = undefined;
            if (!(datas instanceof DataStore)) {
                return of(datas[Object.keys(datas)[0]]);
            } else {
                return of(datas);
            }
        }
        // Si pas présent dans le DataStore, on appel le WS
        if (!datas) {
            return this.dataService.getDataGrid(dataRequest.compiereRequest).pipe(
                map((dataResponse) => {
                    if (fromZoom) {
                        dataResponse.data_UUID = dataResponse.data_UUID.map((data_uuid) => {
                            return data_uuid === 'C_DocBaseType_ID' ? 'DocBaseType' : data_uuid;
                        });
                    }
                    if (dataResponse && dataResponse.data.length > 0) {
                        this.replaceComplexType(dataResponse);
                        this.transformNewDataWSAndStore(dataStorekey, dataResponse, this.dataStore.windowStore.current);
                        datas = this.findInStore(dataStorekey, this.dataStore.windowStore.current);
                        this.copyToOldStore(datas, this.dataStore.windowStore);
                        if (datas instanceof DataStoreSet) {
                            datas = datas.data[datas.orderKeys[0]];
                        }
                        datas.initContextField.emit();
                        datas.currentContext = undefined;
                        return datas;
                    }
                })
            );
        }
    }

    public setStateVisibleOnWindowData(dataStorekey: DataStoreKey): void {
        const datas = <DataStore>this.findInStore(dataStorekey, this.dataStore.windowStore.current);
        datas.state = DataStoreState.VISIBLE;
    }

    public setStateHiddenOnWindowData(dataStorekey: DataStoreKey): void {
        const datas = <DataStore>this.findInStore(dataStorekey, this.dataStore.windowStore.current);
        datas.state = DataStoreState.HIDDEN;
        this.removeFromStore(dataStorekey, this.dataStore.windowStore.old);
    }

    public syncSingleDataWithRemote(
        dataStorekey: DataStoreKey,
        checkConflict = false,
        component?: any,
        userContext?: any,
        callback?: Function,
        callbackParam?: any
    ): Observable<any> {
        let dataRequest = this.initDataRequest(dataStorekey, component, userContext);
        dataRequest = this.prepareDataRequest(dataRequest);
        return this.dataService.getDataGrid(dataRequest.compiereRequest).pipe(
            switchMap((dataResponse) => {
                if (dataResponse) {
                    if (dataResponse.data.length > 0) {
                        return of(dataResponse);
                    }
                    return throwError(() => ({message: 'No Data Found!'}));
                } else {
                    return throwError(() => ({message: 'No response from server'}));
                }
            }),
            map((dataResponse) => {
                if (dataResponse) {
                    return this.handleSyncResponse(dataResponse, dataStorekey, checkConflict, component, callback, callbackParam);
                }
            })
        );
    }

    public syncWithRemoteWindowData(
        dataStorekey: DataStoreKey,
        checkConflict = false,
        component?: any,
        userContext?: any,
        callback?: Function,
        callbackParam?: any
    ): Observable<any> {
        let dataRequest = this.initDataRequest(dataStorekey, component, userContext);
        dataRequest = this.prepareDataRequest(dataRequest);
        return this.dataService.getDataGrid(dataRequest.compiereRequest).pipe(
            map((dataResponse) => {
                return this.handleSyncResponse(dataResponse, dataStorekey, checkConflict, component, callback, callbackParam);
            })
        );
    }

    public syncDataChanges(
        dataStored: DataStore,
        newData: any,
        isForced = false,
        bypassValidation = false,
        calloutStack: string[] = [],
        fromDefaultValue = false,
        shouldSimulateKeys = false
    ) {
        const dataModified = {};
        let keys = null;
        if (!shouldSimulateKeys && dataStored && dataStored.key && dataStored.key.tabId) {
            keys = Object.keys(this.newDataStructure.window[dataStored.key.tabId]);
        } else {
            keys = Object.keys(newData);
        }
        const newDataKeys = Object.keys(newData).filter((key) => !keys || keys.includes(key));
        for (let i = 0; i < newDataKeys.length; i++) {
            const columnName = newDataKeys[i];
            if (isForced) {
                dataModified[columnName] = newData[columnName];
                dataStored.data[columnName] = newData[columnName];
            } else {
                if (typeof newData[columnName] === 'boolean') {
                    newData[columnName] = newData[columnName] ? 'Y' : 'N';
                }
                if (dataStored.data[columnName] && dataStored.data[columnName].hasOwnProperty('id')) {
                    if (newData[columnName] && newData[columnName].hasOwnProperty('id')) {
                        if (JSON.stringify(dataStored.data[columnName]) !== JSON.stringify(newData[columnName])) {
                            dataModified[columnName] = newData[columnName];
                            dataStored.data[columnName] = newData[columnName];
                        }
                    } else {
                        if (dataStored.data[columnName].id != newData[columnName]) {
                            dataModified[columnName] = newData[columnName];
                            dataStored.data[columnName] = newData[columnName];
                        }
                    }
                } else {
                    if (dataStored.data[columnName] != newData[columnName]) {
                        dataModified[columnName] = newData[columnName];
                        dataStored.data[columnName] = newData[columnName];
                    }
                }
            }
            // mise à jour du context réutilisable
            if (dataStored && dataStored.currentContext) {
                dataStored.currentContext[columnName] = has(newData[columnName], 'id')
                    ? newData[columnName].id
                    : newData[columnName];
            }
        }

        /*On vérifie si le store a changé */
        this.updateStoreStatus(dataStored);
        // if (dataStored.status === DataStoreStatus.SYNC) {
        //   dataStored.status = DataStoreStatus.NOTSYNC;
        // }
        if (dataStored.dataChange) {
            dataStored.dataChange.emit({
                dataModified: dataModified,
                bypassValidation: bypassValidation,
                calloutStack: calloutStack,
                fromDefaultValue: fromDefaultValue,
            });
        }
    }

    public updateStoreWithoutFields(dataStorekey: DataStoreKey, dataStored: DataStore, newData: any) {
        const dataStoredTmp = <DataStore>this.findInStore(dataStorekey, this.dataStore.windowStore.current);
        this.syncDataChanges(dataStoredTmp, newData, true);
    }

    #columnToAvoid = new Set(['Updated', 'UpdatedBy', 'Created', 'CreatedBy']);

    public updateStoreStatus(currentStore: DataStore) {
        if (currentStore.key && currentStore.status !== DataStoreStatus.NEWRECORD) {
            const oldStore = this.findInStore(currentStore.key, this.dataStore.windowStore.old);
            let hasChanges = false;
            let keys = null;
            if (currentStore && currentStore.key && currentStore.key.tabId) {
                keys = Object.keys(this.newDataStructure.window[currentStore.key.tabId]);
            } else {
                keys = Object.keys(currentStore.data);
            }
            for (const columnName of keys) {
                if (!this.#columnToAvoid.has(columnName)) {
                    if (!this.isStoreValueEquals(currentStore.data[columnName], oldStore.data[columnName], columnName)) {
                        hasChanges = true;
                    }
                }
            }
            if (hasChanges) {
                currentStore.status = DataStoreStatus.NOTSYNC;
            } else {
                currentStore.status = DataStoreStatus.SYNC;
            }
        }
    }

    private isStoreValueEquals(value1: any, value2: any, columnName: string) {
        let isEquals = true;
        // date case
        if (this.dateColumnCache.has(columnName)) {
            isEquals =
                (this.dateColumnCache.get(columnName)
                    ? moment(value1).format('YYYY-MM-DDTHH:mm:ss.SSS')
                    : moment(value1).format('L')) ===
                (this.dateColumnCache.get(columnName)
                    ? moment(value2).format('YYYY-MM-DDTHH:mm:ss.SSS')
                    : moment(value2).format('L'));
        }
        // simple case
        else if (value1 != value2 && !(value1 instanceof Object) && !(value2 instanceof Object)) {
            if (
                columnName === 'AD_Org_ID' ||
                (!(value1 === null && (value2 === '' || value2 === 0)) && !(value2 === null && (value1 === '' || value1 === 0)))
            ) {
                // input text & auto & number)
                isEquals = false;
            }
        }
        // complex case
        else {
            // id or value
            const val1 = value1 instanceof Object ? value1.id : value1;
            const val2 = value2 instanceof Object ? value2.id : value2;
            if (val1 != val2) {
                isEquals = false;
            }
        }
        // prise en compte du cas des null étant un espace dans le oldstore ou remote (oldValue:' '|currentValue:null)
        if (value1 && value1.trim && value1.trim() === '' && value2 === null) {
            isEquals = true;
        }
        // prise en compte du cas des yes-no null dans le oldstore ou remote
        if ((value1 === null && value2 === 'N') || (value2 === null && value1 === 'N')) {
            isEquals = true;
        }
        return isEquals;
    }

    /**
     * Renvoie null si il y a des erreurs
     * @param { DataStoreKey[] } dataStorekeys accepte une ou plusieurs DataStoreKey(s). Si un tableau est passé dans la méthode, celui-ci doit être spreadé => exemple: saveWindowData(...array);
     */
    public saveWindowData(dataStorekeys: DataStoreKey[], windowCtx?: DataStore): Observable<any> {
        const dataStoreds = new Map<DataStoreKey, DataStore>();
        const dataUUID = this.dataStore.dataUUIDs[dataStorekeys[0].tabId];
        const tabId = dataStorekeys[0].tabId;
        const dataWs: CompiereDataJSON2 = {
            data: [],
            data_UUID: dataUUID,
            displayData: {},
            secondaryColumnFields: [],
            lastRow: 0,
            tab_id: tabId,
        };
        for (const dataStorekey of dataStorekeys) {
            const dataStored = <DataStore>this.findInStore(dataStorekey, this.dataStore.windowStore.current);
            if (dataStored) {
                let dataReformat = Object.assign({}, dataStored.data);
                for (const key of Object.keys(dataReformat)) {
                    if (dataReformat[key] instanceof Object) {
                        dataReformat[key] = dataReformat[key].id;
                    } else if (typeof dataReformat[key] === 'string' && String(dataReformat[key]).startsWith('~@')) {
                        dataReformat[key] = String(dataReformat[key]).replace(/~/g, '');
                        dataReformat[key] = LogicEvaluator.replaceVariables(
                            dataReformat[key],
                            this.connectorService.getIupicsUserContext(),
                            windowCtx
                        );
                    }
                }
                dataReformat['newRecord'] = dataStored.status === DataStoreStatus.NEWRECORD ? 'Y' : 'N';
                if (windowCtx) {
                    dataReformat = Object.assign(windowCtx, dataReformat);
                }
                dataWs.data.push(dataReformat);
                dataStoreds.set(dataStorekey, dataStored);
            }
        }
        return this.dataService.saveData(dataWs).pipe(
            map((newDatasWS) => {
                const errorMessages: string[] = [];
                const warningMessages: string[] = [];
                const successMessages: string[] = [];
                if (newDatasWS && newDatasWS.data.length > 0) {
                    for (const data of newDatasWS.data) {
                        if (data['apiz_dataResult']) {
                            if (data['apiz_dataResult']['responseError']) {
                                for (const error of data['apiz_dataResult']['responseError']) {
                                    errorMessages.push(error.message);
                                }
                            }
                            if (data['apiz_dataResult']['responseWarning']) {
                                for (const warning of data['apiz_dataResult']['responseWarning']) {
                                    warningMessages.push(warning.message);
                                }
                            }
                            if (data['apiz_dataResult']['responseSuccess']) {
                                for (const success of data['apiz_dataResult']['responseSuccess']) {
                                    const index = successMessages.findIndex(
                                        (s: string) => s.trim().toLowerCase().indexOf(success.message.trim().toLowerCase()) >= 0
                                    );
                                    if (index >= 0) {
                                        const opt_nb = successMessages[index].replace(new RegExp(success.message, 'g'), '').trim();
                                        if (isNaN(parseInt(opt_nb, 10))) {
                                            successMessages[index] = `2 ${success.message}`;
                                        } else {
                                            const nb = parseInt(opt_nb, 10) + 1;
                                            successMessages[index] = `${nb} ${success.message}`;
                                        }
                                    } else {
                                        successMessages.push(success.message);
                                    }
                                }
                            }
                        }
                    }
                    this.replaceComplexType(newDatasWS);
                }
                let i = 0;
                for (let dataStorekey of dataStorekeys) {
                    const linkedDatastored = dataStoreds.get(dataStorekey);
                    const oldDatastoredKey = Object.assign({}, dataStorekey);
                    if (newDatasWS && newDatasWS.data.length > 0) {
                        const data = newDatasWS.data[i++];
                        if (!data['apiz_dataResult'] || !data['apiz_dataResult']['responseError']) {
                            this.copyToOldStore(linkedDatastored, this.dataStore.windowStore);
                            dataStorekey = this.transformOneNewDataWsAndStore(
                                dataStorekey,
                                data,
                                this.dataStore.windowStore.remote,
                                newDatasWS
                            );
                            const dataRemote = <DataStore>this.findInStore(dataStorekey, this.dataStore.windowStore.remote);
                            linkedDatastored.data = {...dataRemote.data};
                            linkedDatastored.key = dataStorekey;
                            this.syncDataChanges(linkedDatastored, dataRemote.data);
                            this.removeFromStore(oldDatastoredKey, this.dataStore.windowStore.current);
                            this.store(dataStorekey, linkedDatastored, this.dataStore.windowStore.current);
                            this.copyToOldStore(dataRemote, this.dataStore.windowStore);
                            this.removeFromStore(dataStorekey, this.dataStore.windowStore.remote);
                            this.store(dataStorekey, dataRemote, this.dataStore.windowStore.remote);
                            linkedDatastored.status = DataStoreStatus.SYNC;
                        }
                    }
                }
                if (errorMessages.length > 0) {
                    this.messageManager.newMessage(
                        new IupicsMessage(
                            this.translateService.instant('editView.saveMessageTitle'),
                            errorMessages.join('\n'),
                            'error'
                        )
                    );
                    return null;
                } else {
                    this.messageManager.newMessage(
                        new IupicsMessage(
                            this.translateService.instant('editView.saveMessageTitle'),
                            warningMessages.length > 0 && successMessages.length > 0
                                ? [...warningMessages, ...successMessages].join('\n')
                                : warningMessages.length > 0 && successMessages.length === 0
                                    ? warningMessages.join('\n')
                                    : warningMessages.length === 0 && successMessages.length > 0
                                        ? successMessages.join('\n')
                                        : this.translateService.instant('editView.saveMessage'),
                            warningMessages.length > 0 ? 'warning' : 'success'
                        )
                    );
                    return dataStoreds;
                }
            })
        );
    }

    /**
     * Utilise le service de sauvegarde des données utilisés par les fenêtres standard
     * @param dataStore Clé du DataStore
     * @param tab_id ID de l'onglet correspondant aux données à sauver
     * @param data_UUIDs tableau de colonnes correspondantes à la clé primaire
     * @param windowCtx contexte
     * @returns
     */
    public saveSpecificWindowData(
        dataStore: DataStore,
        tab_id: number,
        data_UUIDs: string[],
        windowCtx?: DataStore
    ): Observable<any> {
        const dataWs: CompiereDataJSON2 = {
            data: [],
            data_UUID: data_UUIDs,
            displayData: {},
            secondaryColumnFields: [],
            lastRow: 0,
            tab_id,
        };
        let dataReformat = Object.assign({}, dataStore.data);
        for (const key of Object.keys(dataReformat)) {
            if (dataReformat[key] instanceof Object) {
                dataReformat[key] = dataReformat[key].id;
            } else if (typeof dataReformat[key] === 'string' && String(dataReformat[key]).startsWith('~@')) {
                dataReformat[key] = String(dataReformat[key]).replace(/~/g, '');
                dataReformat[key] = LogicEvaluator.replaceVariables(
                    dataReformat[key],
                    this.connectorService.getIupicsUserContext(),
                    windowCtx
                );
            }
        }
        let actualRecord = true;
        for (let i = 0; i < data_UUIDs.length; i++) {
            const data_UUID = data_UUIDs[i];
            actualRecord = actualRecord && dataReformat[data_UUID] !== undefined && dataReformat[data_UUID] !== null;
        }
        delete dataReformat['Record_ID'];
        dataReformat['newRecord'] = actualRecord ? 'N' : 'Y';
        // dataReformat['newRecord'] = dataStore.status === DataStoreStatus.NEWRECORD ? 'Y' : 'N';
        if (windowCtx) {
            dataReformat = Object.assign(windowCtx, dataReformat);
        }
        dataWs.data.push(dataReformat);
        this.copyToOldStore(dataStore, this.dataStore.specificWindowStore);
        return this.dataService.saveData(dataWs).pipe(
            map((datasWS) => {
                const errorMessages: string[] = [];
                const warningMessages: string[] = [];
                const successMessages: string[] = [];
                if (datasWS && datasWS.data.length > 0) {
                    for (const data of dataWs.data) {
                        if (data['apiz_dataResult']) {
                            if (data['apiz_dataResult']['responseError']) {
                                for (const error of data['apiz_dataResult']['responseError']) {
                                    errorMessages.push(error.message);
                                }
                            }
                            if (data['apiz_dataResult']['responseWarning']) {
                                for (const warning of data['apiz_dataResult']['responseWarning']) {
                                    warningMessages.push(warning.message);
                                }
                            }
                            if (data['apiz_dataResult']['responseSuccess']) {
                                for (const success of data['apiz_dataResult']['responseSuccess']) {
                                    const index = successMessages.findIndex(
                                        (s: string) => s.trim().toLowerCase().indexOf(success.message.trim().toLowerCase()) >= 0
                                    );
                                    if (index >= 0) {
                                        const opt_nb = successMessages[index].replace(new RegExp(success.message, 'g'), '').trim();
                                        if (isNaN(parseInt(opt_nb, 10))) {
                                            successMessages[index] = `2 ${success.message}`;
                                        } else {
                                            const nb = parseInt(opt_nb, 10) + 1;
                                            successMessages[index] = `${nb} ${success.message}`;
                                        }
                                    } else {
                                        successMessages.push(success.message);
                                    }
                                }
                            }
                        }
                    }
                    this.replaceComplexType(datasWS);
                }
                if (datasWS && datasWS.data.length > 0) {
                    const data = datasWS.data[0];
                    if (!data['apiz_dataResult'] || !data['apiz_dataResult']['responseError']) {
                        this.syncDataChanges(dataStore, data, false, true);
                        // dataStore.status = DataStoreStatus.SYNC;
                    }
                }
                if (errorMessages.length > 0) {
                    this.messageManager.newMessage(
                        new IupicsMessage(
                            this.translateService.instant('editView.saveMessageTitle'),
                            errorMessages.join('\n'),
                            'error'
                        )
                    );
                    return null;
                } else {
                    this.messageManager.newMessage(
                        new IupicsMessage(
                            this.translateService.instant('editView.saveMessageTitle'),
                            warningMessages.length > 0 && successMessages.length > 0
                                ? [...warningMessages, ...successMessages].join('\n')
                                : warningMessages.length > 0 && successMessages.length === 0
                                    ? warningMessages.join('\n')
                                    : warningMessages.length === 0 && successMessages.length > 0
                                        ? successMessages.join('\n')
                                        : this.translateService.instant('editView.saveMessage'),
                            warningMessages.length > 0 ? 'warning' : 'success'
                        )
                    );
                    return dataStore;
                }
            })
        );
    }

    public getWindowSpecificData(tableName: string, datastore: DataStore, id: number): Observable<any> {
        this.copyToOldStore(datastore, this.dataStore.specificWindowStore);
        return this.poService
            .get(tableName, id)
            .pipe(tap((result) => this.syncDataChanges(datastore, result, false, true)));
    }

    /**
     * Utilise le poService pour sauver des données en backend
     * @deprecated Le PO service n'accepte pas les champs dont la valeur vaut null. Ce champ sera supprimé à l'arrivée au niveau des WS
     * @param tableName Nom de la table
     * @param datastore Données à sauver
     * @param id ID de l'enregistrement à sauver
     * @returns
     */
    public saveWindowSpecificData(tableName: string, datastore: DataStore, id?: number) {
        const dataReformat = Object.assign({}, datastore.data);
        const keys = Object.keys(dataReformat);
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            if (dataReformat[key] instanceof Object) {
                dataReformat[key] = dataReformat[key].id;
            }
        }
        this.copyToOldStore(datastore, this.dataStore.specificWindowStore);
        return this.poService
            .save(tableName, dataReformat, id)
            .pipe(tap((result) => this.syncDataChanges(datastore, result, false, true)));
    }

    public deleteWindowSpecificData(tableName: string, datastore: DataStore, id?: number): Observable<any> {
        return this.poService.delete(tableName, id).pipe(
            map((responses) => {
                const errors = {};
                let success = 0;
                if (responses) {
                    for (const response of responses) {
                        if (response.messages[0].type === 'ERROR') {
                            if (response.messages[0] !== undefined) {
                                if (!errors[response.messages[0].message]) {
                                    errors[response.messages[0].message] = 0;
                                }
                                errors[response.messages[0].message]++;
                            }
                        } else {
                            success++;
                            this.removeFromStore(datastore.key, this.dataStore.windowStore.old);
                            this.removeFromStore(datastore.key, this.dataStore.windowStore.remote);
                            this.removeFromStore(datastore.key, this.dataStore.windowStore.current);
                        }
                    }
                    return {errors, success};
                } else {
                    return of({});
                }
            })
        );
    }

    // TODO A utiliser quand ils sera correctement implementé coté back https://gitlab.audaxis.com/iupics/iupics/issues/238 idem que delete
    // public saveWindowMultiData(dataStorekeys: DataStoreKey[]): Observable<any> {
    //   const dataStoredFirst = <DataStore>this.findInStore(dataStorekeys[0], this.dataStore.windowStore.current);
    //   if (dataStoredFirst) {
    //     // set des columnNames dans Fields et du tabId
    //     let columnsName = ['Data_UUID'];
    //     columnsName = [
    //       ...columnsName,
    //       ...Object.keys(dataStoredFirst.data).filter(
    //         key => key !== 'Created' && key !== 'CreatedBy' && key !== 'Updated' && key !== 'UpdatedBy' && key !== 'Data_UUID'
    //       )
    //     ];
    //     const localData: CompiereDataJSON = {
    //       data: [],
    //       fields: columnsName,
    //       tabId: dataStorekeys[0].tabId
    //     };

    //     dataStorekeys.forEach((dataStorekey, index) => {
    //       // const oldDatastoredKey = Object.assign({}, dataStorekey);
    //       const dataStored = <DataStore>this.findInStore(dataStorekey, this.dataStore.windowStore.current);
    //       const isNewRecord = dataStored.status === DataStoreStatus.NEWRECORD;

    //       const dataTmp = [];
    //       columnsName.forEach(key => {
    //         dataTmp.push(dataStored.data[key]);
    //       });
    //       if (isNewRecord) {
    //         dataTmp[0] = null;
    //       }
    //       localData.data.push(dataTmp);
    //       this.copyToOldStore(dataStored, this.dataStore.windowStore);
    //     });
    //     if (localData.data.length > 0) {
    //       return this.dataService.saveData(localData).pipe(
    //         map(datasWS => {
    //           // datasWS.fields = datasWS.fields.slice(0, datasWS.data[0].length);
    //           // data from ws
    //           // this.transformDataWSAndStore(dataStorekey, datasWS, this.dataStore.windowStore.remote);
    //           // if (datasWS && datasWS.data && datasWS.data[0].length > 0) {
    //           //   dataStorekey.recordId = this.getRecordIdString(datasWS.data[0][0]);
    //           // }
    //           // const dataRemote = this.findInStore(dataStorekey, this.dataStore.windowStore.remote);
    //           // this.syncDataChanges(dataStored, dataRemote.data);
    //           // if (dataStored.status === DataStoreStatus.NEWRECORD) {
    //           //   this.removeFromStore(oldDatastoredKey, this.dataStore.windowStore.current);
    //           //   this.store(dataStorekey, dataStored, this.dataStore.windowStore.current);
    //           //   this.store(dataStorekey, dataStored, this.dataStore.windowStore.old);
    //           //   this.removeFromStore(dataStorekey, this.dataStore.windowStore.remote);
    //           //   this.store(dataStorekey, dataStored, this.dataStore.windowStore.remote);
    //           // }
    //           // dataStored.status = DataStoreStatus.SYNC;
    //           // return dataStored;
    //           return true;
    //         })
    //       );
    //     } else {
    //       return of(false);
    //     }
    //   }
    // }

    public deleteWindowData(dataStorekey: DataStoreKey, recordIds: any[]): Observable<any> {
        return this.dataService.deleteData(dataStorekey.tabId, recordIds).pipe(
            map((responses) => {
                const errors = [];
                let success = 0;
                if (responses) {
                    for (const response of responses) {
                        if (!response.messages || !response.messages[0] || response.messages[0].type === 'ERROR') {
                            // create Id
                            let id = '';
                            for (const key of Object.keys(response)) {
                                if (key !== 'messages') id += key + ',' + response[key];
                            }
                            if (response.messages[0]) {
                                if (id !== '') {
                                    errors.push({id: id, message: response.messages[0].message});
                                }
                            }
                        } else {
                            success++;
                            const dataUUIDs = this.getDataUUIDFromTabID(dataStorekey.tabId);
                            let recordID = '';
                            for (const dataUUID of dataUUIDs) {
                                recordID += dataUUID + ',' + response[dataUUID];
                            }
                            const currentDataStoreKey = Object.assign(dataStorekey);
                            currentDataStoreKey.recordId = recordID;
                            this.removeFromStore(currentDataStoreKey, this.dataStore.windowStore.old);
                            this.removeFromStore(currentDataStoreKey, this.dataStore.windowStore.remote);
                            this.removeFromStore(currentDataStoreKey, this.dataStore.windowStore.current);
                        }
                    }
                    return {errors, success};
                } else {
                    return of({});
                }
            })
        );
    }

    public deleteDataFromStoreOnly(dataStorekey: DataStoreKey) {
        this.removeFromStore(dataStorekey, this.dataStore.windowStore.current);
    }

    public isWindowNewData(dataStorekey: DataStoreKey): boolean {
        const result = this.findInStore(dataStorekey, this.dataStore.windowStore.current);
        if (result) {
            return (<DataStore>result).status === DataStoreStatus.NEWRECORD;
        } else {
            return false;
        }
    }

    public isWindowDataSYNC(dataStorekey: DataStoreKey): boolean {
        return (
            (<DataStore>this.findInStore(dataStorekey, this.dataStore.windowStore.current)).status === DataStoreStatus.SYNC
        );
    }

    public addWindowDataStructure(tab_id: number, obj: {}) {
        this.newDataStructure.window[tab_id] = obj;
    }

    public newWindowData(
        window_id: number,
        tab_id: number,
        filter?: string,
        parentDatastoreKey?: DataStoreKey
    ): DataStore {
        const dataStorekey = this.generateDataStoreKey(window_id, tab_id, uuid(), filter);
        if (parentDatastoreKey) {
            dataStorekey.parentId = parentDatastoreKey.recordId;
        }
        const dataTransformed: DataStore = new DataStore();
        Object.assign(dataTransformed.data, this.newDataStructure.window[tab_id]);
        dataTransformed.data['Data_UUID'] = dataStorekey.recordId;

        dataTransformed.status = DataStoreStatus.NEWRECORD;
        this.store(dataStorekey, dataTransformed, this.dataStore.windowStore.current);
        return dataTransformed;
    }

    public newRecord(dsKey: DataStoreKey, windowCtx: any): Observable<any> {
        return this.dataService.getNewRecord(dsKey.tabId, windowCtx).pipe(
            map((newData) => {
                const datas = this.findInStore(dsKey, this.dataStore.windowStore.current) as DataStore;
                Object.assign(datas.data, newData);
                if (datas.currentContext != null && datas.currentContext != undefined) {
                    Object.assign(datas.currentContext, datas.data);
                }
                datas.data['Data_UUID'] = dsKey.recordId;
                datas.status = DataStoreStatus.NEWRECORD;
                this.store(dsKey, datas, this.dataStore.windowStore.current);
                return datas;
            })
        );
    }

    public cleanDataStore() {
        this.dataStore = {
            windowStore: {
                current: {},
                remote: {},
                old: {},
            },
            specificWindowStore: {
                old: {},
                current: {},
                remote: {},
            },
            processStore: {
                old: {},
                current: {},
                remote: {},
            },
            dataUUIDs: {},
        };
    }

    public removeWindowData(dataStoreKey: DataStoreKey) {
        this.removeFromStore(dataStoreKey, this.dataStore.windowStore.current);
        this.removeFromStore(dataStoreKey, this.dataStore.windowStore.old);
        this.removeFromStore(dataStoreKey, this.dataStore.windowStore.remote);
    }

    public copyWindowDataToOldStore(dataStored: DataStore) {
        this.copyToOldStore(dataStored, this.dataStore.windowStore);
    }

    public copyRemoteWindowDataToOldStore(dataStoreKey: DataStoreKey, columnName: string) {
        const dataStoreRemote = <DataStore>this.findInStore(dataStoreKey, this.dataStore.windowStore.remote);
        const dataStoreOld = <DataStore>this.findInStore(dataStoreKey, this.dataStore.windowStore.old);
        if (dataStoreRemote.data[columnName] instanceof Object) {
            dataStoreOld.data[columnName] = Object.assign({}, dataStoreRemote.data[columnName]);
        } else {
            dataStoreOld.data[columnName] = dataStoreRemote.data[columnName];
        }
    }

    public addProcessDataStructure(process_id: number, obj: {}) {
        this.newDataStructure.process[process_id] = obj;
    }

    public newProcessData(process_id: number, parentDatastore?: DataStore): DataStore {
        const dataStorekey = this.generateDataStoreKey(process_id, 0, uuid());
        const dataTransformed: DataStore = new DataStore(IupicsMenuType.PROCESS);
        Object.assign(dataTransformed.data, this.newDataStructure.process[process_id]);
        dataTransformed.data['Data_UUID'] = dataStorekey.recordId;
        dataTransformed.status = DataStoreStatus.NEWRECORD;
        if (parentDatastore) {
            // context du parent
            Object.assign(dataTransformed.data, parentDatastore.data);
        }
        this.store(dataStorekey, dataTransformed, this.dataStore.processStore.current);
        return dataTransformed;
    }

    public addSpecificWindowDataStructure(form_id: number, obj: {}) {
        this.newDataStructure.specificWindow[form_id] = obj;
    }

    public newSpecificWindowData(form_id: number, parentDatastore?: DataStore): DataStore {
        const dataStorekey = this.generateDataStoreKey(form_id, 0, uuid());
        const dataTransformed: DataStore = new DataStore(IupicsMenuType.FORM);
        dataTransformed.data = cloneDeep(this.newDataStructure.specificWindow[form_id]) || {};
        dataTransformed.data['Data_UUID'] = dataStorekey.recordId;
        dataTransformed.status = DataStoreStatus.NEWRECORD;
        if (parentDatastore) {
            // context du parent
            Object.assign(dataTransformed.data, parentDatastore.data);
        }
        this.store(dataStorekey, dataTransformed, this.dataStore.specificWindowStore.current);
        return dataTransformed;
    }

    public saveDateColumn(columnName: string, refID: number) {
        this.dateColumnCache.set(columnName, refID === 16);
    }

    /*
     * ************************************************************************************************
     * ************************************************************************************************
     * ************************************************************************************************
     * ************************************************************************************************
     * ************************* FOLLOWING METHOD NOT USE CACHE IN STORE ******************************
     * ************************************************************************************************
     * ************************************************************************************************
     * ************************************************************************************************
     * ************************************************************************************************
     */
    public getDataTree(id: number, isTreeId = false): Observable<TreeCompiereJSON> {
        return this.dataService.getDataTree(id, isTreeId);
    }

    public calloutData(
        url: string,
        data: CalloutDataJSON,
        dataStore: DataStore,
        dataContainer: AbstractDataContainer,
        callBack?: any
    ) {
        if (data && data.oldValue === data.newValue) {
            data.oldValue = null;
        }
        if (dataContainer) {
            Object.assign(data.windowCtx, dataContainer.getCurrentContext());
        }
        if (!data) {
            console.log('error using callout without data');
        }
        let shouldCall = true;
        const columnName = data ? data.columnName : null;
        const calloutStack = data ? (data.calloutStack ? data.calloutStack : []) : [];
        delete data.columnName;
        delete data.calloutStack;
        if (
            calloutStack.includes(columnName) ||
            (dataStore.calloutStates.get(columnName) && dataStore.calloutStates.get(columnName) === CalloutStatus.LOADING)
        ) {
            if (
                dataStore.calloutStates.get(columnName) &&
                dataStore.calloutStates.get(columnName) === CalloutStatus.WAITING
            ) {
                dataStore.startCallout(columnName);
            } else {
                shouldCall = false;
            }
        } else {
            dataStore.startCallout(columnName);
            calloutStack.push(columnName);
        }
        if (shouldCall) {
            const sub$ = this.dataService.calloutData(url, data).subscribe({
                next: (newData) => {
                    newData['dataChanged'] = Object.assign(newData['dataChanged'], newData['ctxChanged']);
                    this.syncDataChanges(dataStore, newData['dataChanged'], false, true, calloutStack, false, true);
                    if (dataContainer && dataContainer.editViewParent) {
                        dataContainer.editViewParent.updateEditTabsVisibility(dataStore);
                    }
                    dataStore.endCallout(columnName);
                    if (callBack) {
                        this.calloutCallBack = callBack;
                    }
                    if (this.calloutCallBack && dataStore.calloutStates.size === 0) {
                        this.calloutCallBack();
                        this.calloutCallBack = undefined;
                    }
                    if (newData && newData['dataChanged'].hasOwnProperty('apiz_dataResult')) {
                        const responseError = [];
                        const responseWarning = [];
                        if (newData['dataChanged']['apiz_dataResult']['responseError']) {
                            responseError.push(...newData['dataChanged']['apiz_dataResult']['responseError'].map((e) => e.message));
                        }
                        if (newData['dataChanged']['apiz_dataResult']['responseWarning']) {
                            responseWarning.push(
                                ...newData['dataChanged']['apiz_dataResult']['responseWarning'].map((e) => e.message)
                            );
                        }

                        const type = responseError.length > 0 ? 'error' : responseWarning.length > 0 ? 'warning' : 'message';
                        const message = [...responseError, ...responseWarning].join('\n');
                        const title = this.translateService.instant('generic.warning');
                        if (type !== 'message') {
                            this.messageManager.newMessage(new IupicsMessage(title, message, type));
                        }
                    }
                    sub$.unsubscribe();
                },
                error: (err) => {
                    this.syncDataChanges(dataStore, {[columnName]: data.newValue}, false, true, calloutStack, false, true);
                    if (dataContainer && dataContainer.editViewParent) {
                        dataContainer.editViewParent.updateEditTabsVisibility(dataStore);
                    }
                    dataStore.endCallout(columnName);
                    if (callBack) {
                        this.calloutCallBack = callBack;
                    }
                    if (this.calloutCallBack && dataStore.calloutStates.size === 0) {
                        this.calloutCallBack();
                        this.calloutCallBack = undefined;
                    }
                    sub$.unsubscribe();
                },
            });
        }
    }

    public saveDataTree(data: TreeCompiereJSON): Observable<TreeCompiereJSON> {
        return this.dataService.saveDataTree(data);
    }

    public getAutocompleteData(
        fieldType: CompiereDataFieldType,
        entityId: number,
        isSearch: boolean = false,
        query?: string,
        validation?: string, fromFilter: boolean = false
    ): Observable<any> {
        return this.dataService.getDataForAutocomplete(fieldType, entityId, isSearch, query, validation, fromFilter);
    }

    public getAutocompleteDataById(
        fieldType: CompiereDataFieldType,
        entityId: number,
        id: any,
        validation?: string, fromFilter: boolean = false
    ): Observable<any> {
        return this.dataService.getDataForAutocompleteById(fieldType, entityId, id, validation, fromFilter);
    }

    public saveLocation(dataWS: CompiereDataJSON2): Observable<any> {
        return this.dataService.saveData(dataWS).pipe(
            map((datasWS) => {
                this.replaceComplexType(datasWS);
                return datasWS;
            })
        );
    }

    // TODO REPLACE BY GenerateRecordIDString
    /**
     * @deprecated
     */
    public getRecordIdString(recordIdObj: {}): string {
        if (!recordIdObj) {
            return undefined;
        }
        if (typeof recordIdObj !== 'object') {
            return String(recordIdObj);
        }
        let recordId = '';
        for (const key of Object.keys(recordIdObj)) {
            if (recordId) {
                recordId += ',';
            }
            recordId += key + ',' + recordIdObj[key];
        }
        return recordId;
    }

    public generateRecordIdString(columnsID: string[], data: {}): string {
        if (!data) {
            return undefined;
        }
        if (typeof data !== 'object') {
            return String(data);
        }
        let recordId = '';
        for (const columnName of columnsID) {
            // Ce test car pour les id qui vaut 0 (AD_Org) ça renvoi faux
            const value =
                data[columnName] !== undefined && data[columnName] !== null ? data[columnName] : data[columnName.toUpperCase()];
            if (data[columnName] === undefined || data[columnName] === null) {
                return undefined;
            }
            if (recordId) {
                recordId += ',';
            }
            recordId += columnName + ',' + value;
        }
        if (recordId === '') {
            recordId = data['Data_UUID'] ? data['Data_UUID'] : uuid();
        }
        return recordId;
    }

    public transformDataStoredToAray(dataStore: any): any[] {
        const dataArray = [];
        for (const key of Object.keys(dataStore)) {
            dataArray.push(dataStore[key]);
        }
        return dataArray;
    }

    public getChangeLog(table_id: number, objectMap: any): Observable<any> {
        return this.dataService.getChangeLog(table_id, objectMap);
    }

    // public addToWindowCtx(windowId: number, domWinId: string, dsKey: DataStoreKey) {
    //   if (this.windowCtx[windowId] === undefined || this.windowCtx[windowId] === null) {
    //     this.windowCtx[windowId] = {};
    //   }
    //   if (this.windowCtx[windowId][domWinId] === undefined || this.windowCtx[windowId][domWinId] === null) {
    //     this.windowCtx[windowId][domWinId] = [];
    //   }
    //   if (
    //     this.windowCtx[windowId][domWinId].findIndex(
    //       key => dsKey.windowId === key.windowId && dsKey.tabId === key.tabId && dsKey.recordId === key.recordId
    //     ) === -1
    //   ) {
    //     this.windowCtx[windowId][domWinId].push(dsKey);
    //   }
    // }

    // public hasWindowCtx(windowId: number, domWinId: string) {
    //   return (
    //     this.windowCtx &&
    //     this.windowCtx[windowId] &&
    //     this.windowCtx[windowId][domWinId] &&
    //     this.windowCtx[windowId][domWinId].length > 0
    //   );
    // }

    /**
     *
     * @param windowId L'AD_Window_ID de la fenêtre
     * @param domWinId Le domWinId de la fenêtre (souvent stocké dans le tabUI [example: this.container.activeTab.domWinId])
     */
    // public getWindowCtx(windowId: number, domWinId: string) {
    //   if (this.windowCtx[windowId] && this.windowCtx[windowId][domWinId] && this.windowCtx[windowId][domWinId].length > 0) {
    //     let windowCtx = {};

    //     for (let i = 0; i < this.windowCtx[windowId][domWinId].length; i++) {
    //       const key = this.windowCtx[windowId][domWinId][i];
    //       const dataStore = this.findInStore(key, this.dataStore.windowStore.current) as DataStore;
    //       windowCtx = Object.assign(windowCtx, cloneDeep(dataStore.data));
    //     }

    //     return windowCtx;
    //   }
    //   return {};
    // }

    // public removeFromWindowCtx(windowId: number, domWinId: string, dsKey?: DataStoreKey) {
    //   if (this.windowCtx[windowId] && this.windowCtx[windowId][domWinId] && this.windowCtx[windowId][domWinId].length > 0) {
    //     if (dsKey) {
    //       const index = this.windowCtx[windowId][domWinId].findIndex(
    //         key => dsKey.windowId === key.windowId && dsKey.tabId === key.tabId && dsKey.recordId === key.recordId
    //       );
    //       this.windowCtx[windowId][domWinId].splice(index, 1);
    //     } else {
    //       this.windowCtx[windowId][domWinId] = [];
    //     }
    //   }
    // }

    public checkDataBeforeNewLine(dataStoreKey): boolean {
        let result = false;
        const dataStoreCurrent = <DataStore>this.findInStore(dataStoreKey, this.dataStore.windowStore.current);
        if (dataStoreCurrent) {
            result = dataStoreCurrent.status === DataStoreStatus.SYNC;
        }
        return result;
    }

    public getDataUUIDFromTabID(tabID: number) {
        return this.dataStore.dataUUIDs[tabID];
    }

    public getStore(dataStorekey: DataStoreKey, storeName: DataStoreName): DataStoreSet | DataStore {
        if (
            !this.dataStore.windowStore[storeName][dataStorekey.windowId] ||
            !this.dataStore.windowStore[storeName][dataStorekey.windowId][dataStorekey.tabId] ||
            !this.dataStore.windowStore[storeName][dataStorekey.windowId][dataStorekey.tabId][dataStorekey.parentId]
        ) {
            return undefined;
        }
        if (dataStorekey.recordId) {
            if (
                !this.dataStore.windowStore[storeName][dataStorekey.windowId][dataStorekey.tabId][dataStorekey.parentId].data[
                    dataStorekey.recordId
                    ]
            ) {
                return undefined;
            } else {
                return this.dataStore.windowStore[storeName][dataStorekey.windowId][dataStorekey.tabId][dataStorekey.parentId]
                    .data[dataStorekey.recordId];
            }
        } else {
            return this.dataStore.windowStore[storeName][dataStorekey.windowId][dataStorekey.tabId][dataStorekey.parentId];
        }
    }

    public extractRecordInfoFromDsKey(dataStoreKey: DataStoreKey): Map<string, number> {
        const keyMap: Map<string, number> = new Map<string, number>();
        const filtersArray = dataStoreKey.recordId.split(',');
        if (filtersArray.length > 1) {
            for (let i = 0; i < filtersArray.length; i = i + 2) {
                const value = parseInt(filtersArray[i + 1], 10);
                if (!isNaN(value)) {
                    keyMap.set(filtersArray[i], value);
                }
            }
        }
        return keyMap;
    }

    public getInfoWindowDefaultValues(infoWindowID: number, ctx: any): Observable<InfoWindowDV[]> {
        return this.dataService.getInfoWindowDefaultValues(infoWindowID, ctx);
    }

    public findInCurrentStore(dataStorekey: DataStoreKey): DataStoreSet | DataStore {
        return <DataStore>this.findInStore(dataStorekey, this.dataStore.windowStore.current);
    }

    /*
     * **************************************************************************************
     * **************************************************************************************
     * ********************************* Column Infos ***************************************
     * **************************************************************************************
     * **************************************************************************************
     */
    public setColumnInfo(id: number, columnInfo: IupicsColumnInfo[], fromSpecific = false) {
        const prefix = fromSpecific ? 'AD_FormDetail_ID' : 'AD_Tab_ID';
        const key = `${prefix}_${id}`;
        this.iupics_column_info.set(key, columnInfo);

        let dataUUIDs = columnInfo
            .filter((ci) => ci.fieldEntity.field.IsKey)
            .map((ci2) => ci2.fieldEntity.field.ColumnName);
        //pour le cas de clé composée
        if (dataUUIDs.length == 0) {
            dataUUIDs = columnInfo
                .filter((ci) => ci.fieldEntity.field.IsParent)
                .map((ci2) => ci2.fieldEntity.field.ColumnName);
        }
        //pour le cas d'une vue
        if (dataUUIDs.length == 0) {
            dataUUIDs = columnInfo
                .filter((ci) => ci.fieldEntity.field.ColumnName && ci.fieldEntity.field.ColumnName.includes('_ID'))
                .map((ci2) => ci2.fieldEntity.field.ColumnName);
        }
        this.dataStore.dataUUIDs[id] = dataUUIDs;
    }

    public getColumnInfo(id: number, fromSpecific = false) {
        const prefix = fromSpecific ? 'AD_FormDetail_ID' : 'AD_Tab_ID';
        const key = `${prefix}_${id}`;
        if (this.iupics_column_info.has(key)) {
            return this.iupics_column_info.get(key);
        }
    }

    public hasColumnInfo(id: number, fromSpecific = false) {
        const prefix = fromSpecific ? 'AD_FormDetail_ID' : 'AD_Tab_ID';
        const key = `${prefix}_${id}`;
        return this.iupics_column_info.has(key);
    }

    public getColumnCompiereDataGridFilterType(
        id: number,
        columnName: string,
        fromSpecific = false
    ): CompiereDataGridFilterType {
        const prefix = fromSpecific ? 'AD_FormDetail_ID' : 'AD_Tab_ID';
        const key = `${prefix}_${id}`;
        let columnInfo = null;
        if (this.iupics_column_info.has(key)) {
            columnInfo = this.iupics_column_info.get(key).find((col) => {
                if (col.fieldEntity && col.fieldEntity.field && col.fieldEntity.field.ColumnName === columnName) {
                    return col;
                }
            });
        }
        return columnInfo ? columnInfo.filterType : null;
    }

    /*
     * **************************************************************************************
     * **************************************************************************************
     * ********************************* Json Def ***************************************
     * **************************************************************************************
     * **************************************************************************************
     */
    public setJsonDef(id: number, jsonDef: IupicsJsonDef) {
        this.iupics_json_def.set(id, jsonDef);
    }

    public getJsonDef(id: number) {
        if (this.iupics_json_def.has(id)) {
            return this.iupics_json_def.get(id);
        }
    }

    public hasJsonDef(id: number) {
        return this.iupics_json_def.has(id);
    }

    /*
     * ************************************************************************************************
     * ************************************************************************************************
     * ************************************************************************************************
     * ************************************************************************************************
     * ************************* FOLLOWING METHOD MUST BE PRIVATE *************************************
     * *************************(only internal algorithms of store)************************************
     * ************************************************************************************************
     * ************************************************************************************************
     * ************************************************************************************************
     */
    private findInStore(dataStorekey: DataStoreKey, store: {}): DataStoreSet | DataStore {
        if (
            !store[dataStorekey.windowId] ||
            !store[dataStorekey.windowId][dataStorekey.tabId] ||
            !store[dataStorekey.windowId][dataStorekey.tabId][dataStorekey.parentId]
        ) {
            return undefined;
        }
        if (dataStorekey.recordId) {
            if (!store[dataStorekey.windowId][dataStorekey.tabId][dataStorekey.parentId].data[dataStorekey.recordId]) {
                return undefined;
            } else {
                return store[dataStorekey.windowId][dataStorekey.tabId][dataStorekey.parentId].data[dataStorekey.recordId];
            }
        } else {
            return store[dataStorekey.windowId][dataStorekey.tabId][dataStorekey.parentId];
        }
    }

    private removeFromStore(dataStorekey: DataStoreKey, store: {}) {
        if (store && Object.keys(store).length > 0) {
            if (
                dataStorekey &&
                dataStorekey.windowId !== undefined &&
                store[dataStorekey.windowId] !== undefined &&
                dataStorekey.tabId !== undefined &&
                store[dataStorekey.windowId][dataStorekey.tabId] !== undefined &&
                dataStorekey.parentId !== undefined &&
                store[dataStorekey.windowId][dataStorekey.tabId][dataStorekey.parentId] !== undefined &&
                dataStorekey.recordId !== undefined &&
                store[dataStorekey.windowId][dataStorekey.tabId][dataStorekey.parentId].data[dataStorekey.recordId] !==
                undefined
            ) {
                delete store[dataStorekey.windowId][dataStorekey.tabId][dataStorekey.parentId].data[dataStorekey.recordId];
                store[dataStorekey.windowId][dataStorekey.tabId][dataStorekey.parentId].orderKeys = store[
                    dataStorekey.windowId
                    ][dataStorekey.tabId][dataStorekey.parentId].orderKeys.filter((key) => key !== dataStorekey.recordId);
            } else if (
                dataStorekey &&
                dataStorekey.windowId !== undefined &&
                store[dataStorekey.windowId] !== undefined &&
                dataStorekey.tabId !== undefined &&
                store[dataStorekey.windowId][dataStorekey.tabId] !== undefined &&
                dataStorekey.parentId !== undefined &&
                store[dataStorekey.windowId][dataStorekey.tabId][dataStorekey.parentId] !== undefined &&
                dataStorekey.recordId === undefined
            ) {
                delete store[dataStorekey.windowId][dataStorekey.tabId][dataStorekey.parentId];
            } else if (
                dataStorekey &&
                dataStorekey.windowId !== undefined &&
                store[dataStorekey.windowId] !== undefined &&
                dataStorekey.tabId !== undefined &&
                store[dataStorekey.windowId][dataStorekey.tabId] !== undefined &&
                dataStorekey.parentId === undefined &&
                dataStorekey.recordId === undefined
            ) {
                delete store[dataStorekey.windowId][dataStorekey.tabId];
            } else if (
                dataStorekey &&
                dataStorekey.windowId !== undefined &&
                store[dataStorekey.windowId] !== undefined &&
                dataStorekey.tabId === undefined &&
                dataStorekey.parentId === undefined &&
                dataStorekey.recordId === undefined
            ) {
                delete store[dataStorekey.windowId];
            }
        }
        return true;
    }

    private filterData(datas: DataStoreSet, startRow: number, endrow: number): {} {
        const dataFiltered = {};
        for (let i = startRow; i < datas.orderKeys.length && i < endrow; i++) {
            dataFiltered[datas.orderKeys[i]] = datas.data[datas.orderKeys[i]];
        }

        return dataFiltered;
    }

    private copyToOldStore(datas: DataStore | DataStoreSet, store: { old: {} }): void {
        if (datas instanceof DataStoreSet) {
            for (const orderKey of datas.orderKeys) {
                const dataForOld = new DataStore();
                if (datas.data[orderKey]) {
                    dataForOld.key = Object.assign({}, datas.data[orderKey].key);
                    dataForOld.data = Object.assign({}, datas.data[orderKey].data);
                    this.store(dataForOld.key, dataForOld, store.old);
                }
            }
        } else {
            const dataForOld = new DataStore();
            dataForOld.key = Object.assign({}, datas.key);
            dataForOld.data = Object.assign({}, datas.data);
            this.store(dataForOld.key, dataForOld, store.old);
        }
    }

    public replaceComplexType(dataWSResponse: CompiereDataGridResponseJSON) {
        if (dataWSResponse.data_UUID !== undefined) {
            // Si il n'y a pas d'array displayData dans la response on la créé
            const madeDisplayData = [];
            for (const individualUUID of dataWSResponse.data_UUID) {
                const individualUUIDSplitted = individualUUID.split('.');
                const uuidLength = individualUUIDSplitted.length;
                if (uuidLength > 0 && individualUUIDSplitted[uuidLength - 1]) {
                    madeDisplayData.push(individualUUIDSplitted[uuidLength - 1]);
                    if (uuidLength > 1) {
                        for (const line of dataWSResponse.data) {
                            line[individualUUIDSplitted[uuidLength - 1]] = line[individualUUID];
                            delete line[individualUUID];
                        }
                    }
                }
            }
            dataWSResponse.data_UUID = madeDisplayData;
            for (const responseData of dataWSResponse.data) {
                const data_uuid = this.generateRecordIdString(dataWSResponse.data_UUID, responseData);
                responseData['Data_UUID'] = data_uuid;
                if (dataWSResponse.displayData) {
                    for (const columnName of Object.keys(responseData)) {
                        if (dataWSResponse.displayData[columnName + '$' + responseData[columnName]]) {
                            responseData[columnName] = {
                                id: responseData[columnName],
                                displayValue: dataWSResponse.displayData[columnName + '$' + responseData[columnName]],
                            };
                        }
                    }
                } else {
                    for (const columnName of Object.keys(responseData)) {
                        if (madeDisplayData.includes(columnName)) {
                            responseData[columnName] = {
                                id: responseData[columnName],
                                displayValue: responseData[columnName],
                            };
                        }
                    }
                }
            }
        }
    }

    private transformNewDataWSAndStore(
        dataStorekey: DataStoreKey,
        dataWSResponse: CompiereDataGridResponseJSON,
        store: {}
    ): DataStoreKey {
        for (const responseData of dataWSResponse.data) {
            dataStorekey = this.transformOneNewDataWsAndStore(dataStorekey, responseData, store, dataWSResponse);
        }
        return dataStorekey;
    }

    private transformOneNewDataWsAndStore(
        dataStorekey: DataStoreKey,
        responseData: {},
        store: {},
        dataWs: CompiereDataGridResponseJSON
    ) {
        if (!responseData['apiz_dataResult'] || !responseData['apiz_dataResult']['responseError']) {
            const dataStorekey2 = Object.assign({}, dataStorekey);
            const dataStore: DataStore = new DataStore();
            dataStore.data = Object.assign({}, responseData);
            dataStorekey2.recordId = '';
            for (let index = 0; index < dataWs.data_UUID.length ?? 0; index++) {
                const data_uuid = dataWs.data_UUID[index];
                if (responseData[data_uuid] instanceof Object) {
                    dataStorekey2.recordId += data_uuid + ',' + responseData[data_uuid].id;
                } else {
                    dataStorekey2.recordId += data_uuid + ',' + responseData[data_uuid];
                }
                dataStorekey2.recordId += index < dataWs.data_UUID.length - 1 ? ',' : '';
            }
            if (dataStorekey2.recordId === '') {
                dataStorekey2.recordId = dataStorekey.recordId;
            }
            this.store(dataStorekey2, dataStore, store, dataWs.lastRow);
            dataStorekey = dataStorekey2;
        }
        return dataStorekey;
    }

    private store(dataStorekey: DataStoreKey, data: DataStore, store: {}, lastRowRemote?: number) {
        if (!store[dataStorekey.windowId]) {
            store[dataStorekey.windowId] = {};
        }

        if (!store[dataStorekey.windowId][dataStorekey.tabId]) {
            store[dataStorekey.windowId][dataStorekey.tabId] = {};
        }

        if (!store[dataStorekey.windowId][dataStorekey.tabId][dataStorekey.parentId]) {
            store[dataStorekey.windowId][dataStorekey.tabId][dataStorekey.parentId] = new DataStoreSet();
        }
        if (!store[dataStorekey.windowId][dataStorekey.tabId][dataStorekey.parentId].data[dataStorekey.recordId]) {
            data.seqNo = store[dataStorekey.windowId][dataStorekey.tabId][dataStorekey.parentId].nextSeqNo++;
            store[dataStorekey.windowId][dataStorekey.tabId][dataStorekey.parentId].orderKeys.push(dataStorekey.recordId);
            data.key = dataStorekey;
            store[dataStorekey.windowId][dataStorekey.tabId][dataStorekey.parentId].data[dataStorekey.recordId] = data;
        }
        // Always replace Data
        store[dataStorekey.windowId][dataStorekey.tabId][dataStorekey.parentId].data[dataStorekey.recordId].data =
            data.data;
        if (lastRowRemote) {
            store[dataStorekey.windowId][dataStorekey.tabId][dataStorekey.parentId].lastRowRemote = lastRowRemote;
        }
    }

    private compareDataStoreChanges(old: DataStore, local: DataStore, remote: DataStore): DataConflict {
        let fields = null;
        if (old && old.key && old.key.tabId) {
            fields = Object.keys(this.newDataStructure.window[old.key.tabId]);
        } else {
            fields = Object.keys(old.data);
        }
        const dataResult: DataConflict = {mustRefresh: false, hasConflicts: false, dataChanged: {}, dataConflict: {}};
        // verif remote <> old
        moment.locale(this.connectorService.getIupicsDefaultLanguage().iso_code);
        for (let i = 0; i < fields.length; i++) {
            if (['Created', 'CreatedBy', 'Updated', 'UpdatedBy'].includes(fields[i])) {
                continue;
            }
            const diff = !this.isStoreValueEquals(old.data[fields[i]], remote.data[fields[i]], fields[i]);
            if (diff) {
                dataResult.dataChanged[fields[i]] = remote.data[fields[i]];
            }
        }
        // si remote <> old, verif old <> local
        if (Object.keys(dataResult.dataChanged).length > 0) {
            for (let i = 0; i < fields.length; i++) {
                let diff = !this.isStoreValueEquals(local.data[fields[i]], old.data[fields[i]], fields[i]);
                if (diff) {
                    // remote <> old et old <> local, si local <> remote
                    diff = !this.isStoreValueEquals(old.data[fields[i]], remote.data[fields[i]], fields[i]);
                    if (diff) {
                        dataResult.dataConflict[fields[i]] = remote.data[fields[i]];
                    } else {
                        this.copyRemoteWindowDataToOldStore(remote.key, fields[i]);
                    }
                    delete dataResult.dataChanged[fields[i]];
                }
            }
        }
        dataResult.mustRefresh =
            Object.keys(dataResult.dataChanged).length > 0 && Object.keys(dataResult.dataConflict).length <= 0;
        dataResult.hasConflicts = Object.keys(dataResult.dataConflict).length > 0;
        if (environment.constant.mergeLevel === 1 && dataResult.mustRefresh === true) {
            dataResult.refreshAuto = true;
        }
        if (environment.constant.mergeLevel !== 2 && dataResult.hasConflicts === true) {
            dataResult.hasConflicts = false;
            dataResult.mustRefresh = true;
        }
        return dataResult;
    }

    private prepareDataRequest(dataRequest: DataStoreRequest): DataStoreRequest {
        if (!dataRequest.compiereRequest.rowGroupCols) {
            dataRequest.compiereRequest.rowGroupCols = [];
        }

        // if (!dataRequest.compiereRequest.pivotMode || !dataRequest.compiereRequest.pivotCols) {
        //   dataRequest.compiereRequest.pivotCols = [];
        // }

        if (!dataRequest.compiereRequest.valueCols) {
            dataRequest.compiereRequest.valueCols = [];
        }
        if (!dataRequest.compiereRequest.groupKeys) {
            dataRequest.compiereRequest.groupKeys = [];
        }
        if (!dataRequest.compiereRequest.sortModel) {
            dataRequest.compiereRequest.sortModel = [];
        }
        if (!dataRequest.compiereRequest.filterModel) {
            dataRequest.compiereRequest.filterModel = {};
        }

        // Si l'utilisateur a renseigné des filtres, on ne recherche pas dans le DataStore sinon oui
        dataRequest.isUserFilter = Object.keys(dataRequest.compiereRequest.filterModel).length > 0;

        // Si on a une contrainte avec un parent, on ajoute dans le filterModel
        if (dataRequest.parent_constraint) {
            if (!dataRequest.compiereRequest.filterModel) {
                dataRequest.compiereRequest.filterModel = {};
            }
            let filtersArray = dataRequest.parent_constraint.split('=');
            if (filtersArray.length <= 1) {
                const splittedRecord = dataRequest.parent_constraint.split(',');
                filtersArray = splittedRecord.length > 1 ? splittedRecord : [];
            }
            for (let i = 0; i < filtersArray.length; i = i + 2) {
                if (!dataRequest.compiereRequest.filterModel[filtersArray[i]]) {
                    let value;
                    let filterType = null;
                    if (filtersArray[i + 1].startsWith('"')) {
                        value = filtersArray[i + 1].substring(1, filtersArray[i + 1].length - 1);
                    } else {
                        if (
                            !filtersArray[i].includes('_ID') &&
                            dataRequest.compiereRequest &&
                            dataRequest.compiereRequest.entityId
                        ) {
                            filterType = this.getColumnCompiereDataGridFilterType(
                                dataRequest.compiereRequest.entityId,
                                filtersArray[i],
                                dataRequest.compiereRequest.windowType === CompiereDataGridType.FORM
                            );
                            if (filterType && filterType === CompiereDataGridFilterType.TEXT) {
                                value = filtersArray[i + 1];
                            }
                        }
                        if (value === undefined) {
                            if (filtersArray[i + 1] && isNaN(parseInt(filtersArray[i + 1], 10))) {
                                value = filtersArray[i + 1];
                            } else {
                                value = parseInt(filtersArray[i + 1], 10);
                            }
                        }
                    }
                    dataRequest.compiereRequest.filterModel[filtersArray[i]] = {
                        filterType: filterType ? filterType : CompiereDataGridFilterType.SET,
                        values: [value],
                        operators: [OperatorFilterType.EQUALS],
                    };
                }
            }
        }
        // Si on a un recordId, on ajoute dans le filterModel
        // DataUUID="Entitype,N482"
        // DataUUID="AD_Entitype_ID,102482"
        if (dataRequest.record_id) {
            if (!dataRequest.compiereRequest.filterModel) {
                dataRequest.compiereRequest.filterModel = {};
            }
            const filtersArray = dataRequest.record_id.split(',');
            if (filtersArray.length > 1) {
                for (let i = 0; i < filtersArray.length; i = i + 2) {
                    if (!dataRequest.compiereRequest.filterModel[filtersArray[i]]) {
                        let filterType = null;
                        let value;
                        if (filtersArray[i + 1].startsWith('"')) {
                            value = filtersArray[i + 1].substring(1, filtersArray[i + 1].length - 1);
                        } else {
                            if (
                                !filtersArray[i].includes('_ID') &&
                                dataRequest.compiereRequest &&
                                dataRequest.compiereRequest.entityId
                            ) {
                                filterType = this.getColumnCompiereDataGridFilterType(
                                    dataRequest.compiereRequest.entityId,
                                    filtersArray[i],
                                    dataRequest.compiereRequest.windowType === CompiereDataGridType.FORM
                                );
                                if (filterType && filterType === CompiereDataGridFilterType.TEXT) {
                                    value = filtersArray[i + 1];
                                }
                            }
                            if (value === undefined) {
                                if (filtersArray[i + 1] && isNaN(parseInt(filtersArray[i + 1], 10))) {
                                    value = filtersArray[i + 1];
                                } else {
                                    value = parseInt(filtersArray[i + 1], 10);
                                }
                            }
                        }
                        dataRequest.compiereRequest.filterModel[filtersArray[i]] = {
                            filterType: filterType ? filterType : CompiereDataGridFilterType.SET,
                            values: [value],
                            operators: [OperatorFilterType.EQUALS],
                        };
                    }
                }
            }
        }

        dataRequest.compiereRequest.groupKeys = dataRequest.compiereRequest.groupKeys.map((groupKey) => {
            if (groupKey instanceof Object) {
                return groupKey.id;
            } else {
                return groupKey;
            }
        });

        return dataRequest;
    }

    private searchDataInStore(dataRequest: DataStoreRequest, dataStorekey: DataStoreKey, gridOutputFormat = false): any {
        let datas: any = this.findInStore(dataStorekey, this.dataStore.windowStore.current);
        if (
            datas &&
            dataRequest.compiereRequest.groupKeys.length <= 0 &&
            /* !dataRequest.compiereRequest.pivotMode && */
            dataRequest.compiereRequest.rowGroupCols.length <= 0 &&
            dataRequest.compiereRequest.sortModel.length <= 0 &&
            !dataRequest.isUserFilter
        ) {
            if (datas instanceof DataStoreSet) {
                const dataLength = (<DataStoreSet>datas).orderKeys.length;
                const lastRowRemote = (<DataStoreSet>datas).lastRowRemote;
                if (
                    dataLength >= dataRequest.compiereRequest.startRow + dataRequest.compiereRequest.endRow ||
                    lastRowRemote > -1
                ) {
                    datas = this.filterData(
                        <DataStoreSet>datas,
                        dataRequest.compiereRequest.startRow,
                        dataRequest.compiereRequest.endRow
                    );
                    if (gridOutputFormat) {
                        const response: CompiereDataGridResponseJSON = {
                            data: [],
                            data_UUID: [],
                            displayData: {},
                            secondaryColumnFields: [],
                            lastRow: lastRowRemote,
                        };
                        for (const key of Object.keys(datas)) {
                            response.data.push(datas[key].data);
                        }
                        datas = response;
                    } else {
                    }
                } else {
                    datas = undefined;
                }
            }
        } else {
            datas = undefined;
        }
        return datas;
    }

    private initDataRequest(dataStorekey: DataStoreKey, component?: any, userContext?: any): DataStoreRequest {
        const dataRequest = {
            windowId: dataStorekey.windowId,
            record_id: dataStorekey.recordId,
            compiereRequest: {
                windowType: CompiereDataGridType.WINDOW,
                entityId: dataStorekey.tabId,
                startRow: 0,
                endRow: 50,
            },
        } as DataStoreRequest;

        if (component && component.editTabs) {
            dataRequest.compiereRequest.validation = LogicEvaluator.replaceVariables(
                component.getTabWhereclause(),
                userContext,
                component.editTabs[0].getCurrentContext()
            );
            dataRequest.compiereRequest.windowCtx = component.editTabs[0].getCurrentContext();
        }
        if (dataRequest.compiereRequest.validation) {
            dataRequest.compiereRequest.validation = '(' + dataRequest.compiereRequest.validation + ')';
        }
        if (dataStorekey.recordId) {
            dataRequest.compiereRequest.endRow = 1;
        }

        return dataRequest;
    }

    private handleSyncResponse(
        dataResponse: CompiereDataGridResponseJSON,
        dataStorekey: DataStoreKey,
        checkConflict = false,
        component?: any,
        callback?: Function,
        callbackParam?: any
    ) {
        if (dataResponse && dataResponse.data.length > 0) {
            this.replaceComplexType(dataResponse);
            this.transformNewDataWSAndStore(dataStorekey, dataResponse, this.dataStore.windowStore.remote);
            const dataStoreCurrent = <DataStore>this.findInStore(dataStorekey, this.dataStore.windowStore.current);
            const dataStoreRemote = <DataStore>this.findInStore(dataStorekey, this.dataStore.windowStore.remote);
            if (dataStoreCurrent) {
                if (checkConflict) {
                    const dataStoreOld = <DataStore>this.findInStore(dataStorekey, this.dataStore.windowStore.old);
                    if (dataStoreCurrent.status !== DataStoreStatus.NEWRECORD) {
                        const result = this.compareDataStoreChanges(dataStoreOld, dataStoreCurrent, dataStoreRemote);
                        if (environment.constant.mergeLevel === 2 && result.hasConflicts) {
                            dataStoreCurrent.dataConflict.emit(result);
                            if (Object.keys(result.dataChanged).length > 0) {
                                this.syncDataChanges(dataStoreCurrent, result.dataChanged, true, true);
                                this.copyToOldStore(dataStoreCurrent, this.dataStore.windowStore);
                            }
                            dataStoreCurrent.status = DataStoreStatus.NOTSYNC;
                        }
                        if (component) {
                            component.conflictsResult = result;
                        }
                    }
                    if (callback) {
                        callback(callbackParam);
                    }
                } else {
                    this.syncDataChanges(dataStoreCurrent, dataStoreRemote.data, false, true);
                    this.copyToOldStore(dataStoreCurrent, this.dataStore.windowStore);
                    dataStoreCurrent.status = DataStoreStatus.SYNC;
                }
            }

            return dataStoreCurrent;
        }
    }

    // Only for debugging
    private printData(dataStorekey: DataStoreKey, columnName: string, traceName?: string) {
        const dataStoreCurrent = <DataStore>this.findInStore(dataStorekey, this.dataStore.windowStore.current);
        const dataStoreRemote = <DataStore>this.findInStore(dataStorekey, this.dataStore.windowStore.remote);
        const dataStoreOld = <DataStore>this.findInStore(dataStorekey, this.dataStore.windowStore.old);
        let str = '';
        if (traceName) {
            str = `-----------${traceName} (${columnName})-------------------`;
        }
        if (traceName) {
            let strClose = '';
            for (let i = 0; i < str.length; i++) {
                strClose += '-';
            }
        }
    }
}
