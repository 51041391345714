import {NgClass} from '@angular/common';
import {Component, inject, Input, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {ProcessPingInfo} from '@compiere-ws/models/process-ping-info';
import {ProcessInProgressService} from '@compiere-ws/services/process-in-progress/process-in-progress.service';
import {TranslateModule} from '@ngx-translate/core';
import TabUiComponent from '@web-desktop/components/menu-top/components/tab-ui/tab-ui.component';
import * as moment from 'moment';
import {ProgressBarModule} from 'primeng/progressbar';
import {Subscription} from 'rxjs';
import {ParseInstanceParamsPipe} from '../../../pipes/parse-instance-params/parse-instance-params.pipe';
import ButtonUiComponent from "@iupics-components/standard/fields/button-ui/button-ui.component";
import {SecurityManagerService} from "@iupics-manager/managers/security-manager/security-manager.service";

@Component({
    selector: 'iu-process-in-progress-ui',
    templateUrl: './process-in-progress-ui.component.html',
    styleUrls: ['./process-in-progress-ui.component.scss'],
    standalone: true,
    imports: [NgClass, ProgressBarModule, TranslateModule, ParseInstanceParamsPipe, ButtonUiComponent],
})
export default class ProcessInProgressUiComponent implements OnInit, OnDestroy {
    private progressService = inject(ProcessInProgressService);
    private connectorService = inject(SecurityManagerService);
    private renderer = inject(Renderer2);

    @Input() activeTab: TabUiComponent;

    pingInfos: ProcessPingInfo[] = [];

    isAdmin = false;
    private _pingSub: Subscription;
    private actualElt: HTMLDivElement;

    ngOnInit() {
        this.isAdmin = this.connectorService.getIupicsUserAccount().current_role.isAdministrator;
        if (this.activeTab !== undefined) {
            this.progressService.openWindow(this.activeTab.id);
        }

        this._pingSub = this.progressService.watchProcessInProgress().subscribe((pings) => {
            const newPings = [];
            for (const ping of pings) {
                const index = this.pingInfos.findIndex((p) => p.AD_PInstance_ID === ping.AD_PInstance_ID);
                if (index === -1) {
                    // We add new process
                    this.progressService.getProcessInfo(ping);
                    newPings.push(ping);
                } else {
                    // We keep existing process
                    newPings.push(this.pingInfos[index]);
                }
            }

            this.pingInfos = newPings;
        });
    }

    stopProcess(event, pingInfo: ProcessPingInfo) {
        if (event) {
            event.stopPropagation();
        }
        if (pingInfo.Status !== 'finish' && pingInfo.Status !== 'cancel')
            this.progressService.stopProcess(pingInfo.Channel)
    }

    ngOnDestroy() {
        if (this.activeTab !== undefined) {
            this.progressService.closeWindow(this.activeTab.id);
        }
        this._pingSub.unsubscribe();
    }

    getCreated(dateStr: string) {
        const date = moment(dateStr).toDate().getTime();
        const now = moment().toDate().getTime();
        const timeInSec = Math.floor((now - date) / 1000);
        return this.formatCreated(timeInSec);
    }

    getAverageTime(timeInSec: number, dateStr: string) {
        let margin = 20;
        if (dateStr) {
            const date = moment(dateStr).toDate().getTime();
            const now = moment().toDate().getTime();
            const timeSpentInSec = Math.floor((now - date) / 1000);
            margin = Math.floor(timeInSec * 0.15) > 20 ? Math.floor(timeInSec * 0.15) : 20;
            if (timeSpentInSec > timeInSec + margin) {
                return this.formatCreated(timeInSec);
            }
        }
        return null;
    }

    showDetail(pingDetailElt: HTMLDivElement) {
        if (pingDetailElt !== this.actualElt) {
            if (this.actualElt !== undefined) {
                this.renderer.removeClass(this.actualElt, 'open');
            }
            this.actualElt = pingDetailElt;
            this.renderer.addClass(this.actualElt, 'open');
        } else {
            if (this.actualElt.classList.contains('open')) {
                this.renderer.removeClass(this.actualElt, 'open');
            } else {
                this.renderer.addClass(this.actualElt, 'open');
            }
        }
    }

    private formatCreated(nb: number) {
        return nb < 1
            ? ' < 1sec'
            : nb / 60 < 1
                ? `${nb} sec`
                : nb / 3600 < 1
                    ? `${Math.floor(nb / 60)} min ${nb % 60} sec`
                    : `${Math.floor(nb / 3600)} h ${Math.floor(nb / 60) - Math.floor(nb / 3600) * 60} min ${nb % 60} sec`;
    }
}
