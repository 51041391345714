import { NgClass, NgIf } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  inject,
} from '@angular/core';
import { DocserverEntry } from '@compiere-ws/models/docserver-entry-json';
import { DocserverDisplayedTag } from '@compiere-ws/models/docserverDisplayedTag';
import { DocserverWsResponse } from '@compiere-ws/models/docserverWsResponse';
import { TagItem } from '@compiere-ws/models/tag-json';
import { DocServerService } from '@compiere-ws/services/doc-server/doc-server.service';
import { UploadedFile } from '@iupics-components/models/uploaded-file';
import PrimeFileuploadComponent from '@iupics-components/overrided/prime-fileupload/prime-fileupload.component';
import { InfoDialogType } from '@iupics-components/specific/window/info-dialog/info-dialog.component';
import SpecificWindowUiComponent from '@iupics-components/specific/window/specific-window-ui/specific-window-ui.component';
import EditTabUiComponent from '@iupics-components/standard/layouts/edit-tab-ui/edit-tab-ui.component';
import { AppConfig } from '@iupics-config/app.config';
import { AbstractDataContainer, AbstractDataContainerCallout } from '@iupics-manager/models/abstract-datacontainer';
import { Global } from '@iupics-manager/models/global-var';
import { IupicsDataField } from '@iupics-manager/models/iupics-data';
import { MongoSearchQueryOperator } from '@iupics-manager/models/mongo-search';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { TooltipModule } from 'primeng/tooltip';
import { Subscription } from 'rxjs';

@Component({
  selector: 'iu-input-file-ui',
  templateUrl: './input-file-ui.component.html',
  styleUrls: ['./input-file-ui.component.scss'],
  standalone: true,
  imports: [TooltipModule, NgIf, NgClass, PrimeFileuploadComponent],
})
export default class InputFileUiComponent extends AbstractDataContainer implements OnInit, AfterViewInit {
  #docServerService = inject(DocServerService);
  #translateService = inject(TranslateService);
  #config = inject(AppConfig);

  dataContainers: AbstractDataContainerCallout;
  uploadingFiles: File[] = [];
  @Output()
  nbUploadedFilesChangeEmitter: EventEmitter<number> = new EventEmitter<number>();
  @ViewChild('input', { static: true })
  inputRef: ElementRef;
  @ViewChild(PrimeFileuploadComponent, { static: true })
  primeFileuploadComponent: PrimeFileuploadComponent;

  @Output()
  fileAttachedEmitter = new EventEmitter<any>();

  @Output() uploadEnd = new EventEmitter<any>();
  @Output() delete = new EventEmitter<any>();
  @Output() openFileOnSideEmitter = new EventEmitter<any>();
  @Input() data: IupicsDataField;
  @Input() isAttachment = false;
  @Input() displayedTags: DocserverDisplayedTag[] = [];
  @Input() uploadedFiles: UploadedFile[] = [];
  @Input() linkedFiles: UploadedFile[] = [];
  @Input() multiple = false;
  @Input() displayFileUploaded = true;
  @Input() displayButtons = true;
  @Input() auto = false;
  @Input() type = 'ATTACHEMENT';
  @Input() styleClass: string;
  @Input() mustGet = true;
  @Input() isLoaderActive = false;
  @Input() searchInformations = [{ 'META|TYPE': this.type }];
  @Input() additionalUploadInformations: any;
  @Input() canUpload = true;
  @Input() isLocal = false;
  @Input() canDelete = true;
  @Input() canDownload = true;
  @Input() canPreview = true;
  @Input() columnName: string;
  @Input() fromDocServer = true;
  @Input() adTable_ID: number;
  @Input() adTab_ID: number;
  @Input() record_id: number;

  @Input() uploadedFileList_title = 'fileupload.files_list';
  @Input() uploadedFileList_noFiles = 'fileupload.no_files';
  @Input() uploadingFileList_title = 'fileupload.add_files';
  @Input() uploadingFileList_drag = 'fileupload.label';
  @Input() uploadBtn_confirm = 'fileupload.upload_files';
  @Input() uploadBtn_cancel = 'fileupload.clear';

  @Input() fromNotes: boolean = false;

  @Input() supportLinks: boolean = false;

  localFiles: File[];

  valSubscr: Subscription;
  canSubscr: Subscription;

  ngOnInit() {
    super.ngOnInit();
    if ((!this.isStandalone && this.data.isParam) || this.container instanceof SpecificWindowUiComponent) {
      this.mustGet = false;
    }
    if (this.fromNotes) {
      this.mustGet = false;
      this.multiple = false;
    }
    // récupère les fichier depuis le serveur
    if (this.mustGet && !this.isLocal) {
      this.getFiles();
    }
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    let parentComp = this.DOMParentComponent;
    while (parentComp && !(parentComp instanceof EditTabUiComponent)) {
      parentComp = parentComp.DOMParentComponent;
    }
    if (parentComp instanceof EditTabUiComponent) {
      this.dataContainers = new AbstractDataContainerCallout(parentComp.dataContainers);
    }
  }

  getFiles() {
    const searchQuery = this.#docServerService.createQuery(this.searchInformations, MongoSearchQueryOperator.OR);
    const sub = this.#docServerService
      .advancedSearchDocuments(
        searchQuery,
        { limit: -1, start: 0, attachmentInteraction: true },
        this.adTab_ID,
        this.adTable_ID,
        this.record_id
      )
      .subscribe((response: DocserverWsResponse) => {
        if (response) {
          this.displayedTags = response.displayedTags;
          response.entries.forEach((hit: DocserverEntry) => {
            if (hit.type === 'ATTACHEMENT' && parseInt(hit.table_id, 10) === this.adTable_ID) {
              this.uploadedFiles.push({
                createdDate: hit.ufa_created_date ? this.formatDate(hit.ufa_created_date) : null,
                src: hit.url,
                name: hit.fileName,
                id: hit.id,
                docId: hit.docId,
                extension: hit.fileName
                  ? hit.fileName.split('.').length > 1
                    ? hit.fileName.split('.')[hit.fileName.split('.').length - 1]
                    : undefined
                  : undefined,
                isDeletable: !this.fromNotes,
                tags: hit.tags,
                provider: hit.provider,
              });
            } else {
              this.linkedFiles.push({
                createdDate: hit.ufa_created_date ? this.formatDate(hit.ufa_created_date) : null,
                src: hit.url,
                name: hit.fileName,
                id: hit.id,
                docId: hit.docId,
                extension: hit.fileName
                  ? hit.fileName.split('.').length > 1
                    ? hit.fileName.split('.')[hit.fileName.split('.')[hit.fileName.split('.').length - 1]]
                    : undefined
                  : undefined,
                isDeletable: hit.type !== 'NOTES',
                tags: hit.tags,
                provider: hit.provider,
              });
            }
          });
        }

        this.nbUploadedFilesChangeEmitter.emit(this.uploadedFiles.length + this.linkedFiles.length);
        sub.unsubscribe();
      });
  }

  formatDate(value: string) {
    let dateFormatted;
    try {
      dateFormatted = new Date(value).toLocaleDateString();
    } catch (error) {
      dateFormatted = value;
    }
    return dateFormatted;
  }

  myUploader(event: any) {
    // upload des fichiers
    this.uploadFiles({ files: event.files });
  }

  /**
   * Upload les fichiers vers le document server
   * OK
   * @param {File[]}files
   */
  uploadFiles({ files = [], tags = [] }) {
    this.localFiles = [...files];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      // le fichier n'est pas déjà uploadé..
      if (this.uploadingFiles.indexOf(file) === -1) {
        this.uploadingFiles.push(file);
        let fileName = file.name;
        const extension = file.name.split('.').pop();
        let count = 1;
        while (this.uploadedFiles.find((f) => f.name == fileName)) {
          fileName = file.name.replace('.' + extension, '_' + count++ + '.' + extension);
        }
        const id = file.name.replace(/\./g, '_') + new Date().getTime();
        let additionalUploadInformations = cloneDeep(this.additionalUploadInformations);
        if (tags && tags[i])
          for (const t of tags[i])
            if (!additionalUploadInformations['META|' + t.name])
              additionalUploadInformations['META|' + t.name] = t.value;
        this.subscriptions.push(
          this.#docServerService
            .uploadDocument(file, fileName, id, this.type, additionalUploadInformations)
            .subscribe((entry) => {
              const uploadedFile: UploadedFile = {
                createdDate: entry.ufa_created_date ? this.formatDate(entry.ufa_created_date) : null,
                src: entry.url,
                name: entry.fileName,
                id: entry.id,
                docId: entry.docId,
                extension: entry.fileName
                  ? entry.fileName.split('.').length > 1
                    ? entry.fileName.split('.')[entry.fileName.split('.').length - 1]
                    : undefined
                  : undefined,
                isDeletable: !this.fromNotes,
                tags: entry.tags,
                provider: entry.provider,
              };
              if (this.multiple) {
                this.uploadedFiles.push(uploadedFile);
              } else {
                this.uploadedFiles = [uploadedFile];
              }
              this.nbUploadedFilesChangeEmitter.emit(this.uploadedFiles.length + this.linkedFiles.length);
              this.uploadingFiles.splice(this.uploadingFiles.indexOf(file), 1);
              this.primeFileuploadComponent.removeFile(file);
              this.fileAttachedEmitter.emit(this.uploadedFiles);
              this.uploadEnd.emit(uploadedFile);
              this.dataChange(this.uploadedFiles[0].src);
            })
        );
      }
    }
  }

  /**
   * Télécharge le fichier depuis le docserver
   * OK
   * @param {string}docId
   * @param {number}index
   */
  downloadFile(file: UploadedFile) {
    if (!this.isLocal) {
      const sub = this.#docServerService.downloadDocument(file.src as string).subscribe((response) => {
        Global.downloadFile(response, file.name as string);
        sub.unsubscribe();
      });
    } else {
      const link = document.createElement('a');
      link.setAttribute('type', 'hidden');
      link.href = file.src as string;
      link.download = file.name as string;
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }

  /**
   * Supprime un fichier uploadé
   * OK
   * @param {any}docId
   * @param {number}index
   */
  deleteFile(file: UploadedFile) {
    if ((this.valSubscr && !this.valSubscr.closed) || (this.canSubscr && !this.canSubscr.closed)) {
      Global.infoDialog.cancelDialog();
    }
    Global.infoDialog.message = {
      summary: this.#translateService.instant('infodialog.dialogs.deleteFile.title'),
      detail: this.#translateService.instant('infodialog.dialogs.deleteFile.message') + ' ' + file.name + '?',
    };
    Global.infoDialog.dialogType = InfoDialogType.CONFIRM_YESNO;
    Global.infoDialog.showInfoDialog();
    this.valSubscr = Global.infoDialog.confirm.subscribe(() => {
      this.subscriptions.push(
        this.#docServerService.deleteDocument(file, this.isAttachment).subscribe((res) => {
          if (this.uploadedFiles.find((fileTmp) => fileTmp.id === file.id)) {
            this.uploadedFiles.splice(
              this.uploadedFiles.findIndex((fileTmp) => fileTmp.id === file.id),
              1
            );
          }
          if (this.linkedFiles.find((fileTmp) => fileTmp.id === file.id)) {
            this.linkedFiles.splice(
              this.linkedFiles.findIndex((fileTmp) => fileTmp.id === file.id),
              1
            );
          }
          this.nbUploadedFilesChangeEmitter.emit(this.uploadedFiles.length + this.linkedFiles.length);
          this.delete.emit(file);
          this.valSubscr.unsubscribe();
          this.canSubscr.unsubscribe();
        })
      );
    });

    this.canSubscr = Global.infoDialog.cancel.subscribe(() => {
      this.canSubscr.unsubscribe();
      this.valSubscr.unsubscribe();
    });
  }

  onFileSelect(event) {
    if (this.auto) {
      const files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
      this.uploadFiles({ files });
    }
  }

  reset() {
    this.primeFileuploadComponent.files = [];
    this.localFiles = undefined;
    this.uploadedFiles = [];
    this.uploadingFiles = [];
    this.linkedFiles = [];
  }

  public openFileOnSide(additionalInfo: any) {
    this.openFileOnSideEmitter.emit(additionalInfo);
  }

  public onAttachUrl(url: string, tags: TagItem[]) {
    const id = url.replace(/:\/\//, '_').replace(/\./g, '-').replace(/\//g, '_') + new Date().getTime();
    let additionalUploadInformations = cloneDeep(this.additionalUploadInformations);
    if (tags && tags.length)
      for (const t of tags)
        if (!additionalUploadInformations['META|' + t.name]) additionalUploadInformations['META|' + t.name] = t.value;

    this.#docServerService.uploadDocument(url, url, id, this.type, additionalUploadInformations).subscribe((entry) => {
      const uploadedFile: UploadedFile = {
        createdDate: entry.ufa_created_date ? this.formatDate(entry.ufa_created_date) : null,
        src: entry.url,
        name: entry.fileName,
        id: entry.id,
        docId: entry.docId,
        extension: entry.fileName
          ? entry.fileName.split('.').length > 1
            ? entry.fileName.split('.')[entry.fileName.split('.').length - 1]
            : undefined
          : undefined,
        isDeletable: !this.fromNotes,
        tags: entry.tags,
        provider: entry.provider,
      };
      if (this.multiple) {
        this.uploadedFiles.push(uploadedFile);
      } else {
        this.uploadedFiles = [uploadedFile];
      }
      this.nbUploadedFilesChangeEmitter.emit(this.uploadedFiles.length);
      this.fileAttachedEmitter.emit(this.uploadedFiles);
      this.uploadEnd.emit(uploadedFile);
      this.dataChange(this.uploadedFiles[0].src);
    });
  }
}
