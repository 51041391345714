import { NgClass, NgIf } from "@angular/common";
import { Component, OnInit, ViewChild, ViewContainerRef } from "@angular/core";
import { CustomDesignItemType } from "@iupics-components/models/custom-design";
import ButtonUiComponent from "@iupics-components/standard/fields/button-ui/button-ui.component";
import EditViewUiComponent from "@iupics-components/standard/layouts/edit-view-ui/edit-view-ui.component";
import ModalUiComponent from "@iupics-components/standard/layouts/modal-ui/modal-ui.component";
import { IupicsMessage } from "@iupics-manager/models/iupics-message";
import { DynamicContainerDirective } from "@iupics-util/directives/dynamic-container.directive";
import { TranslateModule } from "@ngx-translate/core";
import { ProgressBarModule } from "primeng/progressbar";
import { ScrollPanelModule } from "primeng/scrollpanel";
import { SidebarModule } from "primeng/sidebar";
import SpecificWindowUiComponent from "../specific-window-ui/specific-window-ui.component";

@Component({
  selector: "iu-product-view",
  templateUrl: "./product-view.component.html",
  styleUrls: ["./product-view.component.scss"],
  standalone: true,
  imports: [
    NgIf,
    ProgressBarModule,
    ScrollPanelModule,
    DynamicContainerDirective,
    NgClass,
    ButtonUiComponent,
    SidebarModule,
    ModalUiComponent,
    // UniversalFilterUiComponent,
    // GridTabInfinityScrollUiComponent,
    TranslateModule,
  ],
})
export default class ProductViewComponent
    extends SpecificWindowUiComponent
    implements OnInit
{
  @ViewChild("vcrHidden", { read: ViewContainerRef, static: true })
  vcrHidden: ViewContainerRef;

  ngOnInit() {
    /** @start_custo_code **/
    if (this.parentComponent instanceof EditViewUiComponent) {
      if (!this.dataStore) {
        this.dataStore = this.store.newSpecificWindowData(
            this.formId,
            this.parentComponent.editTabs[0].dataStored
        );
      }
    }
    /** @end_custo_code */

    this.subscriptions.push(
        this.uiCreator
            .getSpecificWindow(this.formId)
            .subscribe((specificWindow) => {
              specificWindow.items.forEach((item) => {
                switch (item.data.columnName) {
                  case "Price_History_Button":
                    this.customDesignArray.push({
                      vcr: "vcrHidden",
                      type: CustomDesignItemType.FIELD,
                      columnName: item.data.columnName,
                      cssClass: "p-lg-3 p-md-3 p-col-12",
                    });
                    break;
                  case "Product Info Table":
                    this.customDesignArray.push({
                      vcr: "vcr",
                      type: CustomDesignItemType.GRID,
                      tableName: item.name,
                      cssClass: item.cssClass,
                    });
                    break;
                  default:
                    if (item.component === "GridViewUiComponent") {
                      this.customDesignArray.push({
                        vcr: "vcr",
                        type: CustomDesignItemType.GRID,
                        tableName: item.name,
                        cssClass: item.cssClass,
                      });
                    } else if (item.component === "ButtonUiComponent") {
                      this.customDesignArray.push({
                        vcr: "vcr",
                        type: CustomDesignItemType.FIELD,
                        columnName: item.data.columnName,
                        cssClass: "p-col-12 p-md-4 p-lg-2",
                      });
                    } else {
                      let vcr = "vcr";
                      if (item.data.isOptional) {
                        vcr = "vcrOptional";
                      }
                      this.customDesignArray.push({
                        vcr: vcr,
                        type: CustomDesignItemType.FIELD,
                        columnName: item.data.columnName,
                        cssClass: "p-col-12 p-md-4 p-lg-2",
                      });
                    }
                    break;
                }
              });
              this.showSpecificWindow();
            })
    );
  }
  showPriceHistoryForm() {
    const selections = this.getGridSelection("Product Info Table");
    const button = this.getDatacontainer("Price_History_Button");
    const gridFound = this.gridViews.find(
        (grid) => grid.data.columnName === "Product Info Table"
    );
    if (gridFound && selections && selections.length > 0) {
      this.updateModalDisplay(
          {
            key: "displayFormUI",
            value: true,
            sourceComponent: gridFound.GridTabInfinityScrollUiComponent,
          },
          { key: "formId", value: button.data["formId"] }
      );
    } else {
      this.messageManager.newMessage(
          new IupicsMessage(
              this.translateService.instant(
                  "infodialog.dialogs.noLineSelected.title"
              ),
              this.translateService.instant(
                  "infodialog.dialogs.noLineSelected.message"
              ),
              "warning"
          )
      );
    }
  }
}
