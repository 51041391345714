<div class="address-container">
    <h4 class="address-title">{{ displayData?.name }}</h4>
    @if (displayData) {
    <button pButton class="p-button p-button-icon-only address-gmap-btn" type="button" (click)="openMap()">
      <i class="icon-pointer-2" aria-hidden="true"></i>
    </button>
    }
    <div class="address-details">
        <span [style]="{'white-space': 'pre-line'}">{{ displayData?.address }}</span>
        <span>{{ displayData?.city }} {{ displayData?.postalCode }}</span>
        <span>{{ displayData?.country }}</span>
    </div>
    <button pButton class="p-button p-button-icon-only address-copy-btn" type="button" (click)="copy()">
        <i class="icon-copy" aria-hidden="true"></i>
    </button>
    <div class="address-tags">
        @if (displayData?.isShipTo) {
        <div class="address-tag" data-type="ship">
          <span>{{ 'cardsUi.addressCard.shipTo' | translate }}</span>
      </div>
        }

        @if (displayData?.isBillTo) {
        <div class="address-tag" data-type="bill">
          <span>{{ 'cardsUi.addressCard.billTo' | translate }}</span>
      </div>
        }

        @if (displayData?.isRemitTo) {
        <div class="address-tag" data-type="remit">
          <span>{{ 'cardsUi.addressCard.remitTo' | translate }}</span>
      </div>
        }

        @if (displayData?.isPayFrom) {
        <div class="address-tag" data-type="pay">
          <span>{{ 'cardsUi.addressCard.payFrom' | translate }}</span>
      </div>
        }
    </div>
</div>
